import React, { useState } from "react";
import ModalComp from "src/components/common/modal";
import { EXPORT_DATA_CONFIRMATION_TEXT } from "src/constant/modals";
import CopyText from "src/components/common/CopyTextButton"
import { useDispatch } from "react-redux";
import { isEditAccess } from "src/helpers";
import { errorAlert, successAlert } from "src/utils/alerts";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
import axios from "src/utils/axios";
import appConfig from "src/appConfig";
import Loader from "src/components/common/loaders";
const MembershipInfo = ({
  info,
  getUserList,
  editMemberships,
  setEditMemberships,
  membershipType,
  currentGiftSlide,
}) => {
  const [isEdit, setEdit] = useState(false);

  const [loading, setLoading] = useState(false);

  const onToggle = async (isConfirm) => {
    try {
      if (isConfirm) {
        setEditMemberships({ value: !editMemberships.isOpen, key: "isOpen" });
        setLoading(true)
        setEdit(true);
        const res = await axios.post(
          `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/referral-partners/export-data`, {}
        );
        if (res && res?.data?.statusCode === 200) {
          setLoading(false)
          const message =
            res && res.data
              ? res.data.message
              : "successfull";
          successAlert(message);
        }
      }
      setEditMemberships({ value: !editMemberships.isOpen, key: "isOpen" });


    }
    catch (error) {
      setLoading(false)
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }
  };

  const onToggleSaved = () => {
    setTimeout(() => {
      setEditMemberships({
        value: !editMemberships.isOpenSaved,
        key: "isOpenSaved",
      });
      setEdit(false);
    }, 1000);
  };

  const personalInfoData = info;

  return (
    <div className="">
      {loading && <Loader />}
      <div className="member-info">
        {Object.entries(info.links).map(([category, durations]) => (
          <div key={category}>
            <div className="heading">
              <h6 className="fs18 mb0">{category.charAt(0).toUpperCase() + category.slice(1)}</h6>
              <div className="ml-auto">
              </div>
            </div>
            <ul className="user-info">
              {Object.entries(durations).map(([duration, link]) => (
                <li>
                  <small>{duration.charAt(0).toUpperCase() + duration.slice(1)}</small>
                  <p>{link || "-"}</p>
                  <CopyText text={link} />
                </li>

              ))}
            </ul>
          </div>
        ))}
        <ModalComp
          open={editMemberships?.isOpen}
          setOpen={onToggle}
          content={EXPORT_DATA_CONFIRMATION_TEXT}
          onClickYes={() => onToggle(true)}
        />
      </div>
    </div>
  );
};

export default MembershipInfo;
