import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomFilterDateRangePicker = ({
  onStartDateChange,
  onEndDateChange,
  initialStartDate,
  initialEndDate,
  reset,
  isFunc

}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minEndDate, setMinEndDate] = useState(null);

  useEffect(() => {
    // Set the minimum end date to 30 days after the start date
    if (startDate) {
      const newMinEndDate = new Date(startDate);

      newMinEndDate.setDate(newMinEndDate.getDate());
      setMinEndDate(newMinEndDate);

      // If the current end date is before the new minimum, reset it
      if (endDate && endDate < newMinEndDate) {
        setEndDate(null);
      }
    }
  }, [startDate, endDate]);
  
  useEffect(()=>{
    if(reset === true)
    setStartDate(null)
    setEndDate(null)
    isFunc()
  },[reset])

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onStartDateChange(date); // Call the prop function
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onEndDateChange(date); // Call the prop function
  };
  
  return (
    <div className="date-pick date-input">
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        endDate={endDate}
        // minDate={new Date()}
        placeholderText="Start Date"
      />
      <span className="sep">-</span>
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={minEndDate}
        placeholderText="End Date"
        disabled={!startDate}
      />
    </div>
  );
};

export default CustomFilterDateRangePicker;
