import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { IconButton } from "@mui/material";

import * as Yup from "yup";

// LOCAL IMPORTS
import { login } from "src/redux/services/auth.service";
import { getSessionToken } from "src/helpers";
import SieveLogo from "../../../assets/images/Sieve-logo-black.svg";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please Enter Valid Email")
    .required("Please Enter Your Email."),
  password: Yup.string().trim().required("Please Enter Your Password."),
});

const Login = () => {
  const { loading: apiLoader } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const ref = useRef();

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const isLogin = getSessionToken("authToken");

  useEffect(() => {
    if (isLogin) {
      navigate("/dashboard");
    }
  }, [isLogin]);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const { email, password } = values;
    dispatch(
      login({
        email,
        password,
        callback: (res) => {
          if (res) {
            if (res?.statusCode === 200) {
              navigate("/dashboard");
            }
          }
        },
      })
    );
    setSubmitting(false);
  };

  const handleKeyUp = (event) => {
    if (event.keyCode === 13) {
      ref.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={handleSubmit}
      >
        {(props) => {
          return (
            <Form>
              <section className="login">
                <div className="wrapper">
                  <div className="text">
                    <div className="head">
                      <figure className="logo">
                        <img src={SieveLogo} alt="Sievelogo" />
                      </figure>

                      <p className="txt">
                        Expertly curated insights <br /> for the next generation
                        of business
                      </p>
                    </div>

                    <div className="body">
                      <h6 className="font-old title fs20 m20">
                        Sign In To Your Account
                      </h6>

                      <form>
                        <div className="form-in icon">
                          <label>Email</label>
                          <div className="f-in">
                            <span className="icon-left">
                              <i className="mail-icon"></i>
                            </span>
                            <Field
                              type="email"
                              placeholder="Enter Email"
                              name="email"
                            />
                          </div>
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>

                        <div className="form-in icon">
                          <label>Password</label>
                          <div className="f-in">
                            <span className="icon-left">
                              <i className="password-icon"></i>
                            </span>
                            <Field
                              type={`${showPassword ? "text" : "password"}`}
                              placeholder="Password"
                              name="password"
                            />
                            <div className="icon-right">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </div>
                          </div>
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="input-feedback error-msg"
                          />
                          <Link
                            className="link mt6 d-block text-right fs14 text-blue"
                            to="/forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </form>
                    </div>

                    <div className="foot">
                      <div>
                        <button
                          ref={ref}
                          type="submit"
                          className="btn single"
                          disabled={apiLoader}
                        >
                          SIGN IN
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default React.memo(Login);
