import React, { useEffect } from "react";
import { format } from "date-fns";
import * as Yup from "yup";
import axios from "src/utils/axios";
import { successAlert, errorAlert } from "src/utils/alerts";
import { Formik, Field, Form, ErrorMessage } from "formik";
import {
  errorMessage,
  firstLetterUpper,
  isEditAccess,
  validNameRegex,
  ValidPhoneNumber,
} from "src/helpers";
import moment from "moment-timezone";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";

// Validation Schema
const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .required("Please Enter First Name.")
    .matches(
      validNameRegex,
      "Please Enter a Valid First Name. A First Name Should Not Contain Special Characters Or Alphanumeric Characters."
    ),
  lastName: Yup.string()
    .trim()
    .required("Please Enter Last Name.")
    .matches(
      validNameRegex,
      "Please Enter a Valid Last Name. A Last Name Should Not Contain Special Characters Or Alphanumeric Characters."
    ),
  contactNo: Yup.string()
    .trim()
    .required("Please Enter Mobile No.")
    .matches(
      ValidPhoneNumber,
      "Please Enter Valid Country Code And Mobile Number. The Mobile Number Must Have A Minimum Of 5 Digits And A Maximum Of 15 digits."
    )
    .min(
      5,
      "Please Enter Valid Country Code And Mobile Number. The Mobile Number Must Have A Minimum Of 5 Digits And A Maximum Of 15 digits."
    )
    .max(
      15,
      "Please Enter Valid Country Code And Mobile Number. The Mobile Number Must Have A Minimum Of 5 Digits And A Maximum Of 15 digits."
    ),
  endDate: Yup.string().trim().required("Please Select End Date."),
  countryCode: Yup.string().trim().required("Please Select Country Code."),
});

const MembershipSearch = ({
  memberData,
  handleSearch,
  fetchComplimentaryMemberList,
}) => {
  useEffect(() => {
    if (memberData) {
      setIsEdit(false);
    }
  }, [memberData]);

  const [isEdit, setIsEdit] = React.useState(false);

  const handleSearchEdit = (values, initialValues) => {
    const dataToSend = {
      complimentaryMemberId: values.id,
      firstName: values.firstName,
      lastName: values.lastName,
      // email: values.email,
      contactNumber: values.contactNo,
      countryCode: values.countryCode,
      endDate: moment(`${values.endDate}`).utc(),
      status: values.status,
    };
    if (memberData?.complimentaryMembershipStatus === values?.status) {
      delete dataToSend.status;
    } else if (memberData?.complimentaryMembershipEndDate === values?.endDate) {
      delete dataToSend.endDate;
    }

    axios
      .put("admin/complimentary-membership/update", { ...dataToSend })
      .then((res) => {
        if (res && res.data && res.data.data) {
          fetchComplimentaryMemberList();
          handleSearch();
          setIsEdit(false);
        }
        // successAlert(res?.data?.message);
      })
      .catch((err) => {
        let message = "";
        if (err?.response && err?.response?.data?.message) {
          message = errorMessage(err);
        }
        errorAlert(message);
      });
  };
  return (
    <Formik
      initialValues={{
        firstName: memberData?.firstName,
        lastName: memberData?.lastName,
        endDate: memberData?.complimentaryMembershipEndDate,
        contactNo: memberData?.contactNumber,
        countryCode: memberData?.countryCode,
        status: memberData?.complimentaryMembershipStatus,
        id: memberData?._id,
      }}
      validationSchema={validationSchema}
      validateOnChange
      validateOnBlur
    >
      {({ values, setFieldValue, setValues, initialValues }) => {
        useEffect(() => {
          if (memberData) {
            setValues({
              firstName: memberData?.firstName,
              lastName: memberData?.lastName,
              endDate: memberData?.complimentaryMembershipEndDate,
              contactNo: memberData?.contactNumber,
              countryCode: memberData?.countryCode,
              status: memberData?.complimentaryMembershipStatus,
              id: memberData?._id,
            });
          }
        }, [memberData]);

        return (
          <Form>
            <div className="compli-search">
              <div className="head">
                <p>
                  Search Results for {memberData?.firstName}{" "}
                  {memberData?.lastName}
                </p>
                {!isEdit ? (
                  <button
                    className="btn"
                    type="button"
                    onClick={() => {
                      const isEdit = isEditAccess("COMPLIMENTARY_MEMBERSHIP");
                      if (isEdit) {
                        setIsEdit(true);
                      } else {
                        errorAlert(NOT_AUTHORIZED_TEXT);
                      }
                    }}
                  >
                    <i className="edit-icon"></i>
                  </button>
                ) : (
                  <button
                    className="btn"
                    type="submit"
                    disabled={
                      values.status === "LIVE" &&
                      new Date(values.endDate) <= new Date()
                    }
                    onClick={() => {
                      handleSearchEdit(values, initialValues);
                    }}
                  >
                    SAVE
                  </button>
                )}
              </div>
              <div className="compli-listing">
                <ul>
                  <li>
                    {isEdit ? (
                      <div>
                        <div className="form-in">
                          <label>First Name</label>
                          <div className="f-in">
                            <Field
                              maxLength={12}
                              type="text"
                              placeholder="Enter First Name"
                              name="firstName"
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="firstName"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p>First Name</p> <small>{memberData?.firstName}</small>
                      </>
                    )}
                  </li>

                  <li>
                    {isEdit ? (
                      <div className="form-in phone">
                        <label>Mobile no.</label>
                        <div className="f-in">
                          <div>
                            <PhoneInput
                              inputStyle={{ width: "100px" }}
                              inputExtraProps={{
                                name: "phone",
                                required: true,
                                autoFocus: true,
                              }}
                              value={`${values.countryCode}`}
                              onChange={(code) => {
                                setFieldValue("countryCode", code);
                              }}
                            />
                            <ErrorMessage
                              name="countryCode"
                              component="div"
                              className="input-feedback error-msg"
                            />
                          </div>
                          <Field
                            type="text"
                            placeholder="Enter Mobile No."
                            name="contactNo"
                            maxLength={15}
                          />{" "}
                        </div>
                        <ErrorMessage
                          name="contactNo"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                    ) : (
                      <>
                        <p>Mobile No.</p>{" "}
                        <small>
                          +{memberData?.countryCode} -{" "}
                          {memberData?.contactNumber}
                        </small>
                      </>
                    )}
                  </li>

                  <li>
                    <p>Customer ID</p> <small>{memberData?.customerId}</small>
                  </li>
                </ul>

                <ul>
                  <li>
                    {isEdit ? (
                      <div>
                        <div className="form-in">
                          <label>Last Name</label>
                          <div className="f-in">
                            <Field
                              maxLength={12}
                              type="text"
                              placeholder="Enter Last Name"
                              name="lastName"
                            />
                          </div>
                        </div>
                        <ErrorMessage
                          name="lastName"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p>Last Name</p> <small>{memberData?.lastName}</small>
                      </>
                    )}
                  </li>

                  <li>
                    <p>Membership Type</p> <small>Professional</small>
                  </li>

                  <li>
                    {isEdit ? (
                      <div className="form-in">
                        <label>Status</label>
                        <div className="f-in">
                          <Field as="select" name="status">
                            <option value="LIVE">Live</option>
                            <option value="EXPIRED">Expired</option>
                          </Field>
                        </div>
                        <ErrorMessage
                          name="status"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <p>Status</p>{" "}
                        <small>
                          {memberData?.complimentaryMembershipStatus
                            ? firstLetterUpper(
                                memberData?.complimentaryMembershipStatus
                              )
                            : "-"}
                        </small>
                      </>
                    )}
                  </li>
                </ul>

                <ul>
                  <li>
                    <p>Email address</p> <small>{memberData?.email}</small>
                  </li>

                  <li className="flex ">
                    <div>
                      <p>Start Date</p>{" "}
                      <small>
                        {memberData?.complimentaryMembershipStartDate &&
                          format(
                            new Date(
                              memberData?.complimentaryMembershipStartDate
                            ),
                            "dd MMM, yyyy"
                          )}
                      </small>
                    </div>
                    <div>
                      {isEdit ? (
                        <div className="form-in">
                          <p>End Date</p>
                          <div className="f-in date-input">
                            <DatePicker
                              selectsEnd
                              placeholderText="End-Date"
                              selected={
                                values?.endDate && new Date(values?.endDate)
                              }
                              minDate={
                                memberData?.complimentaryMembershipStartDate &&
                                new Date(
                                  memberData?.complimentaryMembershipStartDate
                                )
                              }
                              onChange={(date) => {
                                setFieldValue("endDate", date);
                              }}
                            />
                          </div>
                          <ErrorMessage
                            name="endDate"
                            component="div"
                            className="input-feedback error-msg"
                          />
                          {values.status === "LIVE" &&
                            new Date(values.endDate) <= new Date() && (
                              <div className="input-feedback error-msg">
                                Please Select End Date To Change Status
                              </div>
                            )}
                        </div>
                      ) : (
                        <>
                          <p>End Date</p>{" "}
                          <small>
                            {memberData?.complimentaryMembershipEndDate &&
                              format(
                                new Date(
                                  memberData?.complimentaryMembershipEndDate
                                ),
                                "dd MMM, yyyy"
                              )}
                          </small>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MembershipSearch;
