import React from "react";
import { Dialog } from "@mui/material";
import CancelButton from "../../../../components/common/modal-cancel-btn";
 
export default function ExistingEmailModal(props) {
  const { open, setOpen, handleFinish } = props;
 
  return (
    <Dialog
      open={open}
      style={{ padding: "50px" }}
      onClose={() => setOpen(false)}
    >
      <div className="modal-text">
        <div className="close-btn">
          <CancelButton
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>

        <div className="heading flex-center">
            <h6>This user already exists in the system, do you still want to continue</h6>
        </div>

        <div className="foot">
          <button
            className="btn"
            text="Add"
            onClick={() => setOpen(false)}
          >
            NO
          </button>
          <button
            className="btn"
            text="Add"
            onClick={() => handleFinish()}
          >
            YES
          </button>
        </div>
      </div>
    </Dialog>
  );
}
