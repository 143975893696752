import React, { useState } from "react";
import Carousel from "react-multi-carousel";

export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ButtonGroup = ({
  next,
  previous,
  goToSlide,
  setCurrentSlide,
  isShowPersonalized = false,
  handleSendPersonalizedEmail,
  fetchData,
  pageNo,
  setPage,
  // setSendPersonalizedEmail = false,
  ...rest
}) => {
  const {
    carouselState: { currentSlide, totalItems },
  } = rest;
  if (setCurrentSlide) {
    setCurrentSlide(currentSlide);
  }

  const [dataCount, setDataCount] = useState(0);

  return (
    <div className="btn-grp flex-center pt12">
      <button
        className="btn-white fs16"
        onClick={() => {
          previous();
        }}
        disabled={currentSlide === 0}
      >
        Previous
      </button>
      <button
        className="btn fs16"
        onClick={() => {
          if (currentSlide === totalItems - 1 && fetchData && setPage) {
            setPage(pageNo + 1);
            fetchData({ pageNo: pageNo + 1 });
            setDataCount(totalItems);
          } else {
            next();
          }
        }}
        disabled={
          !fetchData
            ? currentSlide === totalItems - 1
            : dataCount === totalItems && currentSlide === totalItems - 1
        }
      >
        Next
      </button>
      {isShowPersonalized && (
        <div className="btns-lower">
          <button className="btn fs16" onClick={handleSendPersonalizedEmail}>
            SEND PERSONALIZED EMAIL
          </button>
        </div>
      )}
    </div>
  );
};

const CustomCarousel = ({
  customButtonGroup = true,
  arrows = false,
  renderButtonGroupOutside = true,
  children,
  setCurrentSlide,
  isShowPersonalized = false,
  handleSendPersonalizedEmail,
  fetchData,
  pageNo,
  setPage,
}) => {
  return (
    <div>
      <Carousel
        arrows={arrows}
        customButtonGroup={
          customButtonGroup ? (
            <ButtonGroup
              setCurrentSlide={setCurrentSlide}
              isShowPersonalized={isShowPersonalized}
              handleSendPersonalizedEmail={handleSendPersonalizedEmail}
              fetchData={fetchData}
              pageNo={pageNo}
              setPage={setPage}
            />
          ) : null
        }
        responsive={responsive}
        renderButtonGroupOutside={renderButtonGroupOutside}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CustomCarousel;
