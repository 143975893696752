import React, { useState } from "react";
import MultiStepFormModal from "./multistepModal";
import axios from "src/utils/axios";
import appConfig from "src/appConfig";
import Filters from "./filter";
import Loader from "src/components/common/loaders";
import { errorAlert, successAlert } from "src/utils/alerts";
const optInMembership = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("")
  const [filterData, setFilterData] = useState("")
  const [filterValue, setFilterValues] = useState("")
  const [loading, setLoading] = useState(false);

  const handleSearch = (res) => {
    setFilterData(res?.data)
  };
  const handleReset = (data) => {
    if (data.reset === true) {
      setFilterData("")
    }
  }
  const handleFilterValue = (obj) => {
    setFilterValues(obj)
  }
  const handleLoading = (val) => {
    setLoading(val)
  }

  const handleExportData = async () => {
    try {
      let searchData = filterValue
      Object.keys(searchData).forEach((key) => {
        if (searchData[key] === "ALL") {
          delete searchData[key];
        }
      });
      setLoading(true)
      const res = await axios.post(
        `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/optin/export-data`,
        searchData
      );
      if (res && res?.data?.statusCode === 200) {
        setLoading(false)
        const message =
          res && res.data
            ? res.data.message
            : "successfull";
        successAlert(message);
      }
    }
    catch (error) {
      setLoading(false)
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }

  }
  return (
    <>
      {isModalOpen && (
        <MultiStepFormModal
          open={isModalOpen}
          modalType={modalType}
          onClose={() => setIsModalOpen(false)}
          modalHeader="Opt In Free Membership"
        />
      )}
      <div className="main-div user-manage">
        {loading && <Loader />}
        <div className="heading">
          <div className="title">
            <h6 className="">Opt In Free Membership</h6>
          </div>
          <div className="ml-auto">
            <button
              className="btn"
              autoFocus
              onClick={() => {
                setIsModalOpen(true)
                setModalType("OPTIN")
              }}
            >
              {" "}
              ADD BULK UPLOAD
            </button>
          </div>
        </div>
        <Filters
          onSearch={handleSearch}
          onReset={() => {
            handleReset({ reset: true })
          }}
          filterValues={handleFilterValue}
          filterType="OPTIN"
          loading={handleLoading}
        />
        {
          filterData ?
            <>
              <div className="dashboard-cards">
                <div className="card-details">
                  <p>Total OPT-In Email Sent</p>
                  <h5>{(filterData.totalOptinEmailSent) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Total OPT-Ins</p>
                  <h5>{(filterData.optInUserCount) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>OPT-In %</p>
                  <h5>{(filterData.optinPercentage) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Total Free Members Created</p>
                  <h5>{(filterData.numberOfMembershipCreated) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Total Signed In</p>
                  <h5>{(filterData.totalSignedUsers) || "0"}</h5>
                </div>
              </div>

              <div className="dashboard-cards">
              <div className="card-details">
                  <p>Signed In %</p>
                  <h5>{(filterData.singinPercentage) || "0"}</h5>
                </div>
                <div className="card-details">
                  <p>Total Converted</p>
                  <h5>{(filterData.totalconvertedUsers) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Conversion %</p>
                  <h5>{(filterData.conversionPercentage) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Active Users</p>
                  <h5>{(filterData.activeUsersLastTenDays) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Inactive Users</p>
                  <h5>{(filterData.inactiveUsersLastTenDays) || "0"}</h5>
                </div>
              </div>
              <div style={{ "text-align": "center", "margin-top": "2rem" }}>
                <button
                  className="btn"
                  autoFocus
                  onClick={handleExportData}

                >
                  Export Data
                </button>
              </div>

            </>
            :
            <div className="no-content">
              <h6>No Content</h6>
              <p>No data available right now.</p>
            </div>
        }
      </div>
    </>
  );
};

export default optInMembership;
