import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomDateRangePicker = ({
  onStartDateChange,
  onEndDateChange,
  initialStartDate,
  initialEndDate,
  dayExtendedType

}) => {
  const [startDate, setStartDate] = useState(initialStartDate || new Date());
  const [endDate, setEndDate] = useState(initialEndDate || null);
  const [minEndDate, setMinEndDate] = useState(null);
  const [isDateDisabled, setIsDateDisabled] = useState(false)

  useEffect(() => {
    // Set the minimum end date to 30 days after the start date
    if (startDate) {
      const newMinEndDate = new Date(startDate);
      let extendedDate = 31
      if (dayExtendedType === "OPTIN") {
        extendedDate = 46
        setIsDateDisabled(true)
      }
      else if (dayExtendedType === "DIRECT") {
        setIsDateDisabled(true)
      }
      else if (dayExtendedType === "DEFAULT") {
        extendedDate = 1
      }
      newMinEndDate.setDate(newMinEndDate.getDate() + extendedDate);
      newMinEndDate.setHours(0, 0, 0, 0)
      setMinEndDate(newMinEndDate);
      // If the current end date is before the new minimum, reset it
      // if (endDate && endDate < newMinEndDate) {
      //   setEndDate(null);
      // }
    }
  }, [startDate, endDate]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    onStartDateChange(date); // Call the prop function
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    onEndDateChange(date); // Call the prop function
  };

  return (
    <div className="date-pick date-input">
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        minDate={new Date()}
        placeholderText="Start Date"
        disabled={isDateDisabled}
      />
      <span className="sep">-</span>
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={minEndDate}
        placeholderText="End Date"
        disabled={isDateDisabled
        }
      />
    </div>
  );
};

export default CustomDateRangePicker;
