import React, { useEffect, useState } from "react";
import { DebounceInput } from "react-debounce-input";
import customAxios from "src/utils/axios";
import { errorAlert } from "src/utils/alerts";
import DateRangePicker from "src/components/DateRangePicker";
import { isValidEmail } from "src/helpers";
import CommissionTable from "./CommissionTable";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import Loader from "src/components/common/loaders";
import moment from "moment-timezone";

const CommissionPayments = () => {
  const [loading, setLoading] = useState(false);
  const [currPageAccrued, setCurrPageAccrued] = useState(1);
  const [currPagePaid, setCurrPagePaid] = useState(1);

  const [commissionAccrued, setCommissionAccrued] = useState();
  const [commissionPaid, setCommissionPaid] = useState();

  const [error, setError] = useState();
  const [filterData, setFilterData] = useState({ search: "" });
  const [totalCountAccrued, setTotalCountAccrued] = useState(1);
  const [totalCountPaid, setTotalCountPaid] = useState(1);

  const [accruedStart, setAccruedStart] = React.useState(null);
  const [accruedEnd, setAccruedEnd] = React.useState(null);

  const selectAccruedStartDate = (d) => {
    if (d > accruedEnd) {
      setAccruedEnd(null);
    }
    setAccruedStart(d);
  };

  const selectAccruedEndDate = (d) => {
    setAccruedEnd(d);
  };

  const clearAccruedDates = () => {
    setAccruedEnd(null);
    setAccruedStart(null);
  };

  // Paid Date Handling

  const [paidStart, setPaidStart] = React.useState(null);
  const [paidEnd, setPaidEnd] = React.useState(null);

  const selectPaidStartDate = (d) => {
    if (d > accruedEnd) {
      setPaidEnd(null);
    }
    setPaidStart(d);
  };

  const selectPaidEndDate = (d) => {
    setPaidEnd(d);
  };

  const clearPaidDates = () => {
    setPaidEnd(null);
    setPaidStart(null);
  };

  useEffect(() => {
    getCommissionPaymentsAccrued();
  }, [currPageAccrued, accruedStart, accruedEnd]);

  useEffect(() => {
    getCommissionPaymentsPaid();
  }, [paidStart, paidEnd, currPagePaid]);

  const handleChange = (event) => {
    const key = event.target.name;
    const value =
      key !== "search"
        ? event.target.value.toLowerCase()
        : event.target.value.toUpperCase();

    if (event.target.value === "") {
      getCommissionPaymentsAccrued();
      getCommissionPaymentsPaid();
    }
    setFilterData({
      ...filterData,
      [key]: value,
    });
  };

  const handleSearch = () => {
    const isEmpty = Object.values(filterData).every(
      (x) => x === null || x === ""
    );
    if (!isEmpty) {
      const data = {
        ...filterData,
      };
      getCommissionPaymentsAccrued(data);
      getCommissionPaymentsPaid(data);
    }
  };

  const getCommissionPaymentsAccrued = (filter) => {
    setLoading(true);
    let url = `/admin/sales-agent/commission-payments-accrued?limit=${MAX_PAGE_LIMIT}&page=${currPageAccrued}`;
    if (accruedStart !== null && accruedEnd !== null) {
      url =
        url + `&accruedStartDate=${accruedStart}&accruedEndDate=${accruedEnd}`;
    }

    if (filter) {
      url = url + `&search=${filter?.search}`;
    }
    setLoading(true);
    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const totalPagesCount =
            response?.data?.data?.total && response?.data?.data?.total > 0
              ? Math.ceil(response?.data?.data?.total / MAX_PAGE_LIMIT)
              : 1;

          setTotalCountAccrued(totalPagesCount);
          setCommissionAccrued(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getCommissionPaymentsPaid = (filter) => {
    setLoading(true);
    let url = `/admin/sales-agent/commission-payments-paid?limit=${MAX_PAGE_LIMIT}&page=${currPagePaid}`;

    if (paidStart !== null && paidEnd !== null) {
      url = url + `&paidStartDate=${paidStart}&paidEndDate=${paidEnd}`;
    }
    if (filter) {
      url = url + `&search=${filter?.search}`;
    }
    setLoading(true);
    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const totalPagesCount =
            response?.data?.data?.total && response?.data?.data?.total > 0
              ? Math.ceil(response?.data?.data?.total / MAX_PAGE_LIMIT)
              : 1;

          setTotalCountPaid(totalPagesCount);
          setCommissionPaid(response?.data?.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <div>
      {loading && <Loader />}
      <div className="comp-search full">
        <div className="form-in icon">
          <span className="icon-left">
            <i className="search-icon"></i>
          </span>
          <DebounceInput
            placeholder={"Search by Partner's Full Name or ID"}
            debounceTimeout={500}
            onBlur={(e) => {
              if (e.target.name === "email" && e.target.value) {
                const isValid = isValidEmail(e.target.value);
                setError(!isValid);
              }
            }}
            onKeyPress={(e) => {
              if (!error && e.key === "Enter") {
                handleSearch();
              }
            }}
            onChange={(e) => {
              handleChange(e);
            }}
            name="search"
          />
        </div>
        <div className="">
          <button
            className="btn fs16"
            onClick={() => {
              if (!error) {
                handleSearch();
              }
            }}
          >
            Search
          </button>
        </div>
      </div>
      {/* commission accrued */}
      <div>
        <div className="heading">
          <div className="">
            <p className="fw600">
              Commission <span className="text-blue"> Accrued</span>
            </p>
          </div>
          <div className="ml-auto date-filter date-input">
            <DateRangePicker
              className="mr-10"
              selectStartDate={selectAccruedStartDate}
              selectEndDate={selectAccruedEndDate}
              startDate={accruedStart}
              endDate={accruedEnd}
              // minDate={new Date()}
              disableEndDate={!accruedStart}
            />
            <button
              className="btn-white ml12"
              onClick={() => clearAccruedDates()}
            >
              Clear
            </button>
          </div>
        </div>

        <div className="">
          <CommissionTable
            list={commissionAccrued}
            action={false}
            currPage={currPageAccrued}
            setCurrPage={setCurrPageAccrued}
            totalCount={totalCountAccrued}
            totalStart={commissionAccrued?.totalAccruedFromStart}
            totalDate={commissionAccrued?.totalAccruedFromDate}
            totalUserFromStart={commissionAccrued?.totalUsersFromStart}
            totalUsersFromDate={commissionAccrued?.totalUsersFromDate}
          />
        </div>
      </div>

      {/* commission paid */}
      <div>
        <div className="heading">
          <div className="">
            <p className="fw600">
              Commission <span className="text-red"> PAID</span>
            </p>
          </div>

          <div className="ml-auto date-filter date-input">
            <DateRangePicker
              className="mr-10"
              selectStartDate={selectPaidStartDate}
              selectEndDate={selectPaidEndDate}
              startDate={paidStart}
              endDate={paidEnd}
              // minDate={new Date()}
              disableEndDate={!paidStart}
            />
            <button className="btn-white ml12" onClick={() => clearPaidDates()}>
              Clear
            </button>
          </div>
        </div>
        <CommissionTable
          list={commissionPaid}
          totalStart={commissionPaid?.totalPaidAmount}
          totalDate={commissionPaid?.totalPaidAmountFromDate}
          action={true}
          currPage={currPagePaid}
          totalCount={totalCountPaid}
          setCurrPage={setCurrPagePaid}
          totalUserFromStart={commissionPaid?.totalUsersFromStart}
          totalUsersFromDate={commissionPaid?.totalUsersFromDate}
          getCommissionPayments={getCommissionPaymentsPaid}
        />
      </div>

      {/* </div> */}
    </div>
  );
};

export default CommissionPayments;
