import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { TableFooter } from "@mui/material";
import UpdateCommission from "./UpdateCommission";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { firstLetterUpper, dateAndYearFormat } from "src/helpers";
import { isEditAccess } from "src/helpers";
import { errorAlert } from "src/utils/alerts";

const heading = [
  "Sr. No.",
  "Partner Details",
  "Enroll Date",
  "Count",
  "USD From Start",
  "Count",
  "USD",
];
const CommissionTable = ({
  list = [],
  action,
  currPage,
  setCurrPage,
  totalDate,
  totalStart,
  totalCount,
  getCommissionPayments,
  totalUserFromStart,
  totalUsersFromDate,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [updateItem, setUpdateItem] = useState({});

  return (
    <>
      <div className="full table-sec mb24 comp-table center-right">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, i) => {
                  return (
                    <>
                      <TableCell align="left" key={i}>
                        {h}
                      </TableCell>
                    </>
                  );
                })}

              {heading && heading.length > 0 && action && (
                <TableCell align="left">Action</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list?.data &&
              list?.data?.length > 0 &&
              list?.data?.map((item, index) => {
                return (
                  <TableRow key={item?._id}>
                    <TableCell>
                      {(currPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>

                    <TableCell>
                      <p>{item?.name ? firstLetterUpper(item?.name) : "-"}</p>
                      <span>#{item?.partnerId}</span>
                      <p></p>
                    </TableCell>
                    <TableCell>
                      {" "}
                      {item?.enrollDate
                        ? dateAndYearFormat(item?.enrollDate)
                        : "-"}
                    </TableCell>

                    <TableCell>
                      {item?.totalUsers ? item?.totalUsers : "-"}
                    </TableCell>

                    <TableCell>
                      {item?.amountFromStart
                        ? Number(item?.amountFromStart || 0).toFixed(2)
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.totalFilteredUsers
                        ? item?.totalFilteredUsers
                        : item?.totalFilteredMembersUsers
                        ? item.totalFilteredMembersUsers
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {item?.amountWithDateFilter
                        ? Number(item?.amountWithDateFilter).toFixed(2)
                        : item?.amountPaidWithDate
                        ? Number(item?.amountPaidWithDate).toFixed(2)
                        : "-"}
                    </TableCell>
                    {action && (
                      <TableCell>
                        <button
                          className="btn sm"
                          onClick={() => {
                            const isEdit = isEditAccess(
                              "REFERRAL_PARTNER_MANAGEMENT"
                            );
                            if (isEdit) {
                              setOpen(true);
                              setUpdateItem(item);
                            } else {
                              errorAlert(NOT_AUTHORIZED_TEXT);
                            }
                          }}
                        >
                          UPDATE
                        </button>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
          {list && list?.data && list?.data?.length > 0 && (
            <TableFooter>
              <TableCell>Total</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                {totalUserFromStart ? Number(totalUserFromStart || 0) : ""}
              </TableCell>
              <TableCell>
                {totalStart ? Number(totalStart || 0).toFixed(2) : ""}
              </TableCell>
              <TableCell>
                {totalUsersFromDate ? Number(totalUsersFromDate || 0) : ""}
              </TableCell>
              <TableCell>
                {totalDate ? Number(totalDate || 0).toFixed(2) : ""}
              </TableCell>
            </TableFooter>
          )}
        </Table>
      </div>
      {open && (
        <UpdateCommission
          open={isOpen}
          title="Confirmation"
          setOpen={() => setOpen(false)}
          updateItem={updateItem}
          getCommissionPayments={getCommissionPayments}
        />
      )}
      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={currPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
      {list && list?.data && list?.data?.length === 0 && (
        <div className="no-content">
          <h6>No Records Found Yet</h6>
        </div>
      )}
    </>
  );
};

export default CommissionTable;
