import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Select from "react-select";
import {
  getUploadResponse,
  isEditAccess,
  isValidFileUploaded,
  isValidImage,
  isValidUrl,
} from "src/helpers";
import { getCategory } from "src/redux/services/category.service";
import { getGroupsList } from "src/redux/services/group.service";
import { errorAlert } from "src/utils/alerts";
import CancelButton from "../../../components/common/modal-cancel-btn";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";

const ctaOptions = [
  {
    label: "LISTEN",
    value: "LISTEN",
  },
  {
    label: "WATCH",
    value: "WATCH",
  },
  {
    label: "READ",
    value: "READ",
  },
];

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  imageSource: Yup.string().trim().required("Please Enter Image Source."),
  attribute: Yup.string().trim().required("Please Enter Attribute."),
  title: Yup.string().trim().required("Please Enter Title."),
  redirectUrl: Yup.string().trim().required("Please Enter Redirect Url."),
  ctaText: Yup.string().trim().required("Please Select CTA Text."),
  categoryId: Yup.string().trim().required("Please Select Category."),
  mobileImage: Yup.string().trim().required("Please Upload An Image."),
});

const ContentCard = ({
  index,
  onSave,
  handleAllValues,
  initialValues,
  isEdit,
  setIsEdit,
  setAddNew,
  setView,
}) => {
  const [topicList, setTopicsList] = useState([]);
  const [imageLoader, setImageLoader] = useState(false);
  const [topicError, setTopicErrors] = useState("");

  const dispatch = useDispatch();

  const { categories, groupList } = useSelector((state) => {
    return {
      categories: state?.category?.category || [],
      groupList:
        state?.groups?.groupsList?.filter((el) => {
          return el.topics.length > 0;
        }) || [],
      apiLoader:
        state?.category?.loading ||
        state?.groups?.loading ||
        state?.content?.loading,
    };
  });

  useEffect(() => {
    if (!initialValues) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [initialValues]);

  useEffect(() => {
    dispatch(getCategory({ callback: () => {} }));
    dispatch(getGroupsList({}));
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    setImageLoader(true);
    const isValid = await isValidImage({ file, height: 625, width: 500 });
    if (isValid) {
      const response = await getUploadResponse(file);
      setImageLoader(false);
      if (response) {
        return response;
      }
    } else {
      setImageLoader(false);
      return false;
    }
  };

  return (
    <div className="content-card">
      {groupList && groupList.length > 0 && (
        <Formik
          initialValues={{
            attribute: "",
            ctaText: "",
            categoryId: "",
            redirectUrl: "",
            imageSource: "",
            title: "",
            mobileImage: "",
            topics: [],
            groups: [],
          }}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          onSubmit={onSave}
        >
          {(props) => {
            const {
              setValues,
              values,
              setFieldValue,
              errors,
              setFieldError,
              isValid,
            } = props;

            useEffect(() => {
              if (
                Object.values(errors).every((el) => {
                  return el === "";
                }) &&
                !topicError
              ) {
                handleAllValues(values, index);
              }
            }, [values]);

            useEffect(() => {
              if (initialValues) {
                setValues(initialValues);
                const groupData = initialValues.groups || [];
                let topicData = [];
                groupData.forEach((el) => {
                  const data =
                    groupList &&
                    groupList.find((item) => {
                      return el.groupId === item._id;
                    });

                  if (data) {
                    topicData.push(
                      ...data?.topics?.map((topic) => {
                        return {
                          ...topic,
                          groupName: el.name,
                        };
                      })
                    );
                  }
                });

                setTopicsList(topicData);
              }
            }, [initialValues]);

            return (
              <Form>
                {/*  new code with design */}

                <div className="heading bb">
                  <div className="title">
                    <h6>Content Piece #1</h6>
                  </div>
                  <div className="btn-grp ml-auto">
                    <button
                      className="btn"
                      type="button"
                      onClick={() => {
                        setAddNew(false);
                        setView(false);
                      }}
                    >
                      BACK
                    </button>
                    {initialValues && (
                      <button
                        className="btn"
                        type="button"
                        onClick={() => {
                          const isEdit = isEditAccess(
                            "CONTENT_BANK_MANAGEMENT"
                          );
                          if (isEdit) {
                            setIsEdit(true);
                          } else {
                            errorAlert(NOT_AUTHORIZED_TEXT);
                          }
                        }}
                      >
                        EDIT
                      </button>
                    )}
                  </div>
                </div>

                <div className="content-piece">
                  <div className="pr30">
                    <div className="content-img">
                      <div
                        className={`img-upload ${
                          values?.mobileImage ? "uploaded" : ""
                        }`}
                      >
                        {imageLoader && (
                          <div className="spinner">
                            <span></span>
                          </div>
                        )}
                        {values?.mobileImage && (
                          <span className="delete-img">
                            <CancelButton
                              disabled={!isEdit}
                              onClose={() => {
                                setFieldValue("mobileImage", "");
                              }}
                            />
                          </span>
                        )}

                        {values.mobileImage ? (
                          <img src={values.mobileImage} />
                        ) : (
                          <div className="f-in">
                            <input
                              type="file"
                              name="mobileImage"
                              accept=".jpg, .jpeg, .png"
                              onChange={async (e) => {
                                const isValidExtension = isValidFileUploaded(
                                  e.target.files[0]
                                );

                                if (isValidExtension) {
                                  const data = await handleFileChange(e);
                                  if (data) {
                                    setFieldValue("mobileImage", data);
                                  } else {
                                    setFieldError(
                                      "mobileImage",
                                      "Width and Height must be 500px * 625px And Size Should Be Less Than 3 MB."
                                    );
                                  }
                                } else {
                                  setFieldError(
                                    "mobileImage",
                                    "File Format Not Supported"
                                  );
                                }
                              }}
                              disabled={!isEdit}
                            />
                          </div>
                        )}

                        <div className="text">
                          <span className="icon icon-upload">
                            <i className="upload-icon"></i>
                          </span>
                          <p>Upload from gallery</p>
                          <p>(500x625)</p>
                          <p className="accept-img">
                            (accepts .png .jpeg .jpg images only)
                          </p>
                        </div>
                      </div>
                      <div
                        name="mobileImage"
                        className="input-feedback error-msg"
                      >
                        {errors?.mobileImage}
                      </div>
                    </div>
                  </div>

                  <div className="fb-1">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-in">
                          <label>Content Category </label>
                          <div className="f-in">
                            <Select
                              onChange={(data) => {
                                setFieldValue("categoryId", data._id);
                              }}
                              value={categories.find((option) => {
                                return option?._id === values?.categoryId;
                              })}
                              options={categories}
                              getOptionLabel={(value) => {
                                return value.name;
                              }}
                              getOptionValue={(value) => {
                                return value._id;
                              }}
                              isDisabled={!isEdit}
                            />
                            <ErrorMessage
                              name="categoryId"
                              component="div"
                              className="input-feedback error-msg"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-in">
                          <label>Attribution Text (30 Char) </label>
                          <div className="f-in">
                            <Field
                              placeholder="Enter attribute text"
                              maxLength={30}
                              type="text"
                              name="attribute"
                              disabled={!isEdit}
                            />
                            <ErrorMessage
                              name="attribute"
                              component="div"
                              className="input-feedback error-msg"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-in">
                          <label>CTA for Mobile </label>
                          <div className="f-in">
                            <Select
                              onChange={(data) => {
                                setFieldValue("ctaText", data.value);
                              }}
                              options={ctaOptions}
                              isDisabled={!isEdit}
                              value={ctaOptions.find((option) => {
                                return option.value === values.ctaText;
                              })}
                            />
                            <ErrorMessage
                              name="ctaText"
                              component="div"
                              className="input-feedback error-msg"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-in">
                          <label>Redirection URL ( Max 1000 Char) </label>
                          <div className="f-in">
                            <Field
                              placeholder="Enter redirect url"
                              maxLength={1000}
                              type="text"
                              name="redirectUrl"
                              disabled={!isEdit}
                            />

                            <a
                              className="icon-right"
                              onClick={() => {
                                if (values?.redirectUrl && isEdit) {
                                  window.open(values?.redirectUrl, "_blank");
                                }
                                if (
                                  values?.redirectUrl &&
                                  !isValidUrl(values?.redirectUrl)
                                ) {
                                  setFieldError(
                                    "redirectUrl",
                                    "Enter Valid URL"
                                  );
                                }
                              }}
                            >
                              <p className="text-blue">TEST</p>
                            </a>
                          </div>
                          <ErrorMessage
                            name="redirectUrl"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-in">
                          <label>Image Source (25 Char)</label>
                          <div className="f-in">
                            <Field
                              type="text"
                              placeholder="Enter Image Source"
                              name="imageSource"
                              maxLength={25}
                              disabled={!isEdit}
                            />
                          </div>
                          <ErrorMessage
                            name="imageSource"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-in">
                          <label>Title Text (250 Char)</label>
                          <div className="f-in">
                            <Field
                              as="textarea"
                              placeholder="Enter Text Here"
                              maxLength={250}
                              name="title"
                              type="text"
                              disabled={!isEdit}
                              rows="3"
                            />
                          </div>
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="input-feedback error-msg"
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-in multi-select">
                          <label>Group (optional)</label>
                          <div className="f-in">
                            <Select
                              isMulti
                              isClearable
                              onChange={(data) => {
                                let topicData = [];
                                const groupData = data.map((el) => {
                                  return {
                                    groupId: el._id,
                                  };
                                });

                                data.forEach((el) => {
                                  topicData.push(
                                    ...el.topics.map((data) => {
                                      return {
                                        ...data,
                                        groupName: el.name,
                                      };
                                    })
                                  );
                                });
                                setTopicsList(topicData);
                                setFieldValue("groups", groupData);

                                const updatedTopicIds =
                                  values &&
                                  values.topics &&
                                  values?.topics.filter((el) => {
                                    const filteredTopicIds = topicData.find(
                                      (data) => {
                                        return data._id === el.topicId;
                                      }
                                    );
                                    return filteredTopicIds ? true : false;
                                  });
                                setFieldValue("topics", updatedTopicIds);

                                if (
                                  updatedTopicIds &&
                                  updatedTopicIds.length > 0
                                ) {
                                  const errorForTopic =
                                    updatedTopicIds.length !== groupData.length;
                                  setTopicErrors(
                                    errorForTopic
                                      ? "Please Select Topics From All Groups"
                                      : ""
                                  );
                                } else {
                                  setTopicErrors("");
                                }
                              }}
                              value={
                                values &&
                                values.groups &&
                                values.groups.length > 0 &&
                                values?.groups.map((option) => {
                                  if (groupList && groupList.length > 0) {
                                    const data = groupList.find((el) => {
                                      return el._id === option.groupId;
                                    });
                                    return data;
                                  }
                                })
                              }
                              options={groupList}
                              getOptionLabel={(value) => {
                                return value.name;
                              }}
                              getOptionValue={(value) => {
                                return value._id;
                              }}
                              isDisabled={!isEdit}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-in multi-select">
                          <label>Topics (optional)</label>
                          <Select
                            isMulti
                            isClearable
                            isDisabled={topicList.length === 0 || !isEdit}
                            onChange={(data) => {
                              const topicData = data.map((el) => {
                                return {
                                  topicId: el._id,
                                };
                              });
                              setFieldValue("topics", topicData);
                              if (data.length > 0) {
                                const groups = data
                                  .map((el) => el.groupId)
                                  .filter(
                                    (item, i, ar) => ar.indexOf(item) === i
                                  );
                                const errorTopic =
                                  groups.length !== values?.groups?.length;

                                setTopicErrors(
                                  errorTopic
                                    ? "Please Select Topics From All Groups"
                                    : ""
                                );
                              } else {
                                setTopicErrors("");
                              }
                            }}
                            options={topicList}
                            getOptionLabel={(value) => {
                              return `${value.name} (${value.groupName})`;
                            }}
                            getOptionValue={(value) => {
                              return value._id;
                            }}
                            value={
                              values &&
                              values.topics &&
                              values.topics.length > 0 &&
                              values?.topics.map((option) => {
                                const data = topicList.find((el) => {
                                  return el._id === option.topicId;
                                });
                                return data;
                              })
                            }
                          />
                          {topicError && (
                            <div
                              name="mobileImage"
                              className="input-feedback error-msg"
                            >
                              {topicError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="btns-lower">
                  <div>
                    <button
                      className="btn long"
                      disabled={!isEdit || !isValid || topicError}
                    >
                      SAVE CONTENT PIECE
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default ContentCard;
