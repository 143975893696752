import React, { useEffect, useState } from "react";
import customAxios from "src/utils/axios";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { TableHead } from "@mui/material";
import { isEditAccess } from "src/helpers";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { errorAlert } from "src/utils/alerts";

const ComplimentaryMemberships = ({ setLoading, selectedDate }) => {
  const [data, setData] = useState();

  useEffect(() => {
    getComlimentaryData();
  }, [selectedDate]);

  const getComlimentaryData = (isSendEmailReport = false) => {
    setLoading(true);
    customAxios
      .get(
        `admin/reports-analytics/complimentary-memberships?isSendEmailReport=${isSendEmailReport}`
      )
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setData(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getComlimentaryData(true);
  };

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Complimentary Memberships</h6>
        </div>
        <div className="ml-auto">
          <button
            className="btn"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendReportOnMail();
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            {/* <i className="msg-icon"></i> */} Send Report To Admin
          </button>
        </div>
      </div>
      <div className="grey-table  full  table-sec mt10 center">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Total Membership</TableCell>
              <TableCell align="left">Active Membership</TableCell>
              <TableCell align="left">Expired Membership</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell>{data?.totalMemberships}</TableCell>
              <TableCell>{data?.live}</TableCell>
              <TableCell>{data?.expiredOrCancelled}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ComplimentaryMemberships;
