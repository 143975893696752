import React, { useEffect, useState } from "react";
import Select from "react-select";
import { NOT_AUTHORIZED_TEXT, editionFilter } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import DateRangePicker from "src/components/DateRangePicker";
import { isEditAccess } from "src/helpers";

const ContentClicksByEdition = ({
  setLoading,
  dataForSendReport,
  setDataForSendReport,
}) => {
  const [filterBy, setFilterBy] = useState(editionFilter[0].value);
  const [contentClicks, setContentClicks] = useState();
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [curPage, setCurrPage] = useState(1);
  useEffect(() => {
    getContentClicks();
  }, [filterBy, rangeEnd, rangeStart]);

  const getContentClicks = (isSendEmailReport = false) => {
    setLoading(true);
    let url = `admin/reports-analytics/content-clicks-by-edition-date?filterBy=${filterBy}&isSendEmailReport=${isSendEmailReport}&`;
    if (rangeStart !== null && rangeEnd !== null) {
      url =
        url +
        `startDate=${rangeStart.toISOString()}&endDate=${rangeEnd.toISOString()}&`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setContentClicks(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getContentClicks(true);
  };

  function getTimeStamp(date) {
    const now = new Date();
    return new Date(
      date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        now.getHours() +
        ":" +
        (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        ":" +
        (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds())
    );
  }

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    const newDate = getTimeStamp(d);
    setRangeStart(newDate);
  };

  const selectEndDate = (d) => {
    const date = getTimeStamp(d);
    setRangeEnd(date);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  return (
    <div className="main-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Content Clicks By Edition Date</h6>
        </div>
        <div className="ml-auto">
          <button
            className="btn"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendReportOnMail();
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            {/* <i className="msg-icon"></i> */} Send Report To Admin
          </button>
        </div>
      </div>
      <div className="heading no-sm">
        <div className="form-in date-pick d-flex mb24">
          <DateRangePicker
            setCurrPage={setCurrPage}
            selectStartDate={selectStartDate}
            selectEndDate={selectEndDate}
            startDate={rangeStart}
            endDate={rangeEnd}
            // minDate={rangeStart}
            disableEndDate={!rangeStart}
          />
          <button
            className="btn-white ml12"
            onClick={() => {
              clearDates();
            }}
          >
            Clear
          </button>
        </div>
        <div className="select-option ml-auto">
          <Select
            options={editionFilter}
            defaultValue={editionFilter[0]}
            onChange={(data) => {
              setFilterBy(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                contentClicksFilterBy: data.value,
              });
            }}
          />
        </div>
      </div>
      <div className="content-flex">
        <p>
          Total Content Pieces
          <b>{contentClicks && contentClicks?.totalContentPieces}</b>
        </p>
        <p>
          Redirection Links
          <b>{contentClicks && contentClicks?.redirectionLink}</b>
        </p>
      </div>
    </div>
  );
};

export default ContentClicksByEdition;
