import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getLocalStorage, getSessionStorage } from "src/helpers";

// sidebar nav config
import navigation from "../../../_nav";

const AppSidebar = () => {
  const location = useLocation();
  const userType = getLocalStorage("userType");

  return (
    <>
      <aside id="aside" className="active">
        <div className="sidebar">
          <ul className="menuList">
            {navigation.map((item, index) => {
              if (userType !== "ADMIN" && item.id === "SUB_ADMIN_MANAGEMENT") {
                return null;
              }
              return (
                <li key={index}>
                  <Link
                    to={item.to}
                    className={`${
                      location.pathname === item.to ? "sidebarLinks active" : ""
                    }`}
                  >
                    <span className="sideText">{item.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default React.memo(AppSidebar);
