import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { TableHead } from "@mui/material";

const ReportFreeTable = ({ heading, data }) => {
  const tableData = data && Object.keys(data).length > 0 && [data];
  return (
    <>
      <div className="grey-table full table-sec center">
        <Table>
          <TableHead>

            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell align="left" key={`key_${index + 1}`}>
                      {h}
                    </TableCell>
                  );
                })}
                
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{item?.registered}</TableCell>
                    <TableCell>{item?.converted}</TableCell>
                    <TableCell>{item?.expired}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default ReportFreeTable;
