import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import {
  GeographyOptions,
  NOT_AUTHORIZED_TEXT,
  ReferralOptions,
} from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal_Reports-Analytics";
import ReportTable from "../components/ReportFreeTable";
import { addPreffixforSelect, isEditAccess } from "src/helpers";
const heading = ["Registered", "Converted", "Expired"];

const Referral_Free_Trial_SignUps = ({
  setLoading,
  startDate,
  endDate,
  activeSalesAgent,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [referralFreeTrialSignUps, setReferralFreeTrialSignUps] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);
  const [salesAgentId, setSalesAgentId] = useState();

  useEffect(() => {
    getReferralFreeTrialSignUps();
  }, [byGeography, salesAgentId, endDate, startDate]);

  function getTimeStamp(date) {
    const now = new Date();
    return new Date(
      date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        now.getHours() +
        ":" +
        (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        ":" +
        (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds())
    );
  }

  const getReferralFreeTrialSignUps = (
    isSendEmailReport = false,
    personalisedData = {},
    sendReportRP
  ) => {
    setLoading(true);
    const url = `admin/reports-analytics/referral-free-trial-signups`;
    let dataToSent = {
      byGeography,
      isSendEmailReport,
    };
    if (startDate !== null && endDate !== null) {
      dataToSent = {
        ...dataToSent,
        startDate: getTimeStamp(startDate).toISOString(),
        endDate: getTimeStamp(endDate).toISOString(),
      };
    }
    if (personalisedData?.isPersonalized) {
      const { isPersonalized, subject, content } = personalisedData;
      dataToSent = {
        ...dataToSent,
        sendPersonalisedEmail: isPersonalized,
        mailSubject: subject,
        mailContent: content,
      };
    }

    if (sendReportRP) {
      dataToSent = {
        ...dataToSent,
        toSalesAgent: salesAgentId ? "ONE" : "ALL",
      };
    }

    if (salesAgentId) {
      dataToSent = {
        ...dataToSent,
        salesAgentId: salesAgentId,
      };
    }
    customAxios
      .post(url, { ...dataToSent })
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport && !personalisedData?.isPersonalized) {
            setReferralFreeTrialSignUps(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getReferralFreeTrialSignUps(true);
  };

  const sendReportPersonalized = (subject, content) => {
    setIsOpen(true);
    if (subject && content) {
      const personalisedData = { isPersonalized: true, subject, content };
      getReferralFreeTrialSignUps(false, personalisedData);
    }
  };
  const sendReportSalesAgent = () => {
    getReferralFreeTrialSignUps(true, {}, true);
  };

  return (
    <>
      <SEND_PERSONALIZED_EMAIL_MODAL
        open={isOpen}
        setOpen={setIsOpen}
        sendReportPersonalized={sendReportPersonalized}
      />
      <div className="in-div h-auto">
        <div className="heading">
          <div className="title">
            <h6 className="fs16">Referral Free Trial Sign-Ups</h6>
          </div>
        </div>
        <div className="select-option lg">
          <Select
            options={GeographyOptions}
            placeholder="Geo"
            components={addPreffixforSelect({
              prefix: "Geo: ",
              components,
            })}
            defaultValue={GeographyOptions[0]}
            onChange={(data) => {
              setGeography(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                referralFreeTrialByGeography: data.value,
              });
            }}
          />
          {activeSalesAgent && activeSalesAgent.length > 0 && (
            <Select
              options={activeSalesAgent}
              placeholder="RP"
              defaultValue={activeSalesAgent[0]}
              components={addPreffixforSelect({
                prefix: "RP: ",
                components,
              })}
              getOptionLabel={(data) => {
                if (data.firstName === "ALL") {
                  return data.firstName;
                }
                return (
                  data.firstName + " " + data.lastName + "-" + data.partnerId
                );
              }}
              getOptionValue={(data) => {
                return data._id;
              }}
              onChange={(data) => {
                setSalesAgentId(data._id);
                setDataForSendReport({
                  ...dataForSendReport,
                  salesAgentIdForReferralFreeTrial: data.value,
                });
              }}
            />
          )}
        </div>

        <div>
          <ReportTable heading={heading} data={referralFreeTrialSignUps} />
          <div className="text-right mb18">
            <a
              class="link ml18"
              onClick={() => {
                const isEdit = isEditAccess("REPORTS_ANALYTICS");
                if (isEdit) {
                  sendReportPersonalized();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              <u>Send Personalised Email</u>
            </a>
          </div>

          <div className="heading btn-grp flex-center mb0">
            <button
              className="btn"
              onClick={() => {
                const isEdit = isEditAccess("REPORTS_ANALYTICS");
                if (isEdit) {
                  sendReportOnMail();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              {/* <i className="msg-icon"></i> */} Send Report To Admin
            </button>

            <button
              className="btn-white"
              onClick={() => {
                const isEdit = isEditAccess("REPORTS_ANALYTICS");
                if (isEdit) {
                  sendReportSalesAgent();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              {/* <i className="msg-icon"></i> */} Send Report To RP
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referral_Free_Trial_SignUps;
