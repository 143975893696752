import React, { useEffect, useState } from "react";
import axios from "src/utils/axios";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import Filters from "./filter";
import { isBefore } from "date-fns";
import Loader from "src/components/common/loaders";
import moment from "moment";
// import { Switch } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // Input,
} from "@material-ui/core";

import {
  API_MESSAGES,
  NOT_AUTHORIZED_TEXT,
  // VALIDATION_MESSAGES,
} from "src/constant/global";
import { errorMessage } from "src/helpers";
import { errorAlert, successAlert } from "src/utils/alerts";
import { updateFreeMembershipEndDate } from "src/redux/services/user.service";
const BulkUpdate = () => {
  const [bulkLotlist, setBulkLotList] = useState([]);
  const [editableId, setEditableId] = useState();
  const [isEdit, setIsEdit] = useState(false)
  const [parterId, setParterId] = useState();
  const [databaseTypeId, setDatabaseTypeId] = useState();
  const [loading, setLoading] = useState(false);
  // const [totalCount, setTotalCount] = useState(1);
  const [curPage, setCurrPage] = useState(1);
  const dispatch = useDispatch()

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));

  useEffect(() => {
      handleSearchLot(parterId, databaseTypeId);
  }, [curPage]);
  const handleSearchLot = async (refrralPartner, databaseType) => {
    try {
      setLoading(true)

      let searchData = {
        salesAgentId: refrralPartner,
        databaseTypeId: databaseType,
        pageNo:curPage,
        limit:MAX_PAGE_LIMIT
      }
      Object.keys(searchData).forEach((key) => {
        if (searchData[key] === "ALL") {
          delete searchData[key];
        }
      });
      const queryString = new URLSearchParams(searchData).toString();

      const response = await axios.get(`admin/free-membership/user-lots?${queryString}`);
      if (response?.data?.statusCode === 200) {
        // successAlert(response?.data?.message);
        setBulkLotList(response?.data?.data);
        setLoading(false)
        return response;
      }
    } catch (error) {
      setLoading(false)
      let message = "";
      if (error?.response && error?.response?.data) {
        message = errorMessage(error) || "";
      } else {
        message = error?.message || "";
      }
    }
  }

  function convertLocalToUTCDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  }

  const classes = useStyles();

  const handleEditClick = (id) => {
    setEditableId(id);
  };


  const handleFilterData = (databaseType, parter) => {
    setDatabaseTypeId(databaseType?.value)
    setParterId(parter?.value)
  }
  const handleReset = () => {
    setBulkLotList("")
  }
  return (
    <div className="main-div user-manage">
      {loading && <Loader />}
      <div className="heading">
        <div className="title">
          <h6 className="">Bulk Update</h6>
        </div>
      </div>
      <Filters onEdit={handleFilterData} onSearch={handleSearchLot} onreset={handleReset} />
      {bulkLotlist.data && bulkLotlist.data.length ?
        <div className="full category-table">
          <Table className={classes.table} aria-label="caption table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Sr. No.</TableCell>
                <TableCell align="left">Type</TableCell>
                <TableCell align="left">Start Date</TableCell>
                <TableCell align="left">End Date</TableCell>
                <TableCell align="left">Record Count</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bulkLotlist.data &&
                bulkLotlist.data.length > 0 &&
                bulkLotlist.data.map((item, index) => {
                  return (
                    <TableRow key={item._id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item.freeMembershipType}</TableCell>
                      <TableCell>{moment.utc(item.lotStartDate).format("MMM DD, yyyy")}</TableCell>
                      <TableCell key={editableId}>
                        {isEdit && editableId === item._id ? (
                          <DatePicker
                            selected={
                              isBefore(new Date(), new Date(item.lotendDate))
                                ? convertLocalToUTCDate(item.lotendDate)
                                : new Date()
                            }
                            minDate={
                              isBefore(new Date(), new Date(item.lotendDate))
                                ? convertLocalToUTCDate(item.lotendDate)
                                : new Date()
                            }
                            onChange={(date) => {
                              setLoading(true)
                              dispatch(
                                updateFreeMembershipEndDate({

                                  lotId: item._id,
                                  databaseTypeId: databaseTypeId,
                                  salesAgentId: parterId,
                                  endDate: date,

                                  callback: (response) => {
                                    handleSearchLot(parterId, databaseTypeId)
                                    setEditableId("")
                                    setLoading(false)
                                  },
                                })
                              );
                            }}
                          />
                        ) : (
                          <TableCell>
                            {item?.lotendDate
                              ? moment
                                .utc(item?.lotendDate)
                                .format("MMM DD, yyyy")
                              : "-"}
                          </TableCell>
                        )}
                      </TableCell>

                      <TableCell>{item.usersCount}</TableCell>
                      <TableCell>
                        <>
                          <button
                            className="null-btn"
                            aria-label="edit"
                            onClick={() => {
                              setIsEdit(true)
                              handleEditClick(item._id);

                            }}
                          >
                            <i className="edit-icon"></i>
                          </button>
                        </>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {bulkLotlist.totalPage > 1 && (
            <Pagination
              count={bulkLotlist.totalPage}
              variant="outlined"
              shape="rounded"
              defaultPage={curPage}
              onChange={(type, page) => {
                setCurrPage(page);
              }}
            />
          )}
        </div> :
        <div className="no-content">
          <h6>No Content</h6>
          <p>No data available right now.</p>
          <p>
            Search lot by Database type and referral partner.
          </p>
        </div>
      }

    </div>
  );
};

export default React.memo(BulkUpdate);
