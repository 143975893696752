import React from "react";
import { firstLetterUpper } from "src/helpers";

const ErrorCounts = ({ errorData }) => {
  return (
    <div className="main-div h-auto mt24">
      <h6 className="m18">Counts</h6>

      <div className="count-cards">
        <div>
          <div className="c-card">
            <p>Total Error Encounter</p>
            <h6>{errorData && errorData?.data?.total}</h6>
          </div>
        </div>

        <div>
          <div className="c-card">
            <p>High Error Rate</p>
            <h6>
              {errorData &&
                firstLetterUpper(errorData?.hightestErrorModule?.module || "")}
            </h6>
          </div>
        </div>

        <div>
          <div className="c-card">
            <p>Low Error Rate</p>
            <h6>
              {errorData &&
                firstLetterUpper(errorData?.lowestErrorModule?.module || "")}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorCounts;
