import React, { useState } from "react";
import { Dialog } from "@mui/material";
import CancelButton from "../../../components/common/modal-cancel-btn";

export default function ViewUserDetails(props) {
  const { open, setOpen, userDetails } = props;
 
  return (
    <Dialog
      open={open}
      style={{ padding: "50px" }}
      onClose={() => setOpen(false)}
    >
      <div className="modal-text">
        <div className="close-btn">
          <CancelButton
            onClose={() => {
              setOpen(false);
            }}
          />
        </div>

        <div className="heading flex-center">
          <h5>Customer Details</h5>
        </div>

        <div className="text">
          <div className="form-in">
          <label className="label-font-16"><b>Email </b></label> <p>{userDetails.email}</p> <br />
          <label className="label-font-16"><b>First Name </b></label> <p>{userDetails.firstName}</p><br />
          <label className="label-font-16"><b>Last Name </b></label> <p>{userDetails.lastName}</p><br />
          <label className="label-font-16"><b>Phone </b></label> <p>+{userDetails.countryCode}-{userDetails.phone}</p>
            </div>
        </div>

        <div className="foot">
          <button
            className="btn"
            text="Close"
            onClick={() => setOpen(false)}
          >
            CLOSE
          </button>
        </div>
      </div>
    </Dialog>
  );
}
