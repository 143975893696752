import axios from "src/utils/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import appConfig from "src/appConfig";
import { errorAlert } from "src/utils/alerts";

//free membership

export const freeMembershipReferralPartnerList = createAsyncThunk(
  "/freeMembership/referral-partner-list",
  // Callback Function
  async ({ rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/referral-partner-list`,
        {
        }
      );
      if (response?.data?.statusCode === 200) {
        // successAlert(response?.data?.message);
      }
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }
  }
);

export const freeMembershipDatabaseType = createAsyncThunk(
  "/freeMembership/database-types",
  // Callback Function
  async ({ rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/database-types`,
        {
        }
      );
      if (response?.data?.statusCode === 200) {
        // successAlert(response?.data?.message);
      }
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }
  }
);
//get bulk update list
export const getBulkUpdateLotList = createAsyncThunk(
  "/freeMembership/user-lots",
  // Callback Function
  async ({ databaseType, refrralPartner, callback }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/free-membership/user-lots?salesAgentId=${refrralPartner}&databaseTypeId=${databaseType}`);
      if (response?.data?.statusCode === 200) {
        // successAlert(response?.data?.message);
      }
      return response;
    } catch (error) {
      let message = "";
      if (error?.response && error?.response?.data) {
        message = errorMessage(error) || "";
        if (callback) {
          callback(message);
        }
        return rejectWithValue(error?.response?.data);
      } else {
        message = error?.message || "";
        if (callback) {
          callback(message);
        }
        return rejectWithValue(error?.message);
      }
    }
  }
);

export const getReferralPartnerLink = createAsyncThunk(
  "/freeMembership/referral-partner-link",
  // Callback Function
  async (
    { pageNo = 1, limit = 10, callback, ...rest },
    { rejectWithValue }
  ) => {
    const data = { ...rest };

    Object.keys(data).forEach((key) => {
      if (data[key] === "") {
        delete data[key];
      }
    });
    try {
      let obj = { ...data, pageNo, limit }
      const queryString = new URLSearchParams(obj).toString();

      const response = await axios.get(
        `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/search-referral-partner-links?${queryString}`
      );
      if (response && response.data?.statusCode === 200) {
        callback(response.data);
      }
      return response;
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }
  }
);