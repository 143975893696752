import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { isValidEmail } from "src/helpers";

const Fields = [
  {
    id: 1,
    name: "email",
    placeholder: "Search by Email",
  },
  {
    id: 2,
    name: "name",
    placeholder: "Search by First and Last Name",
  },
  // {
  //   id: 3,
  //   name: "customerId",
  //   placeholder: "Search by Customer ID",
  // },
  // {
  //   id: 4,
  //   name: "transactionId",
  //   placeholder: "Search by Transaction ID",
  // },
];
const Filters = ({ onChange, onSearch }) => {
  const [error, setError] = useState(false);
  return (
    <div className="search-filter">
      {Fields.map((item, id) => {
        return (
          <div className="cols" key={id}>
            <div className="form-in icon">
              <span className="icon-left">
                <i className="search-icon"></i>
              </span>
              <DebounceInput
                placeholder={item.placeholder}
                debounceTimeout={500}
                onBlur={(e) => {
                  if (e.target.name === "email" && e.target.value) {
                    const isValid = isValidEmail(e.target.value.trim());
                    setError(!isValid);
                  }
                }}
                onChange={(e) => {
                  if (e.target.name === "email" && e.target.value === "") {
                    setError(false);
                  }
                  onChange(e);
                }}
                onKeyPress={(e) => {
                  if (!error && e.key === "Enter") {
                    onSearch();
                  }
                }}
                name={item.name}
              />
              {item.name === "email" && error && (
                <p className="error-msg"> Enter Valid Email </p>
              )}
            </div>
          </div>
        );
      })}
      <div className="cols">
        <button
          className="btn fs16"
          onClick={() => {
            if (!error) {
              onSearch();
            }
          }}
        >
          Search
        </button>
      </div>
    </div>
  );
};

export default Filters;
