import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { NOT_AUTHORIZED_TEXT, StatusOptions } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import DateRangePicker from "src/components/DateRangePicker";
import { addPreffixforSelect, isEditAccess } from "src/helpers";

const ReferralRevenueAndComission = ({
  setLoading,
  setDataForSendReport,
  dataForSendReport,
  activeSalesAgent,
}) => {
  const paymentGatewayOptions = [
    { value: "NON_INDIA", label: "USD" },
    { value: "INDIA", label: "INR" },
  ];
  const [transactionType, setTransactionType] = useState(
    StatusOptions[0].value
  );
  const [data, setData] = useState();
  const [salesAgentId, setSalesAgentId] = useState("");
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [selectedPaymentGateway, setPaymentGateway] = useState(
    paymentGatewayOptions[0].value
  );

  useEffect(() => {
    getReferralRevenueAndCommission();
  }, [
    transactionType,
    salesAgentId,
    rangeEnd,
    rangeStart,
    selectedPaymentGateway,
  ]);

  const getReferralRevenueAndCommission = (isSendEmailReport = false) => {
    setLoading(true);
    let url = `admin/reports-analytics/referral-revenue-and-commission?isSendEmailReport=${isSendEmailReport}&paymentGatewayType=${selectedPaymentGateway}&`;
    if (rangeStart !== null && rangeEnd !== null) {
      url =
        url +
        `startDate=${rangeStart.toISOString()}&endDate=${rangeEnd.toISOString()}&`;
    }
    if (salesAgentId) {
      url = url + `&salesAgentId=${salesAgentId}&`;
    }

    if (transactionType !== "ALL") {
      url = url + `transactionType=${transactionType}&`;
    }

    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setData(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getReferralRevenueAndCommission(true);
  };

  function getTimeStamp(date) {
    const now = new Date();
    return new Date(
      date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        now.getHours() +
        ":" +
        (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        ":" +
        (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds())
    );
  }

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    const newDate = getTimeStamp(d);
    setRangeStart(newDate);
  };

  const selectEndDate = (d) => {
    const newDate = getTimeStamp(d);
    setRangeEnd(newDate);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  return (
    <div className="main-div h-auto mt24">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Referral Revenue & Commission</h6>
        </div>
        <div className="ml-auto">
          <button
            className="btn"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendReportOnMail();
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            {/* <i className="msg-icon"></i> */} Send Report To Admin
          </button>
        </div>
      </div>

      <div className="">
        <div className="form-in date-pick d-flex">
          <DateRangePicker
            // setCurrPage={setCurrPage}
            selectStartDate={selectStartDate}
            selectEndDate={selectEndDate}
            startDate={rangeStart}
            endDate={rangeEnd}
            // minDate={rangeStart}
            disableEndDate={!rangeStart}
          />
          <button
            className="btn-white ml12"
            onClick={() => {
              clearDates();
            }}
          >
            Clear
          </button>
        </div>
      </div>

      <div className="select-option lg " style={{ marginTop: "20px" }}>
        {activeSalesAgent && activeSalesAgent.length > 0 && (
          <Select
            options={activeSalesAgent}
            placeholder="RP"
            defaultValue={activeSalesAgent[0]}
            components={addPreffixforSelect({
              prefix: "RP: ",
              components,
            })}
            getOptionLabel={(data) => {
              if (data.firstName === "ALL") {
                return data.firstName;
              }
              return (
                data.firstName + " " + data.lastName + "-" + data.partnerId
              );
            }}
            getOptionValue={(data) => {
              return data._id;
            }}
            onChange={(data) => {
              setSalesAgentId(data._id);
              setDataForSendReport({
                ...dataForSendReport,
                salesAgentIdForReferralRevenueCommission: data._id,
              });
            }}
          />
        )}

        <Select
          options={StatusOptions}
          placeholder="NR"
          components={addPreffixforSelect({
            prefix: "NR: ",
            components,
          })}
          defaultValue={StatusOptions[0]}
          onChange={(data) => {
            setTransactionType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              transactionTypeForReferralRevenueCommission: data.value,
            });
          }}
        />

        <Select
          options={paymentGatewayOptions}
          placeholder="Filter By"
          components={addPreffixforSelect({
            prefix: "Payments: ",
            components,
          })}
          defaultValue={paymentGatewayOptions[0]}
          onChange={(data) => {
            setPaymentGateway(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              paymentGatewayType: data.value,
            });
          }}
        />
      </div>

      <div className="content-flex">
        <p>
          Total Revenue
          <b>
            {data && data?.netRevenue
              ? `${selectedPaymentGateway === "INDIA" ? "₹" : "$"}${
                  data?.netRevenue
                }`
              : "0"}
          </b>
        </p>
        <p>
          Total Commission
          <b>
            {data && data?.salesAgentCommission
              ? `${selectedPaymentGateway === "INDIA" ? "₹" : "$"}${
                  data?.salesAgentCommission
                }`
              : "0"}
          </b>
        </p>
      </div>
    </div>
  );
};

export default ReferralRevenueAndComission;
