import React, { useEffect, useState } from "react";
import Loader from "src/components/common/loaders";
import { MAX_PAGE_LIMIT, MAX_PAGE_LIMIT_IN_SLIDER } from "src/constant/global";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import InReview from "./in-review";
import PendingRequests from "./pending-request";

const CancellationAndRefund = () => {
  const [selectedTab, setSelectedTab] = useState("pending");
  const [curPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [reviewTotal, setReviewTotal] = useState(0);
  const [pendingCancellationRequests, setPendingCancellationRequests] =
    useState([]);
  const [inReviewList, setInReviewList] = useState([]);

  useEffect(() => {
    getPendingCancellationRequest();
  }, []);

  useEffect(() => {
    getInReviewList();
  }, [curPage]);

  const getPendingCancellationRequest = ({
    pageNo = 1,
    isAddSliderData,
  } = {}) => {
    setLoading(true);

    customAxios
      .get(
        `admin/cancel-refund/requests-list?limit=${MAX_PAGE_LIMIT_IN_SLIDER}&page=${pageNo}&refundStatus=REQUESTED`
      )
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          const pendingRequest = !isAddSliderData
            ? response?.data?.data?.data
            : pendingCancellationRequests.concat(response?.data?.data?.data);
          setPendingCancellationRequests(pendingRequest);
        }
      })
      .catch((error) => {
        setLoading(false);

        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getInReviewList = () => {
    setLoading(true);

    customAxios
      .get(
        `admin/cancel-refund/requests-list?limit=${MAX_PAGE_LIMIT}&page=${curPage}`
      )
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          const totalPagesCount =
            response?.data?.data?.total && response?.data?.data?.total > 0
              ? Math.ceil(response?.data?.data?.total / MAX_PAGE_LIMIT)
              : 1;
          setReviewTotal(response?.data?.data?.total);
          setTotalCount(totalPagesCount);
          setInReviewList(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
        setLoading(false);
      });
  };

  return (
    <div className="main-div">
      <div className="heading">
        <div className="title">
          <h6 className="">Cancellation & Refund</h6>
        </div>

        <div className="btn-grp bg-grey">
          <button
            className={` ${selectedTab === "pending" ? "btn" : "btn-blank"}`}
            onClick={() => setSelectedTab("pending")}
          >
            Pending Requests
            {pendingCancellationRequests &&
              pendingCancellationRequests.length > 0 &&
              `(${pendingCancellationRequests.length})`}
          </button>
          <button
            className={` ${selectedTab === "inReview" ? "btn" : "btn-blank"}`}
            onClick={() => setSelectedTab("inReview")}
          >
            Review Refund {reviewTotal > 0 && `(${reviewTotal})`}
          </button>
        </div>
      </div>
      {loading && <Loader />}
      {selectedTab === "pending" && (
        <PendingRequests
          pendingRequestList={pendingCancellationRequests}
          getPendingCancellationRequest={getPendingCancellationRequest}
          getInReviewList={getInReviewList}
          pageNo={curPage}
          setPage={setCurrPage}
        />
      )}
      {selectedTab === "inReview" && (
        <InReview
          list={inReviewList}
          curPage={curPage}
          totalCount={totalCount}
          setCurrPage={setCurrPage}
          getPendingCancellationRequest={getPendingCancellationRequest}
          getInReviewList={getInReviewList}
        />
      )}
    </div>
  );
};

export default CancellationAndRefund;
