import React, { useEffect, useState } from "react";
import axios from "src/utils/axios";
import moment from "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@mui/material/Pagination";
import { format } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Input,
  Select,
  MenuItem,
  TextField,
} from "@material-ui/core";

//Action
import {
  errorMessage,
  firstLetterUpper,
  isEditAccess,
  isValidName,
  isValidMobile,
} from "src/helpers";
import { errorAlert, successAlert } from "src/utils/alerts";
import { NOT_AUTHORIZED_TEXT, MAX_PAGE_LIMIT } from "src/constant/global";

const ComplimentaryMemberList = ({
  membershipData,
  totalCount,
  curPage,
  setCurrPage,
  fetchComplimentaryMemberList,
  handleView,
}) => {
  const membershipList = membershipData?.data || [];
  const [editableId, setEditableId] = useState({});
  const [errors, setErrors] = useState({});
  const [regError, setRegErrors] = useState({});

  /*
   Styles for Material Ui table
*/

  const VALIDATION_MESSAGES_COMPLIMENTARY = {
    firstName: "Please Enter First Name.",
    lastName: "Please Enter Last Name.",
    contactNumber: "Please Enter Mobile No.",
    complimentaryMembershipStatus: "Please Select Status.",
    complimentaryMembershipEndDate: "Please Select End Date",
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));

  const handleInputChange = (value, id, type) => {
    let data = editableId && editableId[id] ? editableId[id] : {};
    data = { ...data, [type]: value };
    setEditableId({ ...editableId, [id]: data });
    setTimeout(() => {
      document.getElementById(`member-${type}`)?.focus();
    }, 100);
  };

  const splitName = (errName) => {
    return errName?.split(/(?=[A-Z])/).join("_");
  };

  const handleBlurInput = (id, name, value) => {
    let regexError = {
      ...regError,
    };

    if (
      (name === "firstName" ||
        name === "lastName" ||
        name === "contactNumber") &&
      !value
    ) {
      regexError = {
        ...regexError,
        [id]: {
          ...regexError[id],
          [name]: `Please Enter ${firstLetterUpper(splitName(name))}`,
        },
      };
      setRegErrors(regexError);
    } else if (name === "firstName" && value && !isValidName(value)) {
      regexError = {
        ...regexError,
        [id]: {
          ...regexError[id],
          [name]:
            "Please Enter a Valid First Name. A First Name Should Not Contain Special Characters Or Alphanumeric Characters.",
        },
      };
      setRegErrors(regexError);
    } else if (name === "lastName" && value && !isValidName(value)) {
      regexError = {
        ...regexError,
        [id]: {
          ...regexError[id],
          [name]:
            "Please Enter a Valid First Name. A Last Name Should Not Contain Special Characters Or Alphanumeric Characters.",
        },
      };
      setRegErrors(regexError);
    } else if (name === "contactNumber" && value && !isValidMobile(value)) {
      regexError = {
        ...regexError,
        [id]: {
          ...regexError[id],
          [name]:
            "Please Enter Valid Country Code And Mobile Number. The Mobile Number Must Have A Minimum Of 5 Digits And A Maximum Of 15 digits.",
        },
      };
      setRegErrors(regexError);
    } else {
      regexError = {
        ...regexError,
        [id]: {
          ...regexError[id],
        },
      };
      delete regexError[id][name];
      setRegErrors(regexError);
    }
  };

  const handleFocusInput = (id, type) => {
    let err = {
      ...errors,
    };
    if (errors && Object.keys(errors)?.length > 0 && errors[id]) {
      delete err[id];
      setErrors(err);
    }
    setTimeout(() => {
      document.getElementById(`member-${type}`)?.focus();
    }, 100);
  };

  {
    /*
  Custom Table  Cell Value  for View and Edit Table
*/
  }
  const CustomTableCell = (props) => {
    const { member, name } = props;
    const classes = useStyles();
    const categoryNameData = member[name];


    if (name === "firstName") {
      return (
        <TableCell align="left" className={classes.tableCell} key={member._id}>
          {editableId &&
          Object.keys(editableId)?.length > 0 &&
          editableId[member._id] ? (
            <div className="editable min">
              <Input
                inputProps={{ maxlength: 12 }}
                key={member._id}
                id={`member-${name}`}
                defaultValue={editableId[member._id]?.[name]}
                name={name}
                onBlur={(e) =>
                  handleBlurInput(member._id, name, e.target.value)
                }
                onFocus={() => handleFocusInput(member._id, name)}
                onChange={(e) =>
                  handleInputChange(e.target.value, member._id, "firstName")
                }
                className={
                  errors && Object.keys(errors).length > 0 && errors[member._id]
                    ? `${classes.input} error`
                    : `${classes.input}`
                }
              />
              {Object.keys(errors).length === 0 &&
                regError &&
                Object.keys(regError)?.length > 0 &&
                regError[member._id] && (
                  <p className="error-msg fs12">{regError[member._id][name]}</p>
                )}
            </div>
          ) : (
            categoryNameData
          )}
        </TableCell>
      );
    } else if (name === "lastName") {
      return (
        <TableCell align="left" className={classes.tableCell} key={member._id}>
          {editableId &&
          Object.keys(editableId).length > 0 &&
          editableId[member._id] ? (
            <div className="editable min">
              <Input
                inputProps={{ maxlength: 12 }}
                key={member._id}
                id={`member-${name}`}
                defaultValue={editableId[member._id]?.[name]}
                name={name}
                onBlur={(e) =>
                  handleBlurInput(member._id, name, e.target.value)
                }
                onFocus={() => handleFocusInput(member._id, name)}
                onChange={(e) =>
                  handleInputChange(e.target.value, member._id, "lastName")
                }
                className={
                  errors && Object.keys(errors).length > 0 && errors[member._id]
                    ? `${classes.input} error`
                    : `${classes.input}`
                }
              />
              {Object.keys(errors).length === 0 &&
                regError &&
                Object.keys(regError)?.length > 0 &&
                regError[member._id] && (
                  <p className="error-msg fs12">{regError[member._id][name]}</p>
                )}
            </div>
          ) : (
            categoryNameData
          )}
        </TableCell>
      );
    } else if (name === "contactNumber") {
      if(!categoryNameData){
        return (
          <TableCell align="left" className={classes.tableCell} key={member._id}>
            --
         </TableCell>
        )

      }
       return (
        <TableCell align="left" className={classes.tableCell} key={member._id}>
          {editableId &&
          Object.keys(editableId).length > 0 &&
          editableId[member._id] ? (
            <>
              <div className="editable phone">
                <PhoneInput
                  id={`member-${name}`}
                  inputStyle={{ width: "100px" }}
                  inputExtraProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                  value={`${editableId[member._id]?.countryCode}`}
                  onChange={(phone) => {
                    handleInputChange(phone, member._id, "countryCode");
                  }}
                  onBlur={() => handleBlurInput(member._id, name)}
                  onFocus={() => handleFocusInput(member._id, name)}
                />

                {/* need validation for phone input */}

                <TextField
                  type="text"
                  inputProps={{ maxlength: 15 }}
                  id={`member-${name}`}
                  value={editableId[member._id]?.[name]}
                  name="contactNo"
                  onChange={(e) => {
                    handleInputChange(
                      e.target.value,
                      member._id,
                      "contactNumber"
                    );
                  }}
                  onBlur={(e) =>
                    handleBlurInput(member._id, name, e.target.value)
                  }
                  onFocus={() => handleFocusInput(member._id, name)}
                  className={
                    errors &&
                    Object.keys(errors).length > 0 &&
                    errors[member._id]
                      ? `${classes.input} error`
                      : `${classes.input}`
                  }
                />
                {/* <br /> */}
              </div>
              {errors &&
                Object.keys(errors).length > 0 &&
                errors[member._id] && (
                  <p className="error-msg fs12">
                    {errors[member._id][name] === ""
                      ? VALIDATION_MESSAGES_COMPLIMENTARY[name]
                      : ""}
                  </p>
                )}

              {regError &&
                Object.keys(errors).length === 0 &&
                Object.keys(regError)?.length > 0 &&
                regError[member._id] && (
                  <p className="error-msg fs12">{regError[member._id][name]}</p>
                )}
            </>
          ) : (
            <>{`+${member?.countryCode} - ${categoryNameData}`}</>
          )}
        </TableCell>
      );
    } else if (name === "complimentaryMembershipStatus") {
      return (
        <TableCell align="left" className={classes.tableCell} key={member._id}>
          {editableId &&
          Object.keys(editableId).length > 0 &&
          editableId[member._id] ? (
            <div className="editable">
              <Select
                key={member._id}
                id={`member-${name}`}
                value={editableId[member._id]?.[name]}
                name={name}
                onBlur={() => handleBlurInput(member._id, name)}
                onFocus={() => handleFocusInput(member._id, name)}
                onChange={(e) => {
                  handleInputChange(
                    e.target.value,
                    member._id,
                    "complimentaryMembershipStatus"
                  );
                }}
                className={
                  errors && Object.keys(errors).length > 0 && errors[member._id]
                    ? `${classes.input} error`
                    : `${classes.input}`
                }
              >
                <MenuItem value={"LIVE"}>Live</MenuItem>
                <MenuItem value={"EXPIRED"}>Expired</MenuItem>
              </Select>
            </div>
          ) : (
            <span
              className={`chip-label ${
                categoryNameData === "LIVE" ? "blue" : ""
              }`}
            >
              {firstLetterUpper(categoryNameData)}
            </span>
          )}
        </TableCell>
      );
    } else if (name === "complimentaryMembershipEndDate") {
      return (
        <TableCell align="left" className={classes.tableCell} key={member._id}>
          {editableId &&
          Object.keys(editableId).length > 0 &&
          editableId[member._id] ? (
            <div className="editable">
              <DatePicker
                selectsStart
                id={`member-${name}`}
                selected={new Date(editableId[member._id]?.[name])}
                minDate={new Date(member?.complimentaryMembershipStartDate)}
                onChange={(e) => {
                  handleInputChange(
                    e,
                    member._id,
                    "complimentaryMembershipEndDate"
                  );
                }}
              />
              {editableId[member?._id]?.complimentaryMembershipStatus ===
                "LIVE" &&
                new Date(
                  editableId[member?._id]?.complimentaryMembershipEndDate
                ) <= new Date() && (
                  <div className="input-feedback error-msg">
                    {" "}
                    Please Select End Date To Change Status
                  </div>
                )}
              <br />
            </div>
          ) : (
            format(new Date(categoryNameData), "dd MMM, yyyy")
          )}
        </TableCell>
      );
    }
  };

  useEffect(() => {}, []);
  const classes = useStyles();

  const handleEditClick = (id, category) => {
    let data = { ...editableId };
    data = { ...data, [id]: category };
    setEditableId(data);
    // regErr;
  };

  const handleSaveClick = (id) => {
    let data = { ...editableId };
    if (
      id &&
      editableId &&
      Object.keys(editableId).length > 0 &&
      editableId[id] &&
      Object.keys(editableId[id]).length > 0
    ) {
      let dataToSend = {
        complimentaryMemberId: editableId[id]._id,
        firstName: editableId[id].firstName || "",
        lastName: editableId[id].lastName || "",
       
        endDate: moment(
          `${editableId[id].complimentaryMembershipEndDate}`
        ).utc(),
        status: editableId[id].complimentaryMembershipStatus,
      };

      if(editableId[id].countryCode){
        dataToSend = {
          ...dataToSend,
          contactNumber: editableId[id].contactNumber || "",
          countryCode: editableId[id].countryCode || 0,
        }
      }
      const initialValues = membershipList.find((element) => {
        return element._id === id;
      });

      if (
        initialValues?.complimentaryMembershipStatus ===
        editableId[id]?.complimentaryMembershipStatus
      ) {
        delete dataToSend.status;
      } else if (
        initialValues?.complimentaryMembershipEndDate ===
        editableId[id]?.complimentaryMembershipEndDate
      ) {
        delete dataToSend.endDate;
      }

      axios
        .put("admin/complimentary-membership/update", dataToSend)
        .then((res) => {
          if (
            editableId &&
            Object.keys(editableId).length > 0 &&
            editableId[id]
          ) {
            delete data[id];
            setEditableId(data);
          }
          if (res && res.data && res.data.data) {
            fetchComplimentaryMemberList();
          }
          // successAlert(res?.data?.message);
        })
        .catch((err) => {
          let message = "";

          if (err?.response && err?.response?.data) {
            message = errorMessage(err);
          }
          errorAlert(message);
        });
    }
  };

  return (
    <>
      <div className="light-table full table-sec compli-table">
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr. No.</TableCell>
              <TableCell align="left">Customer ID</TableCell>
              <TableCell align="left">First Name</TableCell>
              <TableCell align="left">Last Name</TableCell>
              <TableCell align="left">Email ID</TableCell>
              <TableCell align="left">Mobile No.</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Start Date</TableCell>
              <TableCell align="left">End Date</TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {membershipList &&
              membershipList?.length > 0 &&
              membershipList.map((member, index) => {
                return (
                  <TableRow key={member?._id}>
                    <TableCell>
                      {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>
                    <TableCell>{member?.customerId}</TableCell>

                    <CustomTableCell {...{ member, name: "firstName" }} />
                    <CustomTableCell {...{ member, name: "lastName" }} />
                    <TableCell>{member?.email}</TableCell>
                    <CustomTableCell {...{ member, name: "contactNumber" }} />
                    <CustomTableCell
                      {...{ member, name: "complimentaryMembershipStatus" }}
                    />

                    <TableCell>
                      {member?.complimentaryMembershipStartDate &&
                        format(
                          new Date(member?.complimentaryMembershipStartDate),
                          "dd MMM, yyyy"
                        )}
                    </TableCell>
                    <CustomTableCell
                      {...{ member, name: "complimentaryMembershipEndDate" }}
                    />

                    <TableCell>
                      <div className="table-btns">
                        <button
                          // className="null-btn"
                          // aria-label="edit"
                          onClick={() => handleView(member._id, member)}
                        >
                          <i className="view-icon"></i>
                        </button>
                        <>
                          {editableId &&
                          Object.keys(editableId).length > 0 &&
                          editableId[member._id] ? (
                            <button
                              className="btn small"
                              disabled={
                                (regError &&
                                  member._id &&
                                  regError[member._id] &&
                                  Object.keys(regError[member._id])?.length >
                                    0) ||
                                (editableId[member?._id]
                                  ?.complimentaryMembershipStatus === "LIVE" &&
                                  new Date(
                                    editableId[
                                      member?._id
                                    ]?.complimentaryMembershipEndDate
                                  ) <= new Date())
                              }
                              onClick={() => handleSaveClick(member._id)}
                            >
                              SAVE
                            </button>
                          ) : (
                            <button
                              // className="null-btn"
                              // aria-label="edit"
                              onClick={() => {
                                const isEdit = isEditAccess(
                                  "COMPLIMENTARY_MEMBERSHIP"
                                );
                                if (isEdit) {
                                  handleEditClick(member._id, member);
                                } else {
                                  errorAlert(NOT_AUTHORIZED_TEXT);
                                }
                              }}
                            >
                              <i className="edit-icon"></i>
                            </button>
                          )}
                        </>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {membershipList && membershipList?.length === 0 && (
          <div className="no-content">
            <h6>No Results Found</h6>
            <p>
              You haven’t added anything yet. Click on Add New button to add new
              Complimentary Member
            </p>
          </div>
        )}
      </div>
      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={curPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
    </>
  );
};

export default React.memo(ComplimentaryMemberList);
