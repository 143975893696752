import React, { useEffect, useState } from "react";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import ByGeography from "./ByGeography";
import BySource from "./BySource";
import Registration from "./Registration";
import moment from 'moment';

const FreeMemberships = ({ setLoading, startDate = null, endDate = null }) => {
  const [freeMembershipData, setMembershipData] = useState();
 
  useEffect(() => {
    getFreeTrialMemberships();
  }, []);

  useEffect(() => {
    getFreeTrialMemberships();
  }, [startDate, endDate]);

  const getFreeTrialMemberships = () => {
    setLoading(true);
    let url = `admin/sales-management-global/free-memberships?`;
    if (startDate !== null && endDate !== null) {
      url =
        url +
        `startDate=${moment(startDate).endOf("day").toDate()}&endDate=${ moment(endDate).endOf("day").toDate()}`;
    }
    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          setMembershipData(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      <Registration
        data={freeMembershipData && freeMembershipData?.registrations}
      />
      <BySource data={freeMembershipData && freeMembershipData?.bySource} />
      <ByGeography
        data={freeMembershipData && freeMembershipData?.byGeography}
      />
    </>
  );
};

export default FreeMemberships;
