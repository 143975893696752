import React, { useState, useEffect } from "react";
import customAxios from "src/utils/axios";
import { errorAlert } from "src/utils/alerts";
import moment from "moment-timezone";
import Totals from "./Totals";
import TransactionDetails from "./TransactionDetails";
import Loader from "src/components/common/loaders";
import Select, { components } from "react-select";
import {
  addPreffixforSelect,
  firstLetterUpper,
  isEditAccess,
} from "src/helpers";
const BillingAndPayments = () => {
  const paymentGatewayOptions = [
    { value: "NON_INDIA", label: "Non India" },
    { value: "INDIA", label: "India" },
  ];
  const paymentGatewayMethods = [
    { value: "INDIA", label: "Stripe" },
    { value: "PHONEPE_INDIA", label: "Phonepe" },
  ];
  const [loading, setLoading] = useState(false);
  const [billingData, setBillingData] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [monthYearBill, setMonthYearBill] = useState(new Date());
  const [monthYearPay, setMonthYearPay] = useState(new Date());
  const [isStripeIndia, setIsStripeIndia] = useState(false);
  const [selectedPaymentGateway, setPaymentGateway] = useState(
    paymentGatewayOptions[0].value
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentGatewayMethods[0].value
  );

  useEffect(() => {
    fetchBillings();
    fetchPayments();
  }, []);

  useEffect(() => {
    fetchBillings();
  }, [monthYearBill, selectedPaymentGateway, selectedPaymentMethod]);

  useEffect(() => {
    fetchPayments();
  }, [monthYearPay, selectedPaymentGateway, selectedPaymentMethod]);

  const fetchBillings = () => {
    setLoading(true);
    const month = moment(monthYearBill).month();
    const year = moment(monthYearBill).year();
    let gateway = "";
    if (selectedPaymentGateway === "NON_INDIA") {
      gateway = selectedPaymentGateway
    } else {
      gateway = selectedPaymentMethod
    }
    customAxios
      .get(
        `admin/billing-payments/total-billings-payments?billingMonth=${month}&billingYear=${year}&type=1&paymentGatewayType=${gateway}`
      )
      .then((res) => {
        if (res?.data?.statusCode) {
          if (selectedPaymentMethod === "PHONEPE_INDIA") {
            delete res?.data?.data["stripeCharges"];
          } else {
            delete res?.data?.data["phonepeCharges"];
          }
          setLoading(false);
          setBillingData(res?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const fetchPayments = () => {
    setLoading(true);
    const month = moment(monthYearPay).month();
    const year = moment(monthYearPay).year();
    let gateway = "";
    if (selectedPaymentGateway === "NON_INDIA") {
      gateway = selectedPaymentGateway
    } else {
      gateway = selectedPaymentMethod
    }
    customAxios
      .get(
        `admin/billing-payments/total-billings-payments?billingMonth=${month}&billingYear=${year}&type=2&paymentGatewayType=${gateway}`
      )
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          const data = {
            ...res?.data?.data,
            stripeCharges: 0,
            phonepeCharges: 0,
            difference: res?.data?.data?.systemBilling,
          };
          if (selectedPaymentMethod === "PHONEPE_INDIA") {
            delete data["stripeCharges"];
          } else {
            delete data["phonepeCharges"];
          }
          setPaymentData(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error?.response?.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };
  return (
    <>
      <div className="heading">
        <div className="btn-grp bg-grey">
          <button
            className={`${!isStripeIndia ? "btn" : "btn-blank"}`}
            onClick={() => {
              setIsStripeIndia(false);
              setPaymentGateway(paymentGatewayOptions[0].value);
              setSelectedPaymentMethod(paymentGatewayMethods[0].value);
            }}
          >
            USD
          </button>
          <button
            className={`${isStripeIndia ? "btn" : "btn-blank"}`}
            onClick={() => {
              setIsStripeIndia(true);
              setPaymentGateway(paymentGatewayOptions[1].value);
            }}
          >
            INR{" "}
          </button>
        </div>

        {
          selectedPaymentGateway == "INDIA" &&
          <Select
            options={paymentGatewayMethods}
            placeholder="Filter By"
            components={addPreffixforSelect({
              prefix: "Type: ",
              components,
            })}
            defaultValue={paymentGatewayMethods[0]}
            onChange={(data) => {
              setSelectedPaymentMethod(data.value);
            }}
          />
        }
      </div>


      <div className="row table-reports">
        {loading && <Loader />}
        <Totals
          isPayments={false}
          monthYear={monthYearBill}
          setMonthYear={setMonthYearBill}
          data={billingData}
          selectedPaymentMethod={selectedPaymentMethod}
        />
        <Totals
          isPayments={true}
          monthYear={monthYearPay}
          setMonthYear={setMonthYearPay}
          data={paymentData}
          setPaymentData={setPaymentData}
          selectedPaymentGateway={selectedPaymentGateway}
          selectedPaymentMethod={selectedPaymentMethod}
        />
        <TransactionDetails paymentGateway={selectedPaymentGateway} selectedPaymentMethod={selectedPaymentMethod} />
      </div>
    </>
  );
};

export default BillingAndPayments;
