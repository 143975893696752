const POST_PER_PAGE = 5;
const PAGE_NUMBER_LIMIT = 5;
const MAX_PAGE_LIMIT = 10;
const MAX_PAGE_LIMIT_IN_SLIDER = 100;

const VALIDATION_MESSAGES = {
  categoryInput: "Please Enter Category Name.",
  groupInput: "Please Enter Group Name.",
  topicInput: "Please Enter Topic Name.",
  maxLengthCategory: "The Maximum Length Of 16 Characters Is Reached.",
  maxLengthTopic: "The Maximum Length Of 25 Characters Is Reached.",
  maxLengthGroup: "The Maximum Length Of 20 Characters Is Reached.",
};

const API_MESSAGES = {
  addCategory: "Something Went Wrong While Adding Category",
  addCategorySuccess: "Category Has Been Added Successfully.",
  editCategorySuccess: "Category Has Been Edited Successfully.",
  editCategory: "Something Went Wrong While Editing Category",
  getError: "Something Went Wrong. Please Try Again",
  addGroup: "Something Went Wrong While Adding Group",
  addGroupSuccess: "Group Has Been Added Successfully.",
  addTopic: "Something Went Wrong While Adding Topic",
  addTopicSuccess: "Topic Has Been Added Successfully.",
  editTopicSuccess: "Topic Has Been Updated Successfully",
  editGroupSuccess: "Group Has Been Updated Successfully.",
  deleteEditionSuccess: "Edition Has Been Deleted Successfully",
};

const NOT_AUTHORIZED_TEXT =
  "You Are Not Authorized To Do This Action. Please Contact Admin";

const AccessModules = [
  {
    id: 0,
    label: "Dashboard",
    name: "DASHBOARD",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 1,
    label: "User Management",
    name: "USER_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 2,
    label: "Weekday Content Bank",
    name: "CONTENT_BANK_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 3,
    label: "Content & Edition Management",
    name: "EDITION_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 4,
    label: "Category Management",
    name: "CATEGORY_MANAGEMENT",
    add: false,
    edit: false,
    isView: false,
    delete: false,
  },

  {
    id: 5,
    label: "Topic Management",
    name: "TOPICS_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 16,
    label: "Group Management",
    name: "GROUP_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  // {
  //   id: 15,
  //   label: "Membership Rate Management",
  //   name: "MEMBERSHIP_RATE_MANAGEMENT",
  //   add: false,
  //   edit: false,
  //   view: false,
  //   delete: false,
  // },
  {
    id: 6,
    label: "Editor Pick Emailer",
    name: "EDITOR_PICK_EMAILER",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  // {
  //   id: 7,
  //   label: "Sales Agent Management",
  //   name: "SALES_AGENT_MANAGEMENT",
  //   add: false,
  //   edit: false,
  //   view: false,
  //   delete: false,
  // },
  {
    id: 8,
    label: "Cancellation Refund",
    name: "CANCELLATION_REFUND",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 9,
    label: "Membership Rate Management",
    name: "MEMBERSHIP_RATE_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 10,
    label: "Complimentary Membership",
    name: "COMPLIMENTARY_MEMBERSHIP",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 11,
    label: "Coupon Management",
    name: "COUPON_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 14,
    label: "Referral Partner Management",
    name: "REFERRAL_PARTNER_MANAGEMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 12,
    label: "Billing and Payment",
    name: "BILLING_PAYMENT",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 13,
    label: "Reports and Analytics",
    name: "REPORTS_ANALYTICS",
    add: false,
    edit: false,
    view: false,
    delete: false,
  },
  {
    id: 13,
    label: "Free Membership",
    name: "FREE_MEMBERSHIP",
    add: true,
    edit: true,
    view: true,
    delete: false,
  },
];

export const GeographyOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "INDIA",
    value: "INDIA_TIMEZONES",
  },
  {
    label: "REST OF SUB CONTINENT",
    value: "REST_OF_SUBCONTINENT_TIMEZONES",
  },
  {
    label: "MIDDLE EAST & AFRICA",
    value: "MIDDLE_EAST_AND_AFRICA_TIMEZONES",
  },
  {
    label: "EUROPE",
    value: "EUROPE_TIMEZONES",
  },
  {
    label: "AMERICAS",
    value: "AMERICAS_TIMEZONES",
  },
  {
    label: "FAR EAST JAPAN & CHINA",
    value: "EAST_JAPAN_AND_CHINA_TIMEZONES",
  },
  {
    label: "AUSTRALASIA",
    value: "AUSTRALASIA_TIMEZONES",
  },
  // {
  //   label: "OTHER",Referral Paid Memberships
  //   value: "OTHER",
  // },
];

export const MembershipOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "PROFESSIONAL",
    value: "PROFESSIONAL",
  },
  {
    label: "STUDENT",
    value: "STUDENT",
  },
  {
    label: "GIFT",
    value: "GIFTED",
  },
];

export const MembershipOptionsSales = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "PROFESSIONAL",
    value: "PROFESSIONAL",
  },
  {
    label: "STUDENT",
    value: "STUDENT",
  },
  {
    label: "GIFT PROFESSIONAL",
    value: "GIFTED_PROFESSIONAL",
  },
  {
    label: "GIFT STUDENT",
    value: "GIFTED_STUDENT",
  },
];

export const PaymentPlanOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "ANNUAL",
    value: "ANNUAL",
  },
  {
    label: "SEMI-ANNUAL",
    value: "SEMI_ANNUAL",
  },
  {
    label: "QUARTERLY",
    value: "QUARTERLY",
  },
  {
    label: "MONTHLY",
    value: "MONTHLY",
  },
];

export const StatusOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "NEW",
    value: "NEW",
  },
  {
    label: "RENEWAL",
    value: "RENEWAL",
  },
];

export const ReferralOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "ACTIVE",
    value: "ACTIVE",
  },
  {
    label: "INACTIVE",
    value: "INACTIVE",
  },
];

export const SourceOptions = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "ORGANIC",
    value: "ORGANIC",
  },
  {
    label: "REFERRAL",
    value: "REFERRAL",
  },
  {
    label: "CAMPAIGN",
    value: "CAMPAIGN",
  },
];

export const SourceOptionsSales = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "ORGANIC",
    value: "ORGANIC",
  },
  {
    label: "REFERRAL",
    value: "REFERRAL",
  },
  {
    label: "CAMPAIGN",
    value: "CAMPAIGN",
  },
];

export const SourceOptionsSales2 = [
  {
    label: "ALL",
    value: "ALL",
  },

  {
    label: "REFERRAL",
    value: "REFERRAL",
  },
  {
    label: "CAMPAIGN",
    value: "CAMPAIGN",
  },
];

export const editionFilter = [
  {
    label: "WEEKDAY",
    value: "WEEKDAY",
  },
  {
    label: "WEEKEND",
    value: "WEEKEND",
  },
];

export const ByTypeOptions = [
  {
    label: "LIVE",
    value: "LIVE",
  },
  {
    label: "LAPSED",
    value: "LAPSED",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "EXPIRED",
    value: "EXPIRED",
  },
];

export const ByTypeOptionsSales = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "LIVE",
    value: "LIVE",
  },
  {
    label: "LAPSED",
    value: "LAPSED",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "EXPIRED",
    value: "EXPIRED",
  },
  {
    label: "LAPSED CANCELLED",
    value: "LAPSED_CANCELLED",
  },
];

export const ByTypeOptionsSales2 = [
  // {
  //   label: "ALL",
  //   value: "ALL",
  // },
  {
    label: "LIVE",
    value: "LIVE",
  },
  {
    label: "LAPSED",
    value: "LAPSED",
  },
  {
    label: "CANCELLED",
    value: "CANCELLED",
  },
  {
    label: "EXPIRED",
    value: "EXPIRED",
  },
  {
    label: "LAPSED CANCELLED",
    value: "LAPSED_CANCELLED",
  },
];

export const StatusOptionsForSales = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "NEW",
    value: "NEW",
  },
  {
    label: "RENEWAL",
    value: "RENEWAL",
  },
];
export const Days = {
  year: 365,
  sixMonths: 182,
  threeMonths: 91,
};
let CommissionPercentage = [];
for (let i = 1; i < 100; i++) {
  CommissionPercentage.push({ label: i + "%", value: i });
}

let freeMonths = [];
for (let i = 1; i <= 12; i++) {
  freeMonths.push({ label: i, value: i });
}
export {
  POST_PER_PAGE,
  PAGE_NUMBER_LIMIT,
  MAX_PAGE_LIMIT,
  VALIDATION_MESSAGES,
  API_MESSAGES,
  AccessModules,
  NOT_AUTHORIZED_TEXT,
  CommissionPercentage,
  freeMonths,
  MAX_PAGE_LIMIT_IN_SLIDER,
};
