import React, { useState, useMemo } from "react";
import DatePicker from "react-datepicker";
import ModalComp from "src/components/common/modal";
import { EXPIRY_DATE_CONFIRMATION_TEXT } from "src/constant/modals";
import { isBefore } from "date-fns";
import { updateMembership } from "src/redux/services/user.service";
import { useDispatch } from "react-redux";
import { firstLetterUpper, isEditAccess } from "src/helpers";
import moment from "moment";
import { errorAlert } from "src/utils/alerts";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
const MembershipInfo = ({
  info,
  getUserList,
  editMemberships,
  setEditMemberships,
  membershipType,
  currentGiftSlide,
}) => {
  const [isEdit, setEdit] = useState(false);

  const dispatch = useDispatch();

  const onToggle = (isConfirm) => {
    if (isConfirm) {
      setEdit(true);
    }
    setEditMemberships({ value: !editMemberships.isOpen, key: "isOpen" });
  };

  const onToggleSaved = () => {
    setTimeout(() => {
      setEditMemberships({
        value: !editMemberships.isOpenSaved,
        key: "isOpenSaved",
      });
      setEdit(false);
    }, 1000);
  };

  const infoData =
    !membershipType && currentGiftSlide !== undefined
      ? info?.giftedTo[currentGiftSlide]
      : null;

  const dataSetOne = useMemo(() => {
    let lastAmountPaid = info?.membership?.membershipPlan?.price;
    if (info?.paymentTimezoneOffset === "+05:30") {
      lastAmountPaid = info?.membership?.membershipPlan?.inrPrice;
    }
    return {
      isFreeTrial: info?.isFreeTrial,
      membershipStatus: info?.isFreeTrial
        ? "-"
        : firstLetterUpper(info?.membership?.status),
      membershipType:
        info?.isFreeTrial || info.isComplimentaryMembership
          ? ""
          : !membershipType
          ? firstLetterUpper(info?.membership?.membershipPlan?.name) +
            " Membership"
          : firstLetterUpper(info?.membership?.membershipPlan?.subType) +
            " Membership",
      renewalDate: info?.isFreeTrial ? "" : info?.membership?.renewalDate,
      lapsedDate: info?.isFreeTrial ? "" : info?.membership?.lapsedDate,
      startDate: info?.isFreeTrial ? "" : info?.membership?.planStartedAt,
      endDate: info?.isFreeTrial ? "" : info?.membership?.planEndedAt,
      _id: info?.membership?._id,
      paymentPlan: info?.membership?.membershipPlan?.type
        ? firstLetterUpper(info?.membership?.membershipPlan?.type)
        : "-",
      paymentValidTill: info?.membership?.paymentValidTill,
      coupon: info?.membership?.coupon?.code || "-",
      // lastAmountPaid: info?.membership?.membershipPlan?.price,
      lastAmountPaid,
      refundAmount: info?.membership?.refundAmount,
      cancelation_date: info?.membership?.cancelledDate,
      paymentTimezoneOffset: info?.paymentTimezoneOffset,
    };
  }, [info]);

  const dataSetTwo = useMemo(() => {
    let lastAmountPaid = infoData?.membership?.membershipPlan?.price;
    if (infoData?.paymentTimezoneOffset === "+05:30") {
      lastAmountPaid = infoData?.membership?.membershipPlan?.inrPrice;
    }
    return {
      isFreeTrial: false,
      membershipStatus: infoData?.status
        ? firstLetterUpper(infoData?.status)
        : "-",
      membershipType: infoData?.isComplimentaryMembership
        ? ""
        : infoData?.membershipPlan?.subType
        ? firstLetterUpper(infoData?.membershipPlan?.subType) + " Membership"
        : "-",
      renewalDate: infoData?.renewalDate,
      lapsedDate: infoData?.lapsedDate,
      startDate: infoData?.planStartedAt,
      endDate: infoData?.planEndedAt,
      _id: infoData?._id,
      paymentPlan: infoData?.membershipPlan?.type
        ? firstLetterUpper(infoData?.membershipPlan?.type)
        : "-",
      paymentValidTill: infoData?.paymentValidTill,
      coupon: infoData?.coupon?.code || "-",
      // lastAmountPaid: infoData?.membershipPlan?.price,
      lastAmountPaid,
      // refundAmount: info?.membership?.refundAmount,
      cancelation_date: infoData?.cancelledDate,
      paymentTimezoneOffset: infoData?.paymentTimezoneOffset,
    };
  }, [infoData]);

  const personalInfoData = infoData === null ? dataSetOne : dataSetTwo;

  function convertLocalToUTCDate(date) {
    if (!date) {
      return date;
    }
    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  }

  const timezoneOffset = personalInfoData?.paymentTimezoneOffset;
  const isINR = timezoneOffset == "+05:30";
  const currencySymbol = isINR ? "₹" : "$";

  return (
    <div className="">
      <div className="member-info">
        <div className="heading">
          <h6 className="fs18 mb0">Membership Information</h6>
          <div className="ml-auto">
            <button
              className="null-btn"
              aria-label="edit"
              onClick={() => {
                const isEdit = isEditAccess("USER_MANAGEMENT");
                if (isEdit) {
                  onToggle();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              {personalInfoData.membershipType && (
                <i className="edit-blue-icon"></i>
              )}
            </button>
          </div>
        </div>

        <ul className="user-info">
          <li>
            <small>Membership Type</small>
            <p>{personalInfoData.membershipType || "-"}</p>
          </li>
          <li>
            <small>Membership Status</small>
            <p>{personalInfoData?.membershipStatus || "-"}</p>
          </li>
          <li>
            <small>Start Date</small>
            <p>
              {personalInfoData.startDate
                ? moment.utc(personalInfoData?.startDate).format("MMM DD, yyyy")
                : "-"}
            </p>
          </li>
          <li>
            <small>Renewal Date</small>
            <p>
              {personalInfoData.renewalDate
                ? moment(personalInfoData?.renewalDate).format("MMM DD, yyyy")
                : "-"}
            </p>
          </li>
          <li>
            <small>Lapsed Date</small>
            <p>
              {personalInfoData.lapsedDate
                ? moment(personalInfoData?.lapsedDate).format("MMM DD, yyyy")
                : "-"}
            </p>
          </li>
          <li>
            {isEdit ? (
              <DatePicker
                selected={
                  isBefore(new Date(), new Date(personalInfoData?.endDate))
                    ? convertLocalToUTCDate(personalInfoData?.endDate)
                    : new Date()
                }
                minDate={
                  isBefore(new Date(), new Date(personalInfoData?.endDate))
                    ? convertLocalToUTCDate(personalInfoData?.endDate)
                    : new Date()
                }
                onChange={(date) => {
                  dispatch(
                    updateMembership({
                      subscriptionId: personalInfoData._id,
                      endDate: convertLocalToUTCDate(date),
                      callback: (response) => {
                        onToggleSaved();
                        getUserList({ resetData: true });
                      },
                    })
                  );
                }}
              />
            ) : (
              <>
                <small>Expiry Date</small>
                <p>
                  {personalInfoData.endDate
                    ? moment
                        .utc(personalInfoData?.endDate)
                        .format("MMM DD, yyyy")
                    : "-"}
                </p>
              </>
            )}
          </li>
          <li>
            <small>Cancellation Date</small>
            <p>
              {personalInfoData.cancelation_date
                ? moment(personalInfoData?.cancelation_date).format(
                    "MMM DD, yyyy"
                  )
                : "-"}
            </p>
          </li>
          <li>
            <small>Payment Plan</small>
            <p>{personalInfoData?.paymentPlan}</p>
          </li>
          <li>
            <small>Last Payment Details</small>
            <p>
              {personalInfoData.paymentValidTill
                ? moment
                    .utc(personalInfoData?.paymentValidTill)
                    .format("MMM DD, yyyy")
                : "-"}
            </p>
          </li>
          <li>
            <small>Payment Valid Till</small>
            <p>
              {personalInfoData.paymentValidTill
                ? moment
                    .utc(personalInfoData?.paymentValidTill)
                    .format("MMM DD, yyyy")
                : "-"}
            </p>
          </li>
          <li>
            <small>Coupon Code</small>
            <p>{personalInfoData.coupon}</p>
          </li>
          <li>
            <small>Last Amount Paid</small>
            <p>
              {personalInfoData?.lastAmountPaid
                ? `${currencySymbol}${personalInfoData?.lastAmountPaid}`
                : "-"}
            </p>
          </li>
          <li>
            <small>Refund Amount</small>
            <p className="text-red">
              {personalInfoData?.refundAmount
                ? `${currencySymbol}${personalInfoData?.refundAmount}`
                : "-"}
            </p>
          </li>
        </ul>
        <ModalComp
          open={editMemberships?.isOpen}
          setOpen={onToggle}
          content={EXPIRY_DATE_CONFIRMATION_TEXT}
          onClickYes={() => onToggle(true)}
        />
      </div>
    </div>
  );
};

export default MembershipInfo;
