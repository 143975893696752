import React, { useMemo } from "react";
import { format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { firstLetterUpper } from "src/helpers";

const GiftSenderInfo = ({ info, membershipType }) => {
  const infoData = !membershipType ? info : info?.giftedBy;
  const personalInfoData = useMemo(() => {
    return {
      email: infoData?.email || "-",
      classification: "Gift Membership",
      customerId: infoData?.customerId || "-",
      firstName: infoData?.firstName || "-",
      lastName: infoData?.lastName || "-",
      contactNumber:
        infoData?.contactNumber && infoData?.countryCode
          ? `${infoData?.countryCode} - ${infoData?.contactNumber}`
          : "-",

      free_trial: {
        status: infoData?.isFreeTrial
          ? firstLetterUpper(infoData?.freeTrialStatus)
          : "Expired",

        start_date: infoData?.freeTrialStartDate || "",
        end_date: infoData?.freeTrialEndDate || "",
      },
      referral_source: info?.userSalesAgentDetails,
    };
  }, [infoData]);

  return (
    <>
      <div className="membership-div">
        <h6 className="fs18 mb12">Gift From Information</h6>

        <div>
          <ul className="user-info">
            <li>
              <small>Classification</small>
              <p>{personalInfoData.classification}</p>
            </li>

            <li>
              <small>Customer ID</small>
              <p>{personalInfoData.customerId}</p>
            </li>

            <li>
              <small>Email ID</small>
              <p>{personalInfoData.email}</p>
            </li>

            <li>
              <small>First Name</small>
              <p>{personalInfoData.firstName}</p>
            </li>

            <li>
              <small>Last Name</small>
              <p>{personalInfoData.lastName}</p>
            </li>

            <li>
              <small>Mobile Number</small>
              <p>{personalInfoData.contactNumber}</p>
            </li>
          </ul>

          {personalInfoData.referral_source && (
            <div className="status-infom">
              <div>
                <ul className="user-info">
                  <li>
                    <small>Referral Partner ID</small>
                    <p>{personalInfoData?.referral_source?.partnerId || "-"}</p>
                  </li>

                  <li>
                    <small>Referral Partner Name</small>
                    <p>
                      {personalInfoData?.referral_source?.firstName || "-"}{" "}
                      {personalInfoData?.referral_source?.lastName || "-"}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {/* <div className="status-info d-none">
            {personalInfoData?.free_trial && (
              <div>
                <h6
                  className={`${
                    personalInfoData?.free_trial?.status === "Expired"
                      ? "text-red"
                      : " "
                  }`}
                >
                  Free Trial Status - {personalInfoData?.free_trial?.status}
                </h6>

                <div className="text">
                  <div>
                    <small>Start Date</small>
                    <p>
                      {personalInfoData?.free_trial?.start_date
                        ? format(
                            utcToZonedTime(
                              new Date(
                                personalInfoData?.free_trial?.start_date
                              ),
                              "UTC"
                            ),
                            "MMM dd, yyyy"
                          )
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <small>End Date</small>
                    <p>
                      {personalInfoData?.free_trial?.end_date
                        ? format(
                            utcToZonedTime(
                              new Date(personalInfoData?.free_trial?.end_date),
                              "UTC"
                            ),
                            "MMM dd, yyyy"
                          )
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default GiftSenderInfo;
