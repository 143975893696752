import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import {
  GeographyOptions,
  MembershipOptions,
  NOT_AUTHORIZED_TEXT,
  PaymentPlanOptions,
  StatusOptions,
} from "src/constant/global";
import { addPreffixforSelect, isEditAccess } from "src/helpers";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import ReportTable from "../components/ReportPaidTable";
const heading = ["Live", "Expired", "Lapsed", "Cancelled", "Lapsed Cancelled"];

const Referral_Paid_Memberships = ({
  setLoading,
  selectedDate,
  activeSalesAgent,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [referralPaidMemberships, setReferralPaidMemberships] = useState();
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);
  const [membershipType, setMembershipType] = useState("");
  const [planType, setPlanType] = useState(PaymentPlanOptions[0].value);
  const [paymentType, setPaymentType] = useState(StatusOptions[0].value);
  const [salesAgentId, setSalesAgentId] = useState("");

  useEffect(() => {
    getReferralPidMemberships();
  }, [
    byGeography,
    salesAgentId,
    membershipType,
    planType,
    paymentType,
    selectedDate,
  ]);

  const getReferralPidMemberships = (
    isSendEmailReport = false,
    status,
    reportTo
  ) => {
    setLoading(true);
    let url = `admin/reports-analytics/referral-paid-memberships?byGeography=${byGeography}&isSendEmailReport=${isSendEmailReport}&`;
    if (selectedDate !== null) {
      url = url + `selectedDate=${selectedDate.toISOString()}&`;
    }
    if (membershipType && membershipType !== "ALL") {
      url = url + `membershipType=${membershipType}&`;
    }
    if (salesAgentId) {
      url = url + `salesAgentId=${salesAgentId}&`;
    }

    if (planType !== "ALL") {
      url = url + `planType=${planType}&`;
    }

    if (paymentType !== "ALL") {
      url = url + `paymentType=${paymentType}&`;
    }

    if (reportTo) {
      url = url + `sendReportTo=${reportTo}&`;
    }
    if (status) {
      url = url + `reportFor=${status}&`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            setReferralPaidMemberships(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);/
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = (status, reportTo) => {
    getReferralPidMemberships(true, status, reportTo);
  };

  const reportForAdmin = (status) => {
    getReferralPidMemberships(true, status, "ADMIN");
  };

  const reportForRP = (status) => {
    getReferralPidMemberships(true, status, "RP");
  };

  return (
    <div className="in-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Referral Paid Memberships</h6>
        </div>
        <div className="ml-auto btn-grp">
          <button
            className="btn"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendReportOnMail("ALL", "ADMIN");
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            {/* <i className="msg-icon"></i> */} Send Report To Admin
          </button>

          <button
            className="btn-white"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendReportOnMail("ALL", "RP");
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            {/* <i className="msg-icon"></i> */} Send Report To RP
          </button>
        </div>
      </div>
      <div className="select-option lg">
        <Select
          placeholder="Geo"
          options={GeographyOptions}
          components={addPreffixforSelect({
            prefix: "Geo: ",
            components,
          })}
          defaultValue={GeographyOptions[0]}
          onChange={(data) => {
            setGeography(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsByGeography: data.value,
            });
          }}
        />
        <Select
          placeholder="MT"
          options={MembershipOptions}
          components={addPreffixforSelect({
            prefix: "MT: ",
            components,
          })}
          defaultValue={MembershipOptions[0]}
          onChange={(data) => {
            setMembershipType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsMembershipType: data.value,
            });
          }}
        />
        <Select
          options={PaymentPlanOptions}
          placeholder="PP"
          components={addPreffixforSelect({
            prefix: "PP: ",
            components,
          })}
          defaultValue={PaymentPlanOptions[0]}
          onChange={(data) => {
            setPlanType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsPlanType: data.value,
            });
          }}
        />
        <Select
          options={StatusOptions}
          placeholder="NR"
          components={addPreffixforSelect({
            prefix: "NR: ",
            components,
          })}
          defaultValue={StatusOptions[0]}
          onChange={(data) => {
            setPaymentType(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              referralPaidMembershipsPaymentType: data.value,
            });
          }}
        />
        {activeSalesAgent && activeSalesAgent.length > 0 && (
          <Select
            options={activeSalesAgent}
            defaultValue={activeSalesAgent[0]}
            getOptionLabel={(data) => {
              if (data.firstName === "ALL") {
                return data.firstName;
              }
              return (
                data.firstName + " " + data.lastName + "-" + data.partnerId
              );
            }}
            getOptionValue={(data) => {
              return data._id;
            }}
            placeholder="RP"
            components={addPreffixforSelect({
              prefix: "RP: ",
              components,
            })}
            onChange={(data) => {
              setSalesAgentId(data._id);
              setDataForSendReport({
                ...dataForSendReport,
                salesAgentIdForReferralPaidMemberships: data._id,
              });
            }}
          />
        )}
      </div>
      <div>
        <ReportTable
          heading={heading}
          data={referralPaidMemberships}
          allPaid={false}
          reportForAdmin={reportForAdmin}
          reportForRP={reportForRP}
        />
      </div>
    </div>
  );
};

export default Referral_Paid_Memberships;
