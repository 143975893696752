import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import Pagination from "@mui/material/Pagination";
import Action from "src/components/common/Action";
import { firstLetterUpper } from "src/helpers";
import { CONFIRM_BLOCK_TEXT, CONFIRM_UNBLOCK_TEXT } from "src/constant/modals";
import ModalComp from "src/components/common/modal";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";

const heading = ["Sr. No.", "Name", "Email", "Status", "Action"];

const SubAdminList = ({
  list,
  curPage,
  totalCount,
  setCurrPage,
  setEdit,
  fetchSubAdminList,
  setSubAdminToEdit,
  searchKey,
}) => {
  const [subAdminToChangeStatus, setSubAdminToChangeStatus] = useState("");
  const [open, setOpen] = useState(false);

  const handleBlockAndUnBlock = () => {
    customAxios
      .put("admin/sub-admin-edit", {
        subAdminId: subAdminToChangeStatus._id,
        status:
          subAdminToChangeStatus.status === "ACTIVE" ? "BLOCKED" : "ACTIVE",
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          const message = `Sub Admin ${
            response?.data?.data?.status === "BLOCKED" ? "Blocked" : "Unblocked"
          } Successfully`;
          // successAlert(message);
          setSubAdminToChangeStatus("");
          fetchSubAdminList();
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      <div className="full table-sec">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h) => {
                  return <TableCell align="left">{h}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.length > 0 &&
              list.map((item, index) => {
                return (
                  <TableRow key={item?._id}>
                    <TableCell>
                      {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>
                    <TableCell>
                      <p className=" text-blue fw600">{item.firstName}</p>
                    </TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>
                      <span
                        className={`status-label ${
                          item.status === "BLOCKED" ? "red" : ""
                        }`}
                      >
                        {item.status ? firstLetterUpper(item.status) : "-"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Action
                        id={item._id}
                        isDelete={false}
                        isView={false}
                        isStatus={true}
                        isBlock={item.status === "ACTIVE"}
                        handleStatusChange={(id) => {
                          setSubAdminToChangeStatus(item);
                          setOpen(true);
                        }}
                        setEditContentId={() => {
                          setEdit(true);
                          setSubAdminToEdit(item);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={curPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
      {list && list.length === 0 && searchKey === "" && (
        <div className="no-content">
          <h6>No Sub Admin Added Yet</h6>
          <p>
            You haven’t added anything yet. Click on Add New button to add new
            Sub Admin
          </p>
        </div>
      )}

      {list && list.length === 0 && searchKey !== "" && (
        <div className="no-content">
          <h6>No Results Found</h6>
        </div>
      )}

      <ModalComp
        open={open}
        title="Confirmation"
        setOpen={() => setOpen(false)}
        content={
          subAdminToChangeStatus.status === "ACTIVE"
            ? CONFIRM_BLOCK_TEXT
            : CONFIRM_UNBLOCK_TEXT
        }
        onClickYes={() => {
          setOpen(false);
          handleBlockAndUnBlock();
        }}
      />
    </>
  );
};

export default SubAdminList;
