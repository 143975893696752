import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import DateRangePicker from "src/components/DateRangePicker";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { dateFormat, isAddAccess, isDeleteAccess } from "src/helpers";
import { getContentCardList } from "src/redux/services/contentBank.service";
import Loader from "src/components/common/loaders";
import Action from "src/components/common/Action";
import DeleteContentCard from "./delete-content";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import AddEditContent from "./add-content";

const heading = [
  "Sr. No.",
  "Content Piece Name",
  "Publishing Date",
  "Category",
  "Status",
  "Action",
];

const WeekDayContentBank = () => {
  const [addNew, setAddNew] = useState(false);
  const [totalCount, setTotalCount] = useState(1);
  const [curPage, setCurrPage] = useState(1);
  const [isOpen, setOpen] = useState(false);
  const [contentId, setContentId] = useState("");
  const [editContentId, setEditContentId] = useState("");
  const [contentData, setContentData] = useState();
  const [isView, setView] = useState(false);
  const [rangeStart, setRangeStart] = React.useState(null);
  const [rangeEnd, setRangeEnd] = React.useState(null);
  const [isDataFetched, setIsDataFetched] = React.useState(false);

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  const dispatch = useDispatch();
  const { loading, contentList } = useSelector((state) => {
    return state.content || [];
  });

  useEffect(() => {
    if ((rangeStart && rangeEnd) || (!rangeStart && !rangeEnd)) {
      getContentBankCardList();
    }
  }, [curPage, addNew, isView, rangeStart, rangeEnd]);

  const getContentBankCardList = () => {
    let url = `admin/contentBank/cards-list?limit=${MAX_PAGE_LIMIT}&page=${curPage}`;

    if (rangeStart !== null && rangeEnd !== null) {
      url = url + `&startDate=${rangeStart}&endDate=${rangeEnd}`;
    }

    dispatch(
      getContentCardList({
        url,
        callback: (res) => {
          setIsDataFetched(true);
          if (res) {
            const totalPagesCount =
              res.data.total && res.data.total > 0
                ? Math.ceil(res.data.total / MAX_PAGE_LIMIT)
                : 1;
            setTotalCount(totalPagesCount);
          }
        },
      })
    );
  };

  const handleDeleteContent = () => {
    customAxios
      .delete(`admin/contentBank/delete-card/${contentId}`)
      .then((res) => {
        // successAlert(res.data.message);
        getContentBankCardList();
      })
      .catch((err) => {});

    setContentId("");
    setOpen(false);
  };

  useEffect(() => {
    if (editContentId && isView) {
      const data = contentList.find((el) => {
        return el._id == editContentId;
      });
      setContentData(data);
    } else {
      setContentData();
    }
  }, [editContentId, isView]);

  return (
    <>
      {!addNew && !isView ? (
        <div className="main-div">
          <div className="heading">
            <div className="title">
              <h6>Weekday Content Bank Management</h6>
            </div>
            <div className="pr12 filter-inputs">
              <div className="form-in date-pick d-flex">
                <DateRangePicker
                  setCurrPage={setCurrPage}
                  selectStartDate={selectStartDate}
                  selectEndDate={selectEndDate}
                  startDate={rangeStart}
                  endDate={rangeEnd}
                  // minDate={rangeStart}
                  disableEndDate={!rangeStart}
                />
                <button
                  className="btn-white ml12"
                  onClick={() => {
                    clearDates();
                  }}
                >
                  Clear
                </button>
              </div>
            </div>
            <div className="btn-grp ml-auto">
              <button
                className="btn"
                onClick={() => {
                  const isAdd = isAddAccess("CONTENT_BANK_MANAGEMENT");
                  if (isAdd) {
                    setAddNew(true);
                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
              >
                ADD NEW CONTENT PIECE
              </button>
            </div>
          </div>
          <div className="full table-sec">
            {loading && <Loader />}
            <Table>
              <TableHead>
                <TableRow>
                  {heading &&
                    heading.length > 0 &&
                    heading.map((h, i) => (
                      <TableCell align="left" key={i}>
                        {h}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {contentList?.length > 0 &&
                  contentList.map((content, index) => {
                    return (
                      <TableRow key={content._id}>
                        <TableCell>
                          {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                        </TableCell>
                        <TableCell>{`Content Piece # ${
                          (curPage - 1) * MAX_PAGE_LIMIT + index + 1
                        }`}</TableCell>
                        <TableCell>
                          {dateFormat(content.publishDate) || "-"}
                        </TableCell>
                        <TableCell>{content.categoryName || ""}</TableCell>
                        <TableCell>
                          <span
                            className={`chip-label ${
                              content.status === "DRAFT" ? "blue" : ""
                            }`}
                          >
                            {content.status === "DRAFT" ? "Draft" : "Published"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <Action
                            id={content._id}
                            isEdit={false}
                            setDeleteId={(id) => {
                              const isDelete = isDeleteAccess(
                                "CONTENT_BANK_MANAGEMENT"
                              );
                              if (isDelete) {
                                setOpen(true);
                                setContentId(id);
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                            handleViewClick={(id) => {
                              setEditContentId(id);
                              setView(true);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {totalCount > 1 && (
              <Pagination
                page={curPage}
                count={totalCount}
                variant="outlined"
                shape="rounded"
                defaultPage={1}
                onChange={(type, page) => {
                  setCurrPage(page);
                }}
              />
            )}
            {isDataFetched && contentList?.length === 0 && (
              <div className="no-content">
                <h6>No Content Added Yet</h6>
                <p>
                  You haven’t added anything yet. Click on Add content button to
                  add new content for content bank
                </p>
              </div>
            )}
          </div>
        </div>
      ) : (
        <AddEditContent
          contentData={contentData}
          setAddNew={setAddNew}
          setEditContentId={setEditContentId}
          setView={setView}
        />
      )}
      <DeleteContentCard
        isOpen={isOpen}
        setOpen={setOpen}
        onDelete={handleDeleteContent}
      />
    </>
  );
};

export default WeekDayContentBank;
