import React, { useEffect, useState } from "react";
import Loader from "src/components/common/loaders";
import DateRangePicker from "src/components/DateRangePicker";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import ErrorList from "src/views/pages/error-logs/error-list";
import ErrorCounts from "./error-counts";
import ErrorGraph from "./error-graph";

const ErrorLogs = () => {
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [curPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [errorData, setErrorData] = useState();

  useEffect(() => {
    getErrorData();
  }, []);

  useEffect(() => {
    getErrorData();
  }, [curPage, rangeStart, rangeEnd]);

  const getErrorData = () => {
    setLoading(true);
    let url = `admin/error-management?page=${curPage}&limit=${MAX_PAGE_LIMIT}`;
    if (rangeStart !== null && rangeEnd !== null) {
      url = url + `&startDate=${rangeStart}&endDate=${rangeEnd}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          setErrorData(res?.data?.data);
          const totalPagesCount =
            res?.data?.data?.data?.total && res?.data?.data?.data?.total > 0
              ? Math.ceil(res?.data?.data?.data?.total / MAX_PAGE_LIMIT)
              : 1;
          setTotalCount(totalPagesCount);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  return (
    <>
      <div className="main-div h-auto mt24">
        <div className="heading">
          <div className="title">
            <h6 className="fw600">Error Log Management</h6>
          </div>
          <div className="ml-div">
            <div className="form-in date-pick d-flex">
              <DateRangePicker
                setCurrPage={setCurrPage}
                selectStartDate={selectStartDate}
                selectEndDate={selectEndDate}
                startDate={rangeStart}
                endDate={rangeEnd}
                minDate={rangeStart}
                disableEndDate={!rangeStart}
              />
              <button
                className="btn-white ml12"
                onClick={() => {
                  clearDates();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <ErrorList
        tableData={errorData && errorData?.data?.data}
        curPage={curPage}
        totalCount={totalCount}
        setCurrPage={setCurrPage}
      />

      <div className="row">
        <div className="col-md-8">
          <ErrorCounts errorData={errorData} />
        </div>

        <div className="col-md-4">
          <ErrorGraph graphData={errorData && errorData.graph} />
        </div>
      </div>
    </>
  );
};

export default ErrorLogs;
