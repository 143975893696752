import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { ByTypeOptionsSales2 } from "src/constant/global";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { addPreffixforSelect, firstLetterUpper } from "src/helpers";
import DatePicker from "react-datepicker";

const heading = [
  {
    id: 1,
    title: "Type",
  },
  {
    id: 4,
    title: "Select Date 1",
  },
  {
    id: 2,
    title: "Select Date 2",
  },
  {
    id: 2,
    title: "Difference",
  },
];
const ByStatus = ({ setLoading }) => {
  const [byStatus, setStatus] = useState(ByTypeOptionsSales2[0].value);
  const [statusData, setStatusData] = useState();
  const [selectedDate1, setSelectDate1] = useState(new Date());
  const [selectedDate2, setSelectDate2] = useState(null);

  useEffect(() => {
    getByStatus();
  }, [byStatus]);

  const getByStatus = () => {
    setLoading(true);
    let url = `admin/sales-management-global/paid-memberships-by-status?selectedDate1=${selectedDate1.toISOString()}&byStatus=${byStatus}&`;

    if (selectedDate2 !== null) {
      url = url + `selectedDate2=${selectedDate2}&`;
    }

    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          setStatusData(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  useEffect(() => {
    if (selectedDate1 || selectedDate2) {
      getByStatus();
    }
  }, [selectedDate1, selectedDate2]);

  const tableData =
    (statusData &&
      statusData.selectedDate1 &&
      Object.keys(statusData.selectedDate1).length > 0 &&
      Object.keys(statusData.selectedDate1).map((el) => {
        const field = statusData.selectedDate1[el];
        const key = el.replace("gift", "gift-_");
        return {
          name: firstLetterUpper(key),
          selectedDate1: field,
          selectedDate2: statusData?.selectedDate2
            ? statusData?.selectedDate2[el]
            : "-",
        };
      })) ||
    [];

  return (
    <div className="main-div h-auto mt24 no-pad-select">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Comparison</h6>
        </div>
        <div className="select-option ml-auto">
          <Select
            placeholder="Status  "
            options={ByTypeOptionsSales2}
            onChange={(data) => {
              setStatus(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "Status: ",
              components,
            })}
            defaultValue={ByTypeOptionsSales2[0]}
          />
        </div>
      </div>
      <div className="light-table full table-sec center-right">
        <Table>
          <TableRow>
            {heading &&
              heading.length > 0 &&
              heading.map((h, index) => {
                return (
                  <TableCell align="left" key={`key_${index + 1}`}>
                    {h?.title === "Select Date 1" ||
                    h?.title === "Select Date 2" ? (
                      <DatePicker
                        className="sm-input"
                        maxDate={new Date()}
                        placeholderText={
                          h?.title === "Select Date 1"
                            ? "Select Date 1"
                            : "Select Date 2"
                        }
                        selected={
                          h?.title === "Select Date 1"
                            ? selectedDate1
                            : selectedDate2
                        }
                        onChange={(date) => {
                          if (h?.title === "Select Date 1") {
                            setSelectDate1(date);
                          } else {
                            setSelectDate2(date);
                          }
                        }}
                      />
                    ) : (
                      h?.title
                    )}
                  </TableCell>
                );
              })}
          </TableRow>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>
                      {item.name === "Total" ? <b>{item.name}</b> : item.name}
                    </TableCell>

                    <TableCell>
                      {item.name === "Total" ? (
                        <b>{item.selectedDate1}</b>
                      ) : (
                        item.selectedDate1
                      )}
                    </TableCell>
                    <TableCell>
                      {item.name === "Total" ? (
                        <b>{item.selectedDate2}</b>
                      ) : (
                        item.selectedDate2
                      )}
                    </TableCell>
                    <TableCell>
                      {item.name === "Total" ? (
                        <b>{item.selectedDate1 - item.selectedDate2}</b>
                      ) : (
                        item.selectedDate1 - item.selectedDate2
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default ByStatus;
