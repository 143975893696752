import { useEffect, useState } from "react";
import Loader from "src/components/common/loaders";
import DateRangePicker from "src/components/DateRangePicker";
import customAxios from "src/utils/axios";
import All_Free_Trial_SignUps from "./All_Free_Trial_SignUps";
import All_Paid_Memberships from "./All_Paid_Memberships";
import ComplimentaryMemberships from "./ComplimentaryMemberships";
import ContentClicksByEdition from "./ContentClicksByEdition";
import InactiveUsers from "./InactiveUsers";
import ReferralRevenueAndComission from "./ReferralRevenueAndComission";
import Referral_Free_Trial_SignUps from "./Referral_Free_Trial_SignUps";
import Referral_Paid_Memberships from "./Referral_Paid_Memberships";
import Referral_Partner_Profile_Detail from "./Referral_Partner_Profile_Detail";
import Revenue_By_Month from "./Revenue_By_Month";
import DatePicker from "react-datepicker";
import { errorAlert } from "src/utils/alerts";
import {
  editionFilter,
  GeographyOptions,
  NOT_AUTHORIZED_TEXT,
  SourceOptions,
  StatusOptions,
} from "src/constant/global";
import moment from "moment";
import { isEditAccess } from "src/helpers";
const date = new Date();
const monthValue = moment(date).month();
const year = moment(date).year();

const InitialVluesForReport = {
  allFreeTrialByGeography: GeographyOptions[0].value,
  referralFreeTrialByGeography: GeographyOptions[0].value,
  allPaidMembershipsByGeography: GeographyOptions[0].value,
  referralPaidMembershipsByGeography: GeographyOptions[0].value,
  revenueByMonth: monthValue,
  revenueByYear: year,
  revenuebySource: SourceOptions[0].value,
  contentClicksFilterBy: editionFilter[0].value,
  transactionTypeForReferralRevenueCommission: StatusOptions[0].value,
};

const Reports_And_Analytics = () => {
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [curPage, setCurrPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [activeSalesAgent, setSalesAgent] = useState([]);
  const [nonFilterSection, setNonFilterSections] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const [dataForSendReport, setDataForSendReport] = useState(
    InitialVluesForReport
  );

  useEffect(() => {
    getActiveSalesAgent();
    getReferralComplementaryAndInActiveUserInfo();
  }, []);

  useEffect(() => {
    getReferralComplementaryAndInActiveUserInfo();
  }, [curPage]);

  const getActiveSalesAgent = () => {
    setLoading(true);
    customAxios
      .get(`admin/reports-analytics/active-referral-partners`)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          const data = res?.data?.data;
          data.unshift({ firstName: "ALL" });
          setSalesAgent(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getReferralComplementaryAndInActiveUserInfo = (
    sendEmailReport = ""
  ) => {
    setLoading(true);
    let url = `admin/reports-analytics/referral-inactive-users-info?`;
    let dataToSent = {};
    if (sendEmailReport) {
      dataToSent = {
        ...dataToSent,
        sendEmailReport,
      };
    }

    customAxios
      .post(url, { ...dataToSent })
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!sendEmailReport) {
            setNonFilterSections(res?.data?.data);
            const inActiveUsers = res?.data?.data?.inActiveUsersList;
            setTotalCount(inActiveUsers?.totalPage);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportToEmail = () => {
    setLoading(true);
    let dataToSent = {
      ...dataForSendReport,
    };
    if (dataToSent.revenuebySource === "ALL") {
      delete dataToSent.revenuebySource;
    }

    if (dataToSent.transactionTypeForReferralRevenueCommission === "ALL") {
      delete dataToSent.transactionTypeForReferralRevenueCommission;
    }

    if (rangeStart !== null && rangeEnd !== null) {
      dataToSent = {
        ...dataToSent,
        startDate: rangeStart.toISOString(),
        endDate: rangeEnd.toISOString(),
      };
    }
    if (selectedDate !== null) {
      dataToSent = {
        ...dataToSent,
        selectedDate,
      };
    }
    customAxios
      .post(`admin/reports-analytics/send-report-to-email`, {
        ...dataToSent,
      })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          // successAlert(res?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const selectStartDate = (d) => {
    if (d > rangeEnd) {
      setRangeEnd(null);
    }
    setRangeStart(d);
  };

  const selectEndDate = (d) => {
    setRangeEnd(d);
  };

  const clearDates = () => {
    setRangeEnd(null);
    setRangeStart(null);
  };

  function getTimeStamp(date) {
    const now = new Date();
    return new Date(
      date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        now.getHours() +
        ":" +
        (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        ":" +
        (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds())
    );
  }

  return (
    <>
      <div className="main-div h-auto mt24">
        <div className="heading mb0">
          <div className="title">
            <h6 className="fw600">Reports & Analytics</h6>
          </div>
        </div>
      </div>
      {loading && <Loader />}
      <div className="main-div h-auto mt24 report-analytics">
        <div className="heading no-sm flex-center">
          {/* <h6 className="fw600  pr24">Filter By Date Range</h6> */}
          <div className="">
            <div className="form-in date-pick d-flex">
              <DateRangePicker
                setCurrPage={setCurrPage}
                selectStartDate={selectStartDate}
                selectEndDate={selectEndDate}
                startDate={rangeStart}
                endDate={rangeEnd}
                minDate={rangeStart}
                disableEndDate={!rangeStart}
              />
              <button
                className="btn-white ml12"
                onClick={() => {
                  clearDates();
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <All_Free_Trial_SignUps
              startDate={rangeStart}
              endDate={rangeEnd}
              setLoading={setLoading}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
          </div>

          <div className="col-md-6">
            <Referral_Free_Trial_SignUps
              startDate={rangeStart}
              endDate={rangeEnd}
              setLoading={setLoading}
              activeSalesAgent={activeSalesAgent}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
          </div>
        </div>
      </div>
      <div className="main-div h-auto mt24 report-analytics">
        <div className="heading flex-center no-sm">
          <h6 className="fw600  pr30">Filter By Specific Date</h6>
          <div className="pr24">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                const formatedDate = getTimeStamp(date);
                setSelectedDate(formatedDate);
              }}
              placeholderText="Select Date"
            />
          </div>
          <button
            className="btn-white"
            onClick={() => {
              setSelectedDate(null);
            }}
          >
            Clear
          </button>
        </div>
        <div className="row">
          <div className="col-md-12">
            <All_Paid_Memberships
              selectedDate={selectedDate}
              setLoading={setLoading}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
            <Referral_Paid_Memberships
              selectedDate={selectedDate}
              setLoading={setLoading}
              activeSalesAgent={activeSalesAgent}
              dataForSendReport={dataForSendReport}
              setDataForSendReport={setDataForSendReport}
            />
          </div>

          <div className="col-md-12"></div>

          <div className="col-12 mt18">
            <ComplimentaryMemberships
              selectedDate={selectedDate}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
      <div className="main-div h-auto  mt24 report-analytics">
        <InactiveUsers
          setLoading={setLoading}
          setCurrPage={setCurrPage}
          totalCount={totalCount}
          curPage={curPage}
          getReferralComplementaryAndInActiveUserInfo={
            getReferralComplementaryAndInActiveUserInfo
          }
          data={nonFilterSection && nonFilterSection?.inActiveUsersCount}
        />
      </div>

      <div className="row report-analytics">
        <div className="col-md-6 ">
          <Referral_Partner_Profile_Detail
            getReferralComplementaryAndInActiveUserInfo={
              getReferralComplementaryAndInActiveUserInfo
            }
            setLoading={setLoading}
            data={
              nonFilterSection &&
              nonFilterSection?.referralPartnersProfileDetails
            }
          />
        </div>
        <div className="col-md-6 mt24">
          <Revenue_By_Month
            setLoading={setLoading}
            dataForSendReport={dataForSendReport}
            setDataForSendReport={setDataForSendReport}
          />
        </div>
      </div>

      <div className="row report-analytics">
        <div className="col-md-6 mt24">
          <ContentClicksByEdition
            setLoading={setLoading}
            dataForSendReport={dataForSendReport}
            setDataForSendReport={setDataForSendReport}
          />
        </div>

        <div className="col-md-6 ">
          <ReferralRevenueAndComission
            setLoading={setLoading}
            dataForSendReport={dataForSendReport}
            setDataForSendReport={setDataForSendReport}
            activeSalesAgent={activeSalesAgent}
          />
        </div>
      </div>

      <div className="text-center mt24">
        <button
          className="btn"
          onClick={() => {
            const isEdit = isEditAccess("REPORTS_ANALYTICS");
            if (isEdit) {
              sendReportToEmail();
            } else {
              errorAlert(NOT_AUTHORIZED_TEXT);
            }
          }}
        >
          Email all Reports
        </button>
      </div>
    </>
  );
};

export default Reports_And_Analytics;
