import React, { useState, useMemo, useEffect } from "react";
import DatePicker from "react-datepicker";
import ModalComp from "src/components/common/modal";
import { EXPIRY_DATE_CONFIRMATION_TEXT } from "src/constant/modals";
import { isBefore } from "date-fns";
import { updateFreeMembershipEndDate } from "src/redux/services/user.service";
import { useDispatch } from "react-redux";
import { firstLetterUpper, isEditAccess } from "src/helpers";
import moment from "moment";
import { errorAlert } from "src/utils/alerts";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
const MembershipInfo = ({
  info,
  getUserList,
  editMemberships,
  setEditMemberships,
  membershipType,
  currentGiftSlide,
}) => {
  const [isEdit, setEdit] = useState(false);
  const [selectedDate, setSelectedDate] = useState("")
  const [membershipStatus, setSetMembershipStatus] = useState("false");

  const dispatch = useDispatch();



  const onToggleSaved = () => {
    setTimeout(() => {
      setEditMemberships({
        value: !editMemberships.isOpenSaved,
        key: "isOpenSaved",
      });
      setEdit(false);
    }, 1000);
  };

  const personalInfoData = info;

  const onToggle = (isConfirm) => {
    if (isConfirm) {
      setEdit(true);
      let freeMembershipEndDate = new Date(personalInfoData?.freeMembershipEndDate);
      let currentDate = new Date()
      if (freeMembershipEndDate instanceof Date && !isNaN(freeMembershipEndDate)) {

        const isBeforeDate = (date1, date2) => date1.getTime() < date2.getTime();

        let date = isBeforeDate(currentDate, freeMembershipEndDate) ? freeMembershipEndDate : new Date()
        date = new Date(
          moment(date).utcOffset(0).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          }))
          console.log("yyyyyy",date)
        setSelectedDate(date);
      }
    }
    setEditMemberships({ value: !editMemberships.isOpen, key: "isOpen" });
  };
  useEffect(() => {
    if (personalInfoData && personalInfoData?.freeMembershipStatus) {
      let status = personalInfoData?.freeMembershipStatus
      if (status === "LIVE") {
        setSetMembershipStatus("Free Membership - Live")
      }
      else if (status === "EXPIRED") {

        setSetMembershipStatus("Free Membership - Expired")
      }
      else if (status === "CONVERTED") {

        setSetMembershipStatus("Converted")
      }
      else if (status === "NOT_OPT_IN") {

        setSetMembershipStatus("No OPT-In")
      }
      else if (status === "NOT_SIGNED_IN") {

        setSetMembershipStatus("No Signed In")
      }

    }

  }, [personalInfoData])

  function convertLocalToUTCDate(date) {
    if (!date) {
      return date;
    }

    date = new Date(date);
    date = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    return date;
  }

  const timezoneOffset = personalInfoData?.paymentTimezoneOffset;
  const isINR = timezoneOffset == "+05:30";
  const currencySymbol = isINR ? "₹" : "$";

  return (
    <div className="">
      <div className="membership-info">
        <div className="heading">
          <h6 className="fs18 mb0">Free Membership Information</h6>
          <div className="ml-auto">
            <button
              className="null-btn"
              aria-label="edit"
              onClick={() => {
                const isEdit = isEditAccess("USER_MANAGEMENT");
                if (isEdit) {
                  onToggle();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              {personalInfoData.membershipType && (
                <i className="edit-blue-icon"></i>
              )}
            </button>
          </div>
        </div>

        <ul className="user-info no-grow">
          <li>
            <small>Customer ID</small>
            <p>{personalInfoData?.customerId}</p>
          </li>

          <li>
            <small>First Name</small>
            <p>{personalInfoData?.firstName}</p>
          </li>

          <li>
            <small>Last Name</small>
            <p>{personalInfoData?.lastName}</p>
          </li>

          <li>
            <small>Email ID</small>
            <p>{personalInfoData?.email}</p>
          </li>
          <li>
            <small>Referral Partner</small>
            <p>{personalInfoData?.userSalesAgentDetails?.firstName || "-"} {personalInfoData?.userSalesAgentDetails?.lastName || "-"}</p>
          </li>
          <li>
            <small>Database Type</small>
            <p>{personalInfoData?.databaseName || "-"}</p>
          </li>

          <li>
            <small>Status</small>
            <p>{membershipStatus || "-"}</p>
          </li>

          <li>
            <small>Mobile Number</small>
            <p>{personalInfoData?.contactNumber}</p>
          </li>
          <li>
            <small>Free Membership Start Date</small>
            <p>
              {personalInfoData.freeMembershipStartDate
                ? moment.utc(personalInfoData?.startDate).format("MMM DD, yyyy")
                : "-"}
            </p>
          </li>

          {isEdit ? (
            <li>
              <small>Date</small>
              <DatePicker
                selectsStart
                selected={
                  moment(selectedDate).toDate()
                }
                minDate={
                  isBefore(new Date(), new Date(personalInfoData?.freeMembershipEndDate))
                    ? convertLocalToUTCDate(personalInfoData?.freeMembershipEndDate)
                    : new Date()
                }
                onChange={(date) => {
                  setEdit(false)
                  dispatch(
                    updateFreeMembershipEndDate({
                      userId: personalInfoData._id,
                      databaseTypeId: personalInfoData?.databaseTypeId,
                      salesAgentId: personalInfoData?.userSalesAgentDetails?._id,
                      endDate: moment(date).endOf("day").toDate(),
                      callback: (response) => {
                        onToggleSaved();
                        getUserList({ resetData: true });
                      },
                    })
                  );
                }}
              />
            </li>

          ) : (
            <li>
              <small>Free Membership Expiry Date</small>
              <p>
                {/* {personalInfoData.freeMembershipEndDate} */}
                {moment.utc(personalInfoData.freeMembershipEndDate).format("DD MMM, YYYY")}
                {/* {personalInfoData.freeMembershipEndDate
                  ? moment(new Date(personalInfoData?.freeMembershipEndDate)).format("MMM DD, yyyy")
                  : "-"} */}
              </p>
            </li>
          )}
          <li>
            <small>Active Or Not</small>
            <p >{personalInfoData.status}</p>
          </li>
        </ul>
        <ModalComp
          open={editMemberships?.isOpen}
          setOpen={onToggle}
          content={EXPIRY_DATE_CONFIRMATION_TEXT}
          onClickYes={() => onToggle(true)}
        />
      </div>
    </div>
  );
};

export default MembershipInfo;
