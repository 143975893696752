import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import DateRangePicker from "src/components/DateRangePicker";
import moment from "moment-timezone";

const heading = [
  "By Referral Partner",
  "Enroll DT",
  "Registered",
  "Converted",
  "Live",
  "Expired",
];

const SalesAgentsCountsList = ({
  salesAgentsCountsList,
  getSalesAgentDetail,
  listStartDate,
  setListStartDate,
  listEndDate,
  setListEndDate,
  signupsStartDate,
  signupsEndDate,
}) => {
  const { sales, totalConverted, totalRegistered, totalLive, totalExpired } =
    salesAgentsCountsList;
  const [curPage, setCurrPage] = useState(1);

  const selectStartDate = (d) => {
    let check = d !== null && listEndDate !== null;
    if (d > listEndDate) {
      setListEndDate(null);
      check = false;
    }
    setListStartDate(d);
    if (check) {
      getSalesAgentDetail({
        type: "FREE_TRAIL",
        listStartDate: d,
        listEndDate: listEndDate,
        signupsEndDate,
        signupsStartDate,
      });
    }
  };

  const selectEndDate = (d) => {
    setListEndDate(d);
    if (listStartDate !== null && d !== null) {
      getSalesAgentDetail({
        type: "FREE_TRAIL",
        listStartDate: listStartDate,
        listEndDate: d,
        signupsEndDate,
        signupsStartDate,
      });
    }
  };

  const clearDates = () => {
    setListEndDate(null);
    setListStartDate(null);
    if (listStartDate !== null && listEndDate !== null) {
      getSalesAgentDetail({
        type: "FREE_TRAIL",
        signupsEndDate,
        signupsStartDate,
      });
    }
  };

  return (
    <div
      className="main-div h-auto"
      style={{
        marginTop: "50px",
      }}
    >
      <div className="heading">
        <div className="form-in date-filter ml-auto mb0">
          <DateRangePicker
            setCurrPage={setCurrPage}
            selectStartDate={selectStartDate}
            selectEndDate={selectEndDate}
            startDate={listStartDate}
            endDate={listEndDate}
            minDate={listStartDate}
            disableEndDate={!listStartDate}
          />
        </div>
        <div className="">
          <button
            className="btn-white ml12"
            onClick={() => {
              clearDates();
            }}
          >
            Clear
          </button>
        </div>
      </div>
      <div className="full light-table yellow-line center-right">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell align="left" key={`key_${index + 1}`}>
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {sales &&
              sales.length > 0 &&
              sales.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{item?.firstName ||''} {item?.lastName ||''}</TableCell>
                    <TableCell>
                      {item?.approvedAt
                        ? moment(item?.approvedAt).format("MMM DD, YYYY")
                        : "-"}
                    </TableCell>
                    <TableCell>{item?.registeredUsersCount}</TableCell>
                    <TableCell>{item?.convertedUsersCount}</TableCell>
                    <TableCell>{item?.liveUsersCount}</TableCell>
                    <TableCell>{item?.expiredUsersCount}</TableCell>
                  </TableRow>
                );
              })}
            {sales && sales.length > 0 && (
              <TableRow>
                <TableCell>Total</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <b>{totalRegistered}</b>
                </TableCell>
                <TableCell>
                  <b>{totalConverted}</b>
                </TableCell>
                <TableCell>
                  <b>{totalLive}</b>
                </TableCell>
                <TableCell>
                  <b>{totalExpired}</b>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        {sales && sales.length === 0 && (
          <div className="no-content">
            <h6>No Content</h6>
            <p>No data available right now.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesAgentsCountsList;
