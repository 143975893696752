import React, { useEffect, useState } from "react";
import GiftRecipientInfo from "./GiftRecipientInfo/GiftRecipientInfo";
import GiftSenderInfo from "./GiftSenderInfo";
import MembershipInfo from "./MembershipInfo";
import PaymentHistory from "./PaymentHistory";
import Carousel from "src/components/common/Carousel";
import { useSelector } from "react-redux";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";

const GiftMemberShip = ({
  usersData,
  setCurrentSlide,
  currentSlide,
  getUserList,
  membershipType,
  pageNo,
  setPage,
  handleCanceMembership,
}) => {
  const [editMemberships, setEditMemberships] = useState([]);
  const [currentGiftSlide, setCurrentGiftSlide] = useState(0);
  const [sendPersonalizedEmail, setSendPersonalizedEmail] = useState([]);
  const [sendPersonalizedEmailToGift, setSendPersonalizedEmailToGift] =
    useState([]);
  const [isSendPersonalizedToRecipient, setSendPersonalizedToRecipient] =
    useState(false);

  const { loading } = useSelector((state) => {
    return state.users;
  });

  useEffect(() => {
    if (usersData && usersData?.length > 0) {
      const data = usersData.map((el) => {
        return {
          isOpen: false,
          isOpenSaved: false,
        };
      });
      const emailPopupData = usersData.map((el) => {
        return {
          open: false,
        };
      });
      setEditMemberships(data);
      setSendPersonalizedEmail(emailPopupData);
    }
  }, [usersData]);

  useEffect(() => {
    if (usersData && usersData?.length > 0) {
      const giftModalData = usersData[currentGiftSlide]
        ? usersData[currentGiftSlide]?.giftedTo
        : [];

      if (giftModalData && giftModalData.length > 0) {
        const rawData = giftModalData.map((el) => {
          return {
            giftOpen: false,
          };
        });
        setSendPersonalizedEmailToGift(rawData);
      }
    }
  }, [currentGiftSlide, currentSlide]);

  const handleSendPersonalizedEmail = () => {
    const popupData = [...sendPersonalizedEmail];
    let modalToOpen = popupData[currentSlide];
    modalToOpen = {
      open: !modalToOpen.open,
    };
    popupData[currentSlide] = modalToOpen;
    setSendPersonalizedEmail(popupData);
  };

  const handleSendPersonalizedEmailToGiftRecipient = () => {
    const popupData = [...sendPersonalizedEmailToGift];
    let modalToOpen = popupData[currentGiftSlide];
    modalToOpen = {
      giftOpen: !modalToOpen.giftOpen,
    };
    popupData[currentGiftSlide] = modalToOpen;
    setSendPersonalizedEmailToGift(popupData);
  };

  return (
    <div>
      {usersData && usersData.length > 0 ? (
        usersData?.length === 1 ? (
          usersData?.map((userData, index) => {
            const data = !membershipType ? userData?.giftedTo : null;
            if (data && data.length > 1) {
              return (
                <div className="" key={index}>
                  <Carousel setCurrentSlide={setCurrentGiftSlide}>
                    {data.map((item, index) => {
                      return (
                        <React.Fragment key={item?._id}>
                          <div className="membership-row gift-memb">
                            <GiftRecipientInfo
                              info={item}
                              membershipType={membershipType}
                              getUserList={getUserList}
                              currentGiftSlide={currentGiftSlide}
                              setCurrentGiftSlide={setCurrentGiftSlide}
                            />
                            <MembershipInfo
                              info={userData}
                              membershipType={membershipType}
                              getUserList={getUserList}
                              editMemberships={editMemberships[index]}
                              currentGiftSlide={currentGiftSlide}
                              setCurrentGiftSlide={setCurrentGiftSlide}
                              setEditMemberships={({ value, key }) => {
                                const data = [...editMemberships];
                                let newObject = data[index];
                                newObject = {
                                  ...newObject,
                                  [key]: value,
                                };
                                data[index] = newObject;
                                setEditMemberships(data);
                              }}
                            />
                          </div>
                          <div className="btns-lower">
                            <button
                              className="btn-white fs16"
                              disabled={userData?.giftedTo?.isFreeTrial}
                              onClick={() => {
                                const data = [...editMemberships];
                                let newObject = editMemberships[currentSlide];
                                newObject = {
                                  ...newObject,
                                  isOpen: true,
                                };
                                data[currentSlide] = newObject;
                                setEditMemberships(data);
                              }}
                            >
                              EDIT
                            </button>
                            <button
                              className="btn fs16"
                              disabled={loading}
                              onClick={
                                handleSendPersonalizedEmailToGiftRecipient
                              }
                            >
                              SEND PERSONALIZED EMAIL
                            </button>
                            <button
                              className="btn fs16"
                              disabled={
                                loading || !membershipType
                                  ? !(
                                      (
                                        userData?.giftedTo[currentGiftSlide]
                                          ?.status === "LIVE"
                                      )
                                      //    ||
                                      // userData?.giftedTo[currentGiftSlide]
                                      //   ?.status === "LAPSED"
                                    )
                                  : !(
                                      (userData?.status === "LIVE")
                                      // ||
                                      // userData?.status === "LAPSED"
                                    )
                              }
                              onClick={() => {
                                const userId = !membershipType
                                  ? userData?.giftedTo[currentGiftSlide]?.user
                                      ?._id
                                  : userData?._id;
                                handleCanceMembership(userId);
                              }}
                            >
                              CANCEL MEMBERSHIP
                            </button>
                          </div>
                          {userData?.transactions &&
                            userData?.transactions.length > 0 && (
                              <PaymentHistory
                                paymentData={userData?.transactions || []}
                                userData={userData || {}}
                              />
                            )}
                          <div className="recp-info lower">
                            <GiftSenderInfo
                              info={userData}
                              membershipType={membershipType}
                            />
                          </div>
                          <div className="btns-lower">
                            <button
                              className="btn fs16"
                              disabled={loading}
                              onClick={() => {
                                setSendPersonalizedToRecipient(true);
                                handleSendPersonalizedEmailToGiftRecipient();
                              }}
                            >
                              SEND PERSONALIZED EMAIL
                            </button>
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </Carousel>
                </div>
              );
            } else {
              return (
                <div className="" key={index}>
                  <React.Fragment className="full">
                    <div className="membership-row gift-memb">
                      <GiftRecipientInfo
                        info={userData}
                        membershipType={membershipType}
                        getUserList={getUserList}
                        currentGiftSlide={currentGiftSlide}
                        setCurrentGiftSlide={setCurrentGiftSlide}
                      />
                      <MembershipInfo
                        info={userData}
                        membershipType={membershipType}
                        getUserList={getUserList}
                        editMemberships={editMemberships[index]}
                        currentGiftSlide={currentGiftSlide}
                        setCurrentGiftSlide={setCurrentGiftSlide}
                        setEditMemberships={({ value, key }) => {
                          const data = [...editMemberships];
                          let newObject = data[index];
                          newObject = {
                            ...newObject,
                            [key]: value,
                          };
                          data[index] = newObject;
                          setEditMemberships(data);
                        }}
                      />
                    </div>
                    <div className="btns-lower">
                      <button
                        className="btn-white fs16"
                        disabled={
                          !membershipType
                            ? userData?.giftedTo[currentGiftSlide]?.isFreeTrial
                            : usersData[currentSlide]?.isFreeTrial
                        }
                        onClick={() => {
                          const data = [...editMemberships];
                          let newObject = editMemberships[currentSlide];
                          newObject = {
                            ...newObject,
                            isOpen: true,
                          };
                          data[currentSlide] = newObject;
                          setEditMemberships(data);
                        }}
                      >
                        EDIT
                      </button>
                      <button
                        className="btn fs16"
                        disabled={loading}
                        onClick={handleSendPersonalizedEmail}
                      >
                        SEND PERSONALIZED EMAIL
                      </button>
                      <button
                        className="btn fs16"
                        disabled={
                          loading || !membershipType
                            ? !(
                                (
                                  userData?.giftedTo[currentGiftSlide]
                                    ?.status === "LIVE"
                                )
                                //   ||
                                // userData?.giftedTo[currentGiftSlide]?.status ===
                                //   "LAPSED"
                              )
                            : !(
                                (userData?.status === "LIVE")
                                //  ||
                                // userData?.status === "LAPSED"
                              )
                        }
                        onClick={() => {
                          const userId = !membershipType
                            ? userData?.giftedTo[currentGiftSlide]?._id
                            : userData?._id;
                          handleCanceMembership(userId);
                        }}
                      >
                        CANCEL MEMBERSHIP
                      </button>
                    </div>
                    {userData?.transactions &&
                      userData?.transactions.length > 0 && (
                        <PaymentHistory
                          paymentData={userData?.transactions || []}
                          userData={userData || {}}
                        />
                      )}
                    <div className="recp-info lower">
                      <GiftSenderInfo
                        info={userData}
                        membershipType={membershipType}
                      />
                    </div>
                    <div className="btns-lower">
                      <button
                        className="btn fs16"
                        disabled={loading}
                        onClick={() => {
                          setSendPersonalizedToRecipient(true);
                          handleSendPersonalizedEmailToGiftRecipient();
                        }}
                      >
                        SEND PERSONALIZED EMAIL
                      </button>
                    </div>
                  </React.Fragment>
                </div>
              );
            }
          })
        ) : (
          <Carousel
            setCurrentSlide={setCurrentSlide}
            fetchData={getUserList}
            pageNo={pageNo}
            setPage={setPage}
          >
            {usersData &&
              usersData?.length > 0 &&
              usersData?.map((userData, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="membership-row">
                      <GiftRecipientInfo
                        info={userData}
                        membershipType={membershipType}
                        getUserList={getUserList}
                        currentGiftSlide={currentGiftSlide}
                        setCurrentGiftSlide={setCurrentGiftSlide}
                      />

                      <MembershipInfo
                        info={userData}
                        membershipType={membershipType}
                        getUserList={getUserList}
                        editMemberships={editMemberships[index]}
                        currentGiftSlide={currentGiftSlide}
                        setCurrentGiftSlide={setCurrentGiftSlide}
                        setEditMemberships={({ value, key }) => {
                          const data = [...editMemberships];
                          let newObject = data[index];
                          newObject = {
                            ...newObject,
                            [key]: value,
                          };
                          data[index] = newObject;
                          setEditMemberships(data);
                        }}
                      />
                    </div>
                    <div className="btns-lower">
                      <button
                        className="btn-white fs16"
                        disabled={usersData[currentSlide]?.isFreeTrial}
                        onClick={() => {
                          const data = [...editMemberships];
                          let newObject = editMemberships[currentSlide];
                          newObject = {
                            ...newObject,
                            isOpen: true,
                          };
                          data[currentSlide] = newObject;
                          setEditMemberships(data);
                        }}
                      >
                        EDIT
                      </button>
                      <button
                        className="btn fs16"
                        disabled={loading}
                        onClick={() => {
                          setSendPersonalizedToRecipient(true);
                          handleSendPersonalizedEmail();
                        }}
                      >
                        SEND PERSONALIZED EMAIL
                      </button>
                      <button
                        className="btn fs16"
                        disabled={
                          loading ||
                          !(
                            (userData?.membershipStatus === "LIVE")
                            // ||
                            // userData?.membershipStatus === "LAPSED"
                          )
                        }
                        onClick={() => {
                          const userId = userData?._id;
                          handleCanceMembership(userId);
                        }}
                      >
                        CANCEL MEMBERSHIP
                      </button>
                    </div>
                    {currentSlide === index &&
                      userData?.transactions &&
                      userData?.transactions.length > 0 && (
                        <PaymentHistory
                          paymentData={userData?.transactions || []}
                          userData={userData || {}}
                        />
                      )}
                    <div className="recp-info lower">
                      <GiftSenderInfo
                        info={userData}
                        membershipType={membershipType}
                      />
                    </div>
                    <div className="btns-lower">
                      <button
                        className="btn fs16"
                        disabled={loading}
                        onClick={handleSendPersonalizedEmail}
                      >
                        SEND PERSONALIZED EMAIL
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
          </Carousel>
        )
      ) : null}

      {usersData &&
        usersData.length > 0 &&
        usersData[currentSlide] &&
        sendPersonalizedEmail &&
        sendPersonalizedEmail.length > 0 &&
        sendPersonalizedEmail[currentSlide] && (
          <SEND_PERSONALIZED_EMAIL_MODAL
            open={sendPersonalizedEmail?.[currentSlide]?.open}
            setOpen={() => {
              setSendPersonalizedToRecipient(false);
              handleSendPersonalizedEmail();
            }}
            email={
              isSendPersonalizedToRecipient
                ? usersData[currentSlide].email
                : usersData[currentSlide]?.giftedBy?.email
            }
          />
        )}

      {usersData &&
        usersData.length === 1 &&
        sendPersonalizedEmailToGift &&
        sendPersonalizedEmailToGift.length > 0 &&
        sendPersonalizedEmailToGift[currentGiftSlide] && (
          <SEND_PERSONALIZED_EMAIL_MODAL
            open={sendPersonalizedEmailToGift[currentGiftSlide].giftOpen}
            setOpen={() => {
              setSendPersonalizedToRecipient(false);
              handleSendPersonalizedEmailToGiftRecipient();
            }}
            email={
              isSendPersonalizedToRecipient
                ? usersData[0]?.email
                : usersData[0]?.giftedTo[currentGiftSlide]?.user?.email
            }
          />
        )}
    </div>
  );
};

export default GiftMemberShip;
