import React from "react";
import { Switch } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, Input } from "@material-ui/core";

const CustomCell = ({
  category = {},
  name,
  editableId,
  setEditableId,
  errors,
  setErrors,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",  
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));
  const { _id: id } = category || {};

  const handleInputChange = (value, type) => {
    let data = editableId && editableId[id] ? editableId[id] : {};
    if (type === "categoryName") {
      data = { ...data, name: value };
    }
    setEditableId({ ...editableId, [id]: data });
    setTimeout(() => {
      document.getElementById(`category-${id}`).focus();
    }, 100);
  };

  const handleBlurInput = () => {
    let err = { ...errors };
    if (
      editableId &&
      editableId[id] &&
      Object.keys(editableId[id]).length > 0 &&
      (editableId[id].name === "" || editableId[id].name.length > 16)
    ) {
      err = { ...err, [id]: id };
      setErrors(err);
    }
  };

  const handleFocusInput = () => {
    let err = { ...errors };
    if (errors && Object.keys(errors).length > 0 && errors[id]) {
      delete err[id];
      setErrors(err);
    }
    setTimeout(() => {
      document.getElementById(`category-${id}`).focus();
    }, 100);
  };

  const handleStatusChange = (value, id, type) => {
    let data = editableId && editableId[id] ? editableId[id] : {};
    if (type === "status") {
      data = { ...data, status: value };
    }
    setEditableId({ ...editableId, [id]: data });
  };

  const classes = useStyles();
  const cardCount = category && category?.cardCount ? category.cardCount : 0;
  const categoryNameData =
    cardCount > 0 ? `${category.name} ${" "}(${cardCount})` : category.name;

  return (
    <TableCell align="left" className={classes.tableCell} key={id}>
      {name === "categoryName" ? (
        <div className="editable">
          {editableId &&
          Object.keys(editableId).length > 0 &&
          editableId[id] ? (
            <>
              <Input
                id={`category-${id}`}
                defaultValue={editableId[id].name}
                name={name}
                onBlur={handleBlurInput}
                onFocus={handleFocusInput}
                onChange={(e) =>
                  handleInputChange(e.target.value, "categoryName")
                }
                className={
                  errors &&
                  Object.keys(errors).length > 0 &&
                  errors[category._id]
                    ? `${classes.input} error`
                    : `${classes.input}`
                }
              />
              <br />
              {errors &&
                Object.keys(errors).length > 0 &&
                errors[category._id] && (
                  <span className="error-msg">
                    {editableId[category._id].name === ""
                      ? VALIDATION_MESSAGES.categoryInput
                      : VALIDATION_MESSAGES.maxLengthCategory}
                  </span>
                )}
            </>
          ) : (
            categoryNameData
          )}
        </div>
      ) : name === "status" ? (
        <>
          {" "}
          {editableId &&
          Object.keys(editableId).length > 0 &&
          editableId[id] ? (
            <Switch
              key={Math.random()}
              defaultChecked={editableId[id].status}
              onChange={(e) =>
                handleStatusChange(e.target.checked, id, "status")
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          ) : (
            <Switch
              key={Math.random()}
              checked={category.status}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        </>
      ) : null}
    </TableCell>
  );
};

export default CustomCell;
