import React, { useEffect, useState } from "react";
import DataTable from "../Components/DataTable";
import Select, { components } from "react-select";
import {
  ByTypeOptionsSales,
  MembershipOptionsSales,
  SourceOptionsSales,
  StatusOptionsForSales,
} from "src/constant/global";
import moment from "moment-timezone";
import customAxios from "src/utils/axios";
import { errorAlert } from "src/utils/alerts";
import { addPreffixforSelect } from "src/helpers";

const ByGeography = ({ setLoading, startDate = null, endDate = null }) => {
  const [geographyData, setGeographyData] = useState();
  const [membershipTYpe, setMembershipTYpe] = useState("");
  const [byType, setType] = useState("");
  const [bySource, setBySource] = useState("");
  const [byStatus, setStatus] = useState("");
  const [byCoupon, setCoupon] = useState("");
  const [couponList, setCouponList] = useState([]);

  useEffect(() => {
    getCouponList();
  }, []);

  const getCouponList = () => {
    setLoading(true);
    customAxios
      .get(`admin/list-coupons`)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          const data = response?.data?.data;
          data.unshift({ name: "ALL" });
          setCouponList(data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  useEffect(() => {
    getByGeography();
  }, [
    startDate,
    endDate,
    byCoupon,
    byStatus,
    bySource,
    byType,
    membershipTYpe,
  ]);

  const getByGeography = () => {
    setLoading(true);
    let url = `admin/sales-management-global/paid-memberships-by-geography?`;
    if (startDate !== null && endDate !== null) {
      url =
        url +
        `startDate=${moment(startDate).endOf("day").toDate()}&endDate=${moment(
          endDate
        )
          .endOf("day")
          .toDate()}&`;
    }
    if (membershipTYpe !== "") {
      url = url + `membershipTYpe=${membershipTYpe}&`;
    }

    if (byType && byType !== "ALL") {
      url = url + `byType=${byType}&`;
    }

    if (bySource !== "") {
      url = url + `bySource=${bySource}&`;
    }

    if (byStatus !== "") {
      url = url + `byStatus=${byStatus}&`;
    }

    if (byCoupon) {
      url = url + `byCoupon=${byCoupon}&`;
    }

    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          setGeographyData(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <div className="main-div h-auto mt24 no-pad-select">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">By Geography</h6>
        </div>
        <div className="select-option ml-auto">
          <Select
            options={MembershipOptionsSales}
            placeholder="MT"
            onChange={(data) => {
              setMembershipTYpe(data.value);
            }}
            defaultValue={MembershipOptionsSales[0]}
            components={addPreffixforSelect({
              prefix: "MT: ",
              components,
            })}
          />
          <Select
            options={ByTypeOptionsSales}
            defaultValue={ByTypeOptionsSales[0]}
            placeholder="Status  "
            onChange={(data) => {
              setType(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "Status: ",
              components,
            })}
          />
          <Select
            placeholder="Source"
            options={SourceOptionsSales}
            defaultValue={SourceOptionsSales[0]}
            onChange={(data) => {
              setBySource(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "Source: ",
              components,
            })}
          />
          {couponList && couponList.length > 0 && (
            <Select
              placeholder="Coupon "
              options={couponList}
              defaultValue={couponList[0]}
              components={addPreffixforSelect({
                prefix: "Coupon: ",
                components,
              })}
              getOptionLabel={(data) => {
                return data.name.toUpperCase();
              }}
              getOptionValue={(data) => {
                return data._id;
              }}
              onChange={(data) => {
                setCoupon(data._id);
              }}
            />
          )}
          <Select
            placeholder="NR"
            options={StatusOptionsForSales}
            defaultValue={StatusOptionsForSales[0]}
            onChange={(data) => {
              setStatus(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "NR: ",
              components,
            })}
          />
        </div>
      </div>
      <DataTable data={geographyData} title="Area" />
    </div>
  );
};

export default ByGeography;
