import React, { useEffect, useState } from "react";
import DirectMembership from "./directMembership";
import OptInMembership from "./optInMembership";
import ReferralPartnerLink from "./referral-partner-link/"
import User from "./specific-user-search/"
import BulkUpdate from "./bulk-update"
import { useDispatch } from "react-redux";
import Loader from "src/components/common/loaders";
import {
  freeMembershipReferralPartnerList,
  freeMembershipDatabaseType,
} from "src/redux/services/freeMembership.service";
import InactiveUsers from "src/views/pages/free-memberships/InactiveUsers"
import axios from "src/utils/axios";
import appConfig from "src/appConfig";

const index = () => {
  const [count, setCount] = useState("")
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false)
  const [inactiveUserCount, setInactiveUserCount] = useState(0)
  const [totalActiveSignedInUsers, setTotalActiveSignedInUsers] = useState(0)
  const [totalOptInUsers, setTotalOptInUsers] = useState(0)
  const isLoading = (data) => {
    setLoading(data)
  }
  useEffect(() => {
    try {
      dispatch(freeMembershipReferralPartnerList({}));
      dispatch(freeMembershipDatabaseType({}));
      const getCount = async () => {
        const response = await axios.get(
          `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/users-count`,
        );
        if (response?.data?.statusCode === 200) {
          setCount(response?.data?.data?.liveMembershipsCount)
          setInactiveUserCount(response?.data?.data?.inactiveUsersCount)
          setTotalActiveSignedInUsers(response?.data?.data?.totalActiveSignedInUsers)
          setTotalOptInUsers(response?.data?.data?.totalOptInUsers)

        }
      }
      getCount();
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
    }
  }, []);
  return (
    <>
      {loading && <Loader />}
      {/* <div className="main-div h-auto mb24">
        <div className="title ">
          <h6 className="">Total Live Free Memberships : {(count) || "0"}</h6>
        </div>
      </div> */}
      <div className="dashboard-cards mb0 pt0" >
        <div className="card-details mb0">
          <h6 className="">Total Live Free Memberships : {(count) || "0"}</h6>
        </div>
        <div className="card-details mb0">
          <h6 className="">Total Active SignedIn Users : {(totalActiveSignedInUsers) || "0"}</h6>
        </div>
        <div className="card-details mb0" >
          <h6 className="">Total OptIn Users : {(totalOptInUsers) || "0"}</h6>
        </div>
      </div>
      <div className="main-div h-auto mb24 report-analytics">
        <InactiveUsers loading={isLoading} count={inactiveUserCount} />
      </div>
      <DirectMembership emailLoader={isLoading} />
      <OptInMembership />
      <User />
      <BulkUpdate />
      <ReferralPartnerLink />
    </>
  );
};

export default index;
