import React, { useEffect, useState } from "react";
import Filters from "./Filters";
import axios from "src/utils/axios";
import PersonalMembership from "./PersonalMembership";
import { getReferralPartnerLink } from "src/redux/services/freeMembership.service";
import { useDispatch, useSelector } from "react-redux";
import { successAlert, errorAlert } from "src/utils/alerts";
import { errorMessage, isAddAccess } from "src/helpers";
import Loader from "src/components/common/loaders";
import {
  MAX_PAGE_LIMIT_IN_SLIDER,
  NOT_AUTHORIZED_TEXT,
} from "src/constant/global";
import customAxios from "src/utils/axios";
import { useNavigate } from "react-router-dom";

const InitialFilterData = {
  name: "",
  email: "",
  transactionId: "",
  customerId: "",
};

// Users Management

const ReferralPartnerLink = () => {
  const [filterData, setFilterData] = useState(InitialFilterData);
  const [isGiftMemberShip, setGiftMemberShip] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [membershipType, setMembershipType] = useState("");
  const [users, setUsers] = useState([]);
  const [pageNo, setPage] = useState(1);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { loading: apiLoader } = useSelector((state) => {
    return state.freeMembership;
  });

  const handleChange = (event) => {
    const key = event.target.name;
    const value =
      key !== "customerId"
        ? event.target.value.toLowerCase()
        : event.target.value.toUpperCase();

    setFilterData({
      ...filterData,
      [key]: value.trim(),
    });
  };

  const handleSearch = ({ resetData, pageNo = 1 }) => {
    const isEmpty = Object.values(filterData).every(
      (x) => x === null || x === ""
    );
    if (!isEmpty) {
      const data = {
        ...filterData,
        membershipType: membershipType,
        pageNo: pageNo || 1,
        limit: MAX_PAGE_LIMIT_IN_SLIDER,
      };
      if (resetData) {
        setCurrentSlide(0);
      }
      dispatch(
        getReferralPartnerLink({
          ...data,
          callback: (response) => {
            const userDataArray = resetData
              ? response?.data?.data
              : users.concat(response?.data?.data);
            setUsers(userDataArray);
          },
        })
      );
    }
  };

  useEffect(() => {
    if (isGiftMemberShip) {
      const isEmpty = Object.values(filterData).every(
        (x) => x === null || x === ""
      );
      const noGifted =
        users && users[currentSlide] && users[currentSlide]?.giftedTo?.length;

      if (isEmpty) {
        setUsers([]);
        setMembershipType("GIFTED");
      } else if (!isEmpty && noGifted === undefined) {
        setMembershipType("GIFTED");
      }
    }
  }, [isGiftMemberShip, filterData]);

  useEffect(() => {
    if (membershipType !== "GIFTED" && Object.values(filterData).length > 0) {
      handleSearch({});
    }
  }, [membershipType]);

  const handleCanceMembership = (userId) => {
    customAxios
      .post(`admin/memberships/cancel-membership`, { userId })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          handleSearch({});
          navigate("/cancellation-and-refund");
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };
  const handleLoading = (val) => {
    setLoading(val)
  }
  return (
    <>
      <div className="main-div user-manage">
        <div className="heading">
          <div className="title">
            <h6 className="">Referral Partner Link</h6>
          </div>
        </div>
        <Filters
          onChange={handleChange}
          onSearch={() => {
            handleSearch({ resetData: true });
          }}
          loading={handleLoading}
        />
        {apiLoader && <Loader />}
        {loading && <Loader />}
        {users && users?.length > 0 ? (
          <>
            <PersonalMembership
              membershipType={membershipType}
              usersData={users}
              setCurrentSlide={setCurrentSlide}
              currentSlide={currentSlide}
              getUserList={handleSearch}
              pageNo={pageNo}
              setPage={setPage}
              handleCanceMembership={handleCanceMembership}
            />
          </>
        ) : ""}
        {users && users?.length === 0 && (
          <div className="no-content">
            <h6>No Content</h6>
            <p>No data available right now.</p>
            <p>
              Search by Email and Name.
            </p>
          </div>

          // <div className="no-content">
          //   <h6>No Content</h6>
          //   <p>No data available right now.</p>
          // </div>
        )}
      </div>
    </>
  );
};

export default React.memo(ReferralPartnerLink);
