import { createSlice } from "@reduxjs/toolkit";
import {
  freeMembershipReferralPartnerList,
  freeMembershipDatabaseType,
  getBulkUpdateLotList,
  getReferralPartnerLink
} from "src/redux/services/freeMembership.service"

const initialState = {
  loading: false,
  dataBaseTypes: [],
  referralPartnerList: [],
  bulkLotList:[],
  referralPartnerLink:[]
};

const freeMembershipSlice = createSlice({
  name: "freeMembership",
  initialState,
  reducers: {},
  extraReducers: {
    [freeMembershipReferralPartnerList.pending]: (state) => {
      state.loading = true;
    },
    [freeMembershipReferralPartnerList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.referralPartnerList = payload.data.data;
      state.message = payload.message;
    },
    [freeMembershipReferralPartnerList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },

    [freeMembershipDatabaseType.pending]: (state) => {
      state.loading = true;
    },
    [freeMembershipDatabaseType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.dataBaseTypes = payload.data.data;
      state.message = payload.message;
    },
    [freeMembershipDatabaseType.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },
    [getBulkUpdateLotList.pending]: (state) => {
      state.loading = true;
    },
    [getBulkUpdateLotList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.bulkLotList = payload.data.data;
      state.message = payload.message;
    },
    [getBulkUpdateLotList.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },
    [getReferralPartnerLink.pending]: (state) => {
      state.loading = true;
    },
    [getReferralPartnerLink.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.referralPartnerLink = payload.data.data;
      state.message = payload.message;
    },
    [getReferralPartnerLink.rejected]: (state, { payload }) => {
      state.loading = false;
      state.message = payload;
    },
  },
});

export default freeMembershipSlice.reducer;
