import React, { memo, useState, useEffect } from "react";
import CustomCarousel from "src/components/common/Carousel";
import RecipientData from "./RecipientData";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";

const GiftRecipientInfo = ({
  info,
  membershipType,
  getUserList,
  currentGiftSlide,
  setCurrentGiftSlide,
}) => {
  const [editExpiry, setEditExpiry] = useState([]);
  const [sendPersonalizedEmail, setSendPersonalizedEmail] = useState([]);

  const data = !membershipType ? info?.giftedTo : null;
  const infoData = data && data.length === 1 ? data[0] : info;

  const handleSendPersonalizedEmail = () => {
    const popupData = [...sendPersonalizedEmail];
    let modalToOpen = popupData[currentGiftSlide];
    modalToOpen = {
      open: !modalToOpen.open,
    };
    popupData[currentGiftSlide] = modalToOpen;
    setSendPersonalizedEmail(popupData);
  };

  useEffect(() => {
    if (data && data.length > 1) {
      const newData = data.map((el) => {
        return {
          isOpen: false,
          isOpenSaved: false,
        };
      });
      const emailPopupData = data.map((el) => {
        return {
          open: false,
        };
      });
      setEditExpiry(newData);
      setSendPersonalizedEmail(emailPopupData);
    } else if (infoData) {
      const newData = {
        isOpen: false,
        isOpenSaved: false,
      };
      const emailPopupData = {
        open: false,
      };

      setEditExpiry(newData);
      setSendPersonalizedEmail([emailPopupData]);
    }
  }, [data, infoData]);

  // if (data && data.length > 1) {
  //   return (
  //     <>
  //       {/* <CustomCarousel
  //         setCurrentSlide={setCurrentGiftSlide}
  //         // isShowPersonalized={true}
  //         handleSendPersonalizedEmail={handleSendPersonalizedEmail}
  //       > */}
  //       {data.map((item, index) => {
  //         return (
  //           <React.Fragment key={item?._id}>
  //             <RecipientData
  //               getUserList={getUserList}
  //               count={index + 1}
  //               info={{
  //                 ...item.user,
  //                 planEndedAt: item.planEndedAt,
  //                 id: item._id,
  //                 membershipType: item?.membershipPlan?.subType,
  //               }}
  //               editExpiry={editExpiry[index]}
  //               setEditExpiry={({ value, key }) => {
  //                 const data = [...editExpiry];
  //                 let newObject = data[index];
  //                 newObject = {
  //                   ...newObject,
  //                   [key]: value,
  //                 };
  //                 data[index] = newObject;
  //                 setEditExpiry(data);
  //               }}
  //             />
  //           </React.Fragment>
  //         );
  //       })}
  //       {/* </CustomCarousel> */}
  //       {sendPersonalizedEmail && sendPersonalizedEmail.length > 0 && (
  //         <SEND_PERSONALIZED_EMAIL_MODAL
  //           open={sendPersonalizedEmail[currentGiftSlide].open}
  //           setOpen={handleSendPersonalizedEmail}
  //           email={data[currentGiftSlide]?.user?.email}
  //         />
  //       )}
  //     </>
  //   );
  // }

  return (
    <>
      <RecipientData
        getUserList={getUserList}
        count={0}
        info={
          infoData?.user
            ? {
                ...infoData.user,
                planEndedAt: infoData.planEndedAt,
                id: infoData._id,
                membershipType: infoData?.membershipPlan?.subType,
                membershipStatus: infoData?.status,
              }
            : {
                ...infoData,
                planEndedAt: infoData?.membership?.planEndedAt,
                id: infoData?.membership?._id,
                membershipType: infoData?.membership?.membershipPlan?.subType,
                membershipStatus: infoData?.membershipStatus,
              }
        }
        editExpiry={editExpiry}
        setEditExpiry={({ value, key }) => {
          let newObject = { ...editExpiry };
          newObject = {
            ...newObject,
            [key]: value,
          };

          setEditExpiry(newObject);
        }}
      />
      {/* <div className="btns-lower">
        <button className="btn fs16" onClick={handleSendPersonalizedEmail}>
          SEND PERSONALIZED EMAIL
        </button>
      </div>
      {sendPersonalizedEmail && sendPersonalizedEmail.length > 0 && (
        <SEND_PERSONALIZED_EMAIL_MODAL
          open={sendPersonalizedEmail[0].open}
          setOpen={handleSendPersonalizedEmail}
          email={infoData?.email || infoData?.user?.email}
        />
      )} */}
    </>
  );
};

export default memo(GiftRecipientInfo);
