import React from "react";
import SieveLogo from "src/assets/images/Sieve-logo-black.svg";
import { logout } from "src/redux/services/auth.service";
import { getSessionToken } from "src/helpers";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Menu, MenuItem } from "@mui/material";

const AppHeader = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  // const clearSessionStorage = () => {
  //   sessionStorage.clear();
  // };

  // React.useEffect(() => {
  //   window.addEventListener("beforeunload", clearSessionStorage);
  //   return () => {
  //     window.removeEventListener("beforeunload", clearSessionStorage);
  //   };
  // }, []);

  const token = getSessionToken("authToken");

  const [profileEl, setprofileEl] = React.useState(null);
  const profileOpen = Boolean(profileEl);

  const handleProfileClose = () => {
    setprofileEl(null);
  };

  const handleProfileIconClick = (event) => {
    setprofileEl(event.currentTarget);
  };

  const handleLogout = () => {
    dispatch(
      logout({
        token,
        callback: (res) => {
          if (res) {
            if (res?.statusCode === 200) {
              navigate("/");
            }
          }
        },
      })
    );
  };

  return (
    <header className="main-header">
      <nav className="navbar">
        <a className="navbar-brand" href="#">
          <div id="hambtn" className="hamBtn">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={SieveLogo} alt="SieveLogo" />
        </a>

        <div className="ml-auto">
          <button className="blank-btn" onClick={handleProfileIconClick}>
            <i className="user-icon"></i>
          </button>
        </div>
        <Menu
          anchorEl={profileEl}
          open={profileOpen}
          className="nav-menu"
          onClose={handleProfileClose}
          transition="true"
        >
          {" "}
          <div>
            <MenuItem
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </MenuItem>
          </div>
        </Menu>
      </nav>
    </header>
  );
};

export default AppHeader;
