import React, { useState } from "react";
import EditorPickContent from "./component/editorPickContent";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { TextField } from "@mui/material";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import PreviewOnMobileAndDesktop from "../PreviewOnMobileAndDesktop";

const initialValues = {
  mobileImage: "",
  attribute: "",
  redirectUrl: "",
  ctaText: "",
};

const AddEditorPick = ({ setAddNew }) => {
  const [editorContent, setEditorContent] = useState([initialValues]);
  const [publishDate, setPublishDate] = useState();
  // const [publishTime, setPublishTime] = useState();
  const [preview, setPreview] = useState("");

  const handleAddContent = () => {
    const newArray = [...editorContent];
    newArray.push(initialValues);
    setEditorContent(newArray);
    setPreview("");
  };

  const handleRemove = (index) => {
    const newArray = [...editorContent];
    newArray.splice(index, 1);
    setEditorContent(newArray);
  };

  const handleAllValues = (values, index) => {
    const newArray = [...editorContent];
    newArray[index] = values;
    setEditorContent(newArray);
  };

  const handleSave = () => {
    const date = moment(publishDate).endOf("day");
    const dateAndTime = moment(`${date}`).toISOString();
    const dataToSend = {
      contentPieces: editorContent,
      publishDate: dateAndTime,
    };

    customAxios
      .post("admin/editor-pick/add", { ...dataToSend })
      .then((res) => {
        if (res?.data?.statusCode) {
          // successAlert(res?.data?.message || "");
          setAddNew(false);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const getDisabledStatus = () => {
    if (editorContent && editorContent.length > 0) {
      return checkEmptyValue(editorContent) || !publishDate;
    }

    return true;
  };

  const checkEmptyValue = (dataArray) => {
    const value = dataArray.find((data) => {
      return Object.values(data)
        .map((el) => el.trim())
        .includes("");
    });
    return value ? true : false;
  };

  return (
    <>
      <div className="main-div h-auto">
        <div className="heading bb">
          <div className="title">
            <h6>Create Editor’s Pick</h6>
          </div>
          <div className="btn-grp ml-auto">
            <button
              className="btn"
              type="button"
              onClick={() => {
                setAddNew(false);
              }}
            >
              BACK
            </button>
          </div>
        </div>
        <div>
          <div className="emailer-divs">
            {editorContent &&
              editorContent.length > 0 &&
              editorContent.map((content, index, self) => {
                return (
                  <EditorPickContent
                    content={content}
                    onRemove={handleRemove}
                    index={index}
                    handleAllValues={handleAllValues}
                    count={self.length}
                    status="DRAFT"
                  />
                );
              })}
          </div>
          <div className="text-center mb24" type="button">
            <div>
              <button className="btn fs16" onClick={handleAddContent}>
                ADD CONTENT PIECE
              </button>
            </div>
          </div>
          <div className="publish-date mb24">
            <p className="m12 text-black fw500">Schedule Publishing</p>

            <div className="row">
              <div className="col-lg-6 col-md-4 col-5">
                <div className="form-in">
                  <label>Publishing Date of Editor’s Pick</label>
                  <DatePicker
                    // disabled={isContentSaved}
                    minDate={moment().add(1, "day").startOf("day").toDate()}
                    className="date-picker"
                    value={publishDate}
                    selected={publishDate}
                    onChange={(date) => {
                      setPublishDate(date);
                    }}
                    placeholderText="dd:mm:yyyy"
                  />
                </div>
              </div>

              {/* <div className="col-lg-6 col-md-4 col-5">
                <div className="form-in">
                  <label>Time of Publishing*</label>
                  <TextField
                    id="time"
                    type="time"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300,
                    }}
                    sx={{ width: 150 }}
                    onChange={(e) => {
                      setPublishTime(e.target.value);
                    }}
                  />
                </div>
              </div> */}
            </div>
          </div>

          <div className="text-center mb24" type="button">
            <div>
              <button
                className="btn fs16"
                onClick={handleSave}
                disabled={getDisabledStatus()}
              >
                SAVE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="main-div content-btns mt24">
        <div className="btns-lower">
          <div>
            <button
              className="btn-white long"
              onClick={() => setPreview("desktop")}
              disabled={getDisabledStatus()}
            >
              Preview editor’s Pick on Desktop
            </button>
          </div>
          <div>
            <button
              className="btn-white long"
              onClick={() => setPreview("mobile")}
              disabled={getDisabledStatus()}
            >
              Preview editor’s Pick on Mobile
            </button>
          </div>
        </div>
      </div>
      {preview !== "" && (
        <>
          <PreviewOnMobileAndDesktop
            editorContent={editorContent}
            preview={preview}
            publishDate={publishDate}
            setPreview={setPreview}
          />
          <div className="main-div content-btns mt24">
            <div className="btns-lower">
              <div>
                <button
                  className="btn-white lg"
                  onClick={handleSave}
                  disabled={getDisabledStatus()}
                >
                  SAVE
                </button>
              </div>

              <div className="ml-auto">
                <button className="btn lg long" disabled>
                  PUBLISH
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddEditorPick;
