import React, { useEffect, useState } from "react";
import Button from "src/components/common/Button";
import Loader from "src/components/common/loaders";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { isAddAccess } from "src/helpers";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import AddCoupon from "./add-coupon";
import CouponsList from "./coupons-list";
import EditCoupon from "./edit-coupon";

const CouponManagement = () => {
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [couponToEdit, setCouponToEdit] = useState();
  const [totalCount, setTotalCount] = useState(1);
  const [curPage, setCurrPage] = useState(1);

  useEffect(() => {
    fetchCoupons();
  }, [addNew, edit, curPage]);

  const fetchCoupons = () => {
    setLoading(true);
    customAxios
      .get(`admin/list-coupons?limit=${MAX_PAGE_LIMIT}&page=${curPage}`)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          const totalPagesCount =
            res.data.data.total && res.data.data.total > 0
              ? Math.ceil(res.data.data.total / MAX_PAGE_LIMIT)
              : 1;
          setTotalCount(totalPagesCount);
          setCoupons(res?.data?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      {!addNew && !edit && (
        <div className="main-div">
          <div className="heading">
            <div className="title">
              <h6 className="fw600">Coupon Management</h6>
            </div>

            <div className="ml-div">
              <div>
                <Button
                  text=" ADD NEW"
                  onHandleClick={() => {
                    const isAdd = isAddAccess("COUPON_MANAGEMENT");
                    if (isAdd) {
                      setAddNew(true);
                    } else {
                      errorAlert(NOT_AUTHORIZED_TEXT);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          {loading && <Loader />}

          <CouponsList
            list={coupons}
            fetchCoupons={fetchCoupons}
            curPage={curPage}
            setCurrPage={setCurrPage}
            totalCount={totalCount}
            setCouponToEdit={(item) => {
              setCouponToEdit(item);
              setEdit(true);
            }}
          />
        </div>
      )}
      {addNew && <AddCoupon setAddNew={setAddNew} />}

      {edit && <EditCoupon setEdit={setEdit} couponToEdit={couponToEdit} />}
    </>
  );
};

export default CouponManagement;
