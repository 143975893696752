import React from "react";
import { firstLetterUpper } from "src/helpers";
import DataTable from "../components/DataTable";

const NewPaidMemberships = ({ data, getFreeData }) => {
  const tableData =
    data &&
    Object.keys(data).length > 0 &&
    Object.keys(data).map((el) => {
      const field = data[el];
      const key = el.replace("overall", "overall_");
      return {
        title: firstLetterUpper(key),
        ...field,
      };
    });
  return (
    <div className="main-div h-auto mt18">
      <div className="heading">
        <div className="title">
          <h6 className="fw600">New Paid Memberships</h6>
        </div>
      </div>
      <DataTable tableData={tableData} getFreeData={getFreeData} />
    </div>
  );
};

export default NewPaidMemberships;
