import React, { useState } from "react";
import { ButtonGroup } from "../../referral-partner-management/pending-requests";
import CancellationDetail from "./components/CancellationDetail";
import PendingRequestForm from "./components/PendingRequestForm";
import Carousel from "react-multi-carousel";
import { responsive } from "src/components/common/Carousel";

const PendingRequests = ({
  pendingRequestList,
  getPendingCancellationRequest,
  getInReviewList,
  pageNo,
  setPage,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  return (
    <>
      <div className="refund-div">
        <div className="referral-form">
          {pendingRequestList && pendingRequestList.length === 1
            ? pendingRequestList.map((request) => {
                return (
                  <>
                    <PendingRequestForm request={request} key={request?._id} />
                    <CancellationDetail
                      request={
                        pendingRequestList && pendingRequestList[currentSlide]
                      }
                      getPendingCancellationRequest={
                        getPendingCancellationRequest
                      }
                      getInReviewList={getInReviewList}
                    />
                  </>
                );
              })
            : pendingRequestList &&
              pendingRequestList.length > 0 && (
                <Carousel
                  responsive={responsive}
                  renderButtonGroupOutside={true}
                  arrows={false}
                  customButtonGroup={
                    <ButtonGroup
                      setCurrentSlide={setCurrentSlide}
                      pageNo={pageNo}
                      setPage={setPage}
                      fetchData={getPendingCancellationRequest}
                    />
                  }
                >
                  {pendingRequestList.map((request, index) => {
                    return (
                      <>
                        {currentSlide === index && (
                          <>
                            <PendingRequestForm
                              request={request}
                              count={index + 1}
                              key={request?._id}
                            />
                            <CancellationDetail
                              request={
                                pendingRequestList &&
                                pendingRequestList[currentSlide]
                              }
                              getPendingCancellationRequest={
                                getPendingCancellationRequest
                              }
                              getInReviewList={getInReviewList}
                            />
                          </>
                        )}
                      </>
                    );
                  })}
                </Carousel>
              )}
        </div>
        {pendingRequestList && pendingRequestList.length === 0 && (
          <div className="no-content">
            <h6>No Pending Requests</h6>
          </div>
        )}
      </div>
    </>
  );
};

export default PendingRequests;
