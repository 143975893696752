import React from "react";
import { errorAlert, successAlert } from "src/utils/alerts";
const CopyText = ({ text }) => {
    return (
        <button
            className="null-btn"
            aria-label="edit"
            onClick={() => {
                console.log(text)
                navigator.clipboard.writeText(text)
                    .then(() => {
                        successAlert("Link successfully copied to clipboard.");
                    })
                    .catch((error) => {
                        errorAlert("Unable to copy link to clipboard")
                    });
            }}
        >
            <i className="copy-icon"></i>
        </button>

    );
};

export default CopyText;
