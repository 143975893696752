import React, { useState,useEffect } from "react";
import MultiStepFormModal from "./multistepModal";
import Filters from "./filter";
import axios from "src/utils/axios";
import appConfig from "src/appConfig";
import Loader from "src/components/common/loaders";
import { errorAlert, successAlert } from "src/utils/alerts";
import Tooltip from '@mui/material/Tooltip';
import FreeMembership_SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/FreeMembership-Send-Personalized-Email-Modal";
const directMembership = ({ emailLoader }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState("")
  const [filterData, setFilterData] = useState("")
  const [filterValue, setFilterValues] = useState("")
  const [loading, setLoading] = useState(false);
  const [isSendEmail, setIsSendEmail] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const setIsOpen = (data) => {
    setIsSendEmail(data)
  }
  useEffect(() => {
    emailLoader(emailLoading)
  }, [emailLoading])

  const isLoading = (data) => {
    setEmailLoading(data)
  }
  const handleSearch = (res) => {
    setFilterData(res?.data)
  };
  const handleReset = (data) => {
    if (data.reset === true) {
      setFilterData("")
    }
  }
  const handleFilterValue = (obj) => {
    setFilterValues(obj)
  }
  const handleLoading = (val) => {
    setLoading(val)
  }

  const handleExportData = async () => {
    try {
      setLoading(true)
      let searchData = filterValue
      Object.keys(searchData).forEach((key) => {
        if (searchData[key] === "ALL") {
          delete searchData[key];
        }
      });
      const res = await axios.post(
        `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/direct/export-data`,
        searchData
      );
      if (res && res?.data?.statusCode === 200) {
        setLoading(false)
        const message =
          res && res.data
            ? res.data.message
            : "successfull";
        successAlert(message);
      }
    }
    catch (error) {
      setLoading(false)
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      return rejectWithValue(message);
    }

  }

  return (
    <>
      {isModalOpen && (
        <MultiStepFormModal
          open={isModalOpen}
          modalType={modalType}
          onClose={() => setIsModalOpen(false)}
          modalHeader="Direct Free Membership"
        />
      )}
      <div className="main-div user-manage">
        {loading && <Loader />}
        <div className="heading">
          <div className="title">
            <h6 className="">Direct Free Membership</h6>
          </div>
          <div className="ml-auto">
            <button
              className="btn"
              autoFocus
              onClick={() => {
                setIsModalOpen(true)
                setModalType("DIRECT")
              }}
            >
              {" "}
              ADD BULK UPLOAD
            </button>
          </div>
        </div>
        <Filters
          onSearch={handleSearch}
          onReset={() => {
            handleReset({ reset: true })
          }}
          filterValues={handleFilterValue}
          filterType="DIRECT"
          loading={handleLoading}
        />
        {
          filterData ?
            <>
              <div className="dashboard-cards">
                <div className="card-details">
                  <p>Total Free Members Created
                  </p>
                  <h5>{(filterData.totalFreeMembershipsCreated) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Total Signed In</p>
                  <h5>{(filterData.totalSignedInUsers) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Total Converted</p>
                  <h5>{(filterData.totalconvertedUsers) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Conversion %</p>
                  <h5>{(filterData.conversionPercentage) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Active Users</p>
                  <h5>{(filterData.activeUsersLastTenDays) || "0"}</h5>
                </div>

                <div className="card-details">
                  <p>Inactive Users</p>
                  <h5>{(filterData.inactiveUsersLastTenDays) || "0"}</h5>
                </div>
              </div>
              <div style={{ "text-align": "center", "margin-top": "2rem" }}>
                <button
                  className="btn"
                  autoFocus
                  onClick={handleExportData}

                >
                  Export Data
                </button>
                <Tooltip placement="top" arrow title="Send personalized email to user not signed-in">
                  <button
                    className="btn"
                    autoFocus
                    onClick={() => {
                      setIsOpen(true)
                    }}
                    style={{ "margin-left": "2rem" }}
                  >
                    Send Personalized Email
                  </button>
                </Tooltip>
              </div>
            </> :
            <div className="no-content">
              <h6>No Content</h6>
              <p>No data available right now.</p>
            </div>
        }
      </div>
      <FreeMembership_SEND_PERSONALIZED_EMAIL_MODAL
        emailLoading={isLoading}
        open={isSendEmail}
        setOpen={setIsOpen}
        emailType="USER-NOT-SIGNEDIN"
        filterValue={filterValue}
      />
    </>
  );
};

export default directMembership;
