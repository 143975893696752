import React from "react";
import DatePicker from "react-datepicker";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  amount: Yup.string().trim().required("Please Enter Amount."),
  date: Yup.string().trim().required("Please Select Start Date."),
});

const UpdateCommissionForm = ({ item, setOpen, getCommissionPayments }) => {
  const onSave = (values) => {
    let dataToSent = {
      salesAgentId: values?.id,
      amountPaid: values?.amount,
      date: values?.date,
    };
    customAxios
      .post(`admin/sales-agent/update-paid-commission`, { ...dataToSent })
      .then((res) => {
        if (res?.data?.statusCode) {
          // successAlert(res?.data?.message || "");
          getCommissionPayments();
          setOpen(false);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const partnerData = `${item?.name} #${item?.partnerId}`;
  return (
    <>
      <Formik
        initialValues={{
          partnerDetails: partnerData,
          amount: "",
          date: new Date(),
          id: item?._id,
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={onSave}
      >
        {(props) => {
          const { values, setFieldValue } = props;
          const getDisabledStatus = () => {
            return !!(values && Object.values(values).some((el) => el === ""));
          };

          return (
            <>
              <Form>
                <h6 className="m16">UPDATE COMMISSION</h6>
                <div className="row col-form">
                  <div className="col-md-6">
                    <div className="form-in">
                      <label>Partner Details</label>
                      <div className="f-in">
                        <Field type="text" name="partnerDetails" disabled />
                      </div>
                      <ErrorMessage
                        name="partnerDetails"
                        component="span"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-in">
                      <label>Amount ($)</label>
                      <div className="f-in">
                        <Field type="text" name="amount" />
                      </div>
                      <ErrorMessage
                        name="amount"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-in date-input">
                      <label>Date</label>
                      <div className="f-in date-input">
                        <DatePicker
                          className="date-picker"
                          placeholderText="mm/dd/yyyy"
                          value={values && new Date(values?.date)}
                          selected={values && new Date(values?.date)}
                          onChange={(date) => {
                            setFieldValue("date", date);
                          }}
                        />
                      </div>
                      <ErrorMessage
                        name="date"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>
                </div>
                <div className="btns-lower">
                  <button className="btn" disabled={getDisabledStatus()}>
                    {/* {isEdit ? "SAVE CHANGES" :  */}
                    UPDATE
                    {/* } */}
                  </button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default UpdateCommissionForm;
