import React from "react";
import customAxios from "src/utils/axios";
import { errorAlert } from "src/utils/alerts";
import { isEditAccess } from "src/helpers";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";

const InactiveUsers = ({
  data,
  setLoading,
  getReferralComplementaryAndInActiveUserInfo,
}) => {
  const sendReportOnMail = () => {
    getReferralComplementaryAndInActiveUserInfo("INACTIVE_USERS");
  };

  const sendEmailToInactiveUsers = () => {
    setLoading(true);
    customAxios
      .post(`admin/reports-analytics/send-email-inactive-incomplete-users`, {
        type: 2,
      })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      <div className="heading mb0">
        <div className="title">
          <h6 className="fw600">
            Inactive Users: <b>{data}</b>
          </h6>
        </div>
        <div className="ml-auto btn-grp">
          <button
            className="btn-white"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendReportOnMail();
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            {/* <i className="msg-icon"></i> */} Send Report To Admin
          </button>
          <button
            className="btn"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendEmailToInactiveUsers();
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            Send Email
          </button>
        </div>
      </div>
    </>
  );
};

export default InactiveUsers;
