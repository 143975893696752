import { toast } from "react-toastify";

export const successAlert = (message) => {
  return toast(
    <div className="tn-box active">
      <figure>
        <i className="check-green-icon"></i>
      </figure>
      <div className="text">
        <h6>SUCCESS!</h6>
        <p>{message}</p>
      </div>
    </div>,
    {
      toastId: message,
      type: "success",
      position: "top-right",
      autoClose: 5000,
      // hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: false,
      hideProgressBar: true,
    }
  );
};

export const errorAlert = (message) => {
  return toast(
    <div className="tn-box active red">
      <figure>
        <i className="cross-red-icon"></i>
      </figure>
      <div className="text">
        <h6>ERROR!</h6>
        <p>{message}</p>
      </div>
    </div>,
    {
      toastId: message,
      type: "error",
      position: "top-right",
      autoClose: 5000,
      // hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: false,
      hideProgressBar: true,
    }
  );
};

export const underDevAlert = () => {
  errorAlert("Under Development");
};
