import React, { useEffect, useState } from "react";
import Loader from "src/components/common/loaders";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import EditorPickContent from "../add-editor-pick/component/editorPickContent";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import { TextField } from "@mui/material";
import PreviewOnMobileAndDesktop from "../PreviewOnMobileAndDesktop";
import { isEditAccess } from "src/helpers";

const initialValues = {
  mobileImage: "",
  attribute: "",
  redirectUrl: "",
  ctaText: "",
};

const EditEditorPick = ({ setEdit, editorPickId }) => {
  const [editorContent, setEditorContent] = useState([]);
  const [publishDate, setPublishDate] = useState();
  // const [publishTime, setPublishTime] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState("");
  const [isEditable, setEditable] = useState(false);
  const [deletedContentPieces, setDeletedContentPieces] = useState([]);

  useEffect(() => {
    if (editorPickId) {
      setLoading(true);
      let url = `admin/editor-pick/list?limit=${MAX_PAGE_LIMIT}&page=${1}&editorPickId=${editorPickId}`;

      customAxios
        .get(url)
        .then((res) => {
          if (res?.data?.statusCode) {
            setLoading(false);
            setEditorContent(res.data.data.cards);
            const publishDate = res.data.data.publishDate;
            const date = new Date(publishDate);
            const time = moment.utc(publishDate).format("HH:mm");
            setPublishDate(date);
            // setPublishTime(time);
            setStatus(res.data.data.status);
          }
        })
        .catch((error) => {
          setLoading(false);
          const message =
            error.response && error.response.data
              ? error.response.data.message
              : "Something Went Wrong";
          errorAlert(message);
        });
    }
  }, []);

  const handleAddContent = () => {
    const newArray = [...editorContent];
    newArray.push(initialValues);
    setEditorContent(newArray);
    setPreview("");
  };

  const handleRemove = (index) => {
    const newArray = [...editorContent];
    const checkForDeleteCard = newArray[index];
    if (checkForDeleteCard && checkForDeleteCard._id) {
      setDeletedContentPieces([
        ...deletedContentPieces,
        checkForDeleteCard._id,
      ]);
    }
    newArray.splice(index, 1);
    setEditorContent(newArray);
  };

  const handleAllValues = (values, index) => {
    const newArray = [...editorContent];
    newArray[index] = values;
    setEditorContent(newArray);
  };

  const getDisabledStatus = () => {
    if (editorContent && editorContent.length > 0) {
      return checkEmptyValue(editorContent) || !publishDate;
    }

    return true;
  };

  const checkEmptyValue = (dataArray) => {
    const value = dataArray.find((data) => {
      return Object.values(data)
        .map((el) => el.trim())
        .includes("");
    });
    return value ? true : false;
  };

  const handleSave = () => {
    const date = moment(publishDate).endOf("day");
    const dateAndTime = moment(`${date}`).toISOString();
    const dataToSend = {
      editorPickId,
      updatedContentPieces: editorContent
        .filter((el) => {
          return el._id;
        })
        .map((el) => {
          const newObject = {
            ...el,
            cardId: el._id,
          };
          delete newObject._id;
          delete newObject.editorPickId;
          return newObject;
        }),
      newContentPieces: editorContent.filter((el) => {
        return !el._id;
      }),
      publishDate: dateAndTime,
      deletedContentPieces,
    };
    customAxios
      .put("admin/editor-pick/update", { ...dataToSend })
      .then((res) => {
        if (res?.data?.statusCode) {
          // successAlert(res?.data?.message || "");
          setEdit(false);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const handlePublish = () => {
    customAxios
      .put("admin/editor-pick/publish", { editorPickId })
      .then((res) => {
        if (res?.data?.statusCode) {
          // successAlert(res?.data?.message || "");
          setEdit(false);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      {loading && <Loader />}

      <div className="main-div h-auto mt24">
        <div className="heading bb">
          <div className="title">
            <h6>Edit Editor’s Pick</h6>
          </div>
          <div className="btn-grp ml-auto">
            <button
              className="btn"
              type="button"
              onClick={() => {
                setEdit(false);
              }}
            >
              BACK
            </button>
            {status === "DRAFT" && (
              <button
                className="btn"
                type="button"
                onClick={() => {
                  const isEdit = isEditAccess("EDITOR_PICK_EMAILER");
                  if (isEdit) {
                    setEditable(true);
                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
              >
                EDIT
              </button>
            )}
          </div>
        </div>
        {!loading && (
          <>
            <div>
              <div className="emailer-divs">
                {editorContent &&
                  editorContent.length > 0 &&
                  editorContent.map((content, index, self) => {
                    return (
                      <EditorPickContent
                        initialValues={content}
                        content={content}
                        onRemove={handleRemove}
                        index={index}
                        handleAllValues={handleAllValues}
                        count={self.length}
                        isDisabled={status !== "DRAFT" || !isEditable}
                        status={status}
                      />
                    );
                  })}
              </div>
              {status === "DRAFT" && (
                <div className="text-center mb24" type="button">
                  <div>
                    <button
                      className="btn fs16"
                      onClick={handleAddContent}
                      disabled={!isEditable}
                    >
                      ADD CONTENT PIECE
                    </button>
                  </div>
                </div>
              )}
              <div className="publish-date mb24">
                <p className="m12 text-black fw500">Schedule Publishing</p>

                <div className="row">
                  <div className="col-lg-6 col-md-4 col-5">
                    <div className="form-in">
                      <label>Publishing Date of Editor’s Pick</label>
                      <DatePicker
                        disabled={status !== "DRAFT" || !isEditable}
                        minDate={moment().toDate()}
                        className="date-picker"
                        value={publishDate}
                        selected={publishDate}
                        onChange={(date) => {
                          setPublishDate(date);
                        }}
                        placeholderText="dd:mm:yyyy"
                      />
                    </div>
                  </div>

                  {/* <div className="col-lg-6 col-md-4 col-5">
                    <div className="form-in">
                      <label>Time of Publishing*</label>
                      <TextField
                        disabled={status !== "DRAFT" || !isEditable}
                        id="time"
                        type="time"
                        placeholder="hh:mm:ss"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{
                          step: 300,
                        }}
                        value={publishTime}
                        sx={{ width: 150 }}
                        onChange={(e) => {
                          setPublishTime(e.target.value);
                        }}
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              {status === "DRAFT" && (
                <div className="text-center mb24" type="button">
                  <div>
                    <button
                      className="btn fs16"
                      onClick={handleSave}
                      disabled={getDisabledStatus() || !isEditable}
                    >
                      SAVE
                    </button>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {
        <div className="main-div content-btns mt24">
          <div className="btns-lower">
            <div>
              <button
                className="btn-white long"
                onClick={() => setPreview("desktop")}
                disabled={getDisabledStatus()}
              >
                Preview editor’s Pick on Desktop
              </button>
            </div>
            <div>
              <button
                className="btn-white long"
                onClick={() => setPreview("mobile")}
                disabled={getDisabledStatus()}
              >
                Preview editor’s Pick on Mobile
              </button>
            </div>
          </div>
        </div>
      }
      {preview !== "" && (
        <>
          <PreviewOnMobileAndDesktop
            editorContent={editorContent}
            preview={preview}
            publishDate={publishDate}
            setPreview={setPreview}
          />
          {status === "DRAFT" && (
            <div className="main-div content-btns mt24">
              <div className="btns-lower">
                <div>
                  <button
                    className="btn-white lg fs16"
                    onClick={handleSave}
                    disabled={!isEditable || getDisabledStatus()}
                  >
                    SAVE
                  </button>
                </div>

                <div className="ml-auto">
                  <button
                    className="btn lg long fs16"
                    onClick={handlePublish}
                    disabled={status === "PUBLISHED"}
                  >
                    PUBLISH
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditEditorPick;
