import React from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { TableHead } from "@mui/material";
import { isEditAccess } from "src/helpers";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { errorAlert } from "src/utils/alerts";

const ReportPaidTable = ({
  heading,
  data,
  allPaid,
  reportStatus,
  reportForAdmin,
  reportForRP,
}) => {
  const tableData = data && Object.keys(data).length > 0 && [data];

  return (
    <>
      <div className="grey-table full table-sec center">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h, index) => {
                  return (
                    <TableCell align="left" key={`key_${index + 1}`}>
                      {h}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                return (
                  <>
                    <TableRow key={`key_${index + 1}`}>
                      <TableCell>{item?.live}</TableCell>
                      <TableCell>{item?.expired}</TableCell>
                      <TableCell>{item?.lapsed}</TableCell>
                      <TableCell>{item?.cancelled}</TableCell>
                      <TableCell>{item?.lapsedCancelled}</TableCell>
                    </TableRow>

                    <TableRow key={`key_${index + 1}`}>
                      <TableCell>
                        <b>Send Report To </b>
                      </TableCell>
                      <TableCell align="center">
                        {allPaid && (
                          <div className="mb8">
                            <a
                              className="link"
                              onClick={() => {
                                const isEdit =
                                  isEditAccess("REPORTS_ANALYTICS");
                                if (isEdit) {
                                  reportStatus("EXPIRED", true);
                                } else {
                                  errorAlert(NOT_AUTHORIZED_TEXT);
                                }
                              }}
                            >
                              <u>Send Personalised Email</u>
                            </a>
                          </div>
                        )}
                        <button
                          className="btn mb8"
                          onClick={() => {
                            const isEdit = isEditAccess("REPORTS_ANALYTICS");
                            if (isEdit) {
                              reportForAdmin("EXPIRED");
                            } else {
                              errorAlert(NOT_AUTHORIZED_TEXT);
                            }
                          }}
                        >
                          Admin
                        </button>{" "}
                        <br />{" "}
                        {!allPaid && (
                          <button
                            className="btn-white"
                            onClick={() => {
                              const isEdit = isEditAccess("REPORTS_ANALYTICS");
                              if (isEdit) {
                                reportForRP("EXPIRED");
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                          >
                            RP
                          </button>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {allPaid && (
                          <div className="mb8">
                            <a
                              className="link"
                              onClick={() => {
                                const isEdit =
                                  isEditAccess("REPORTS_ANALYTICS");
                                if (isEdit) {
                                  reportStatus("LAPSED", true);
                                } else {
                                  errorAlert(NOT_AUTHORIZED_TEXT);
                                }
                              }}
                            >
                              <u>Send Personalised Email</u>
                            </a>
                          </div>
                        )}
                        <button
                          className="btn mb8"
                          onClick={() => {
                            const isEdit = isEditAccess("REPORTS_ANALYTICS");
                            if (isEdit) {
                              reportForAdmin("LAPSED");
                            } else {
                              errorAlert(NOT_AUTHORIZED_TEXT);
                            }
                          }}
                        >
                          Admin
                        </button>{" "}
                        <br />{" "}
                        {!allPaid && (
                          <button
                            className="btn-white"
                            onClick={() => {
                              const isEdit = isEditAccess("REPORTS_ANALYTICS");
                              if (isEdit) {
                                reportForRP("LAPSED");
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                          >
                            RP
                          </button>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {allPaid && (
                          <div className="mb8">
                            <a
                              className="link"
                              onClick={() => {
                                const isEdit =
                                  isEditAccess("REPORTS_ANALYTICS");
                                if (isEdit) {
                                  reportStatus("CANCELLED", true);
                                } else {
                                  errorAlert(NOT_AUTHORIZED_TEXT);
                                }
                              }}
                            >
                              <u>Send Personalised Email</u>
                            </a>
                          </div>
                        )}
                        <button
                          className="btn mb8"
                          onClick={() => {
                            const isEdit = isEditAccess("REPORTS_ANALYTICS");
                            if (isEdit) {
                              reportForAdmin("CANCELLED");
                            } else {
                              errorAlert(NOT_AUTHORIZED_TEXT);
                            }
                          }}
                        >
                          Admin
                        </button>{" "}
                        <br />{" "}
                        {!allPaid && (
                          <button
                            className="btn-white"
                            onClick={() => {
                              const isEdit = isEditAccess("REPORTS_ANALYTICS");
                              if (isEdit) {
                                reportForRP("CANCELLED");
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                          >
                            RP
                          </button>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {allPaid && (
                          <div className="mb8">
                            <a
                              className="link"
                              onClick={() => {
                                const isEdit =
                                  isEditAccess("REPORTS_ANALYTICS");
                                if (isEdit) {
                                  reportStatus("LAPSED_CANCELLED", true);
                                } else {
                                  errorAlert(NOT_AUTHORIZED_TEXT);
                                }
                              }}
                            >
                              <u>Send Personalised Email</u>
                            </a>
                          </div>
                        )}
                        <button
                          className="btn mb8"
                          onClick={() => {
                            const isEdit = isEditAccess("REPORTS_ANALYTICS");
                            if (isEdit) {
                              reportForAdmin("LAPSED_CANCELLED");
                            } else {
                              errorAlert(NOT_AUTHORIZED_TEXT);
                            }
                          }}
                        >
                          Admin
                        </button>{" "}
                        <br />{" "}
                        {!allPaid && (
                          <button
                            className="btn-white"
                            onClick={() => {
                              const isEdit = isEditAccess("REPORTS_ANALYTICS");
                              if (isEdit) {
                                reportForRP("LAPSED_CANCELLED");
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                          >
                            RP
                          </button>
                        )}
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default ReportPaidTable;
