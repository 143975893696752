import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import { MAX_PAGE_LIMIT, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { dateFormat, firstLetterUpper, isDeleteAccess } from "src/helpers";
import Action from "src/components/common/Action";
import ModalComp from "src/components/common/modal";
import { DELETE_EDITOR_PICK_TEXT } from "src/constant/modals";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import moment from "moment-timezone";

const heading = [
  "Sr. No.",
  "Publishing Date",
  "No. of Content Pieces",
  "Status",
  "Action",
];

const EditorPickList = ({
  list,
  curPage,
  totalCount,
  setCurrPage,
  fetchEditorPageList,
  setEditorPickToEdit,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");

  const onDelete = (editorPickId) => {
    customAxios
      .delete(`admin/editor-pick/${editorPickId}`)
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          // successAlert(res?.data?.message || "");
          setOpen(false);
          setIdToDelete("");
          fetchEditorPageList();
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
        setIdToDelete("");
      });
  };

  return (
    <>
      <div className="full table-sec">
        <Table>
          <TableHead>
            <TableRow>
              {heading &&
                heading.length > 0 &&
                heading.map((h) => {
                  return <TableCell align="left">{h}</TableCell>;
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {list &&
              list.length > 0 &&
              list.map((item, index) => {
                return (
                  <TableRow key={item?._id}>
                    <TableCell>
                      {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                    </TableCell>
                    <TableCell>
                      <p className="fs16 text-blue fw600">
                        {item?.publishDate
                          ? moment(item?.publishDate)
                              .utc()
                              .format("DD MMM, YYYY")
                          : "-"}
                      </p>
                    </TableCell>
                    <TableCell>{item?.cardsCount || "-"}</TableCell>
                    <TableCell>
                      <span
                        className={`chip-label ${
                          item.status === "DRAFT" ? "blue" : ""
                        }`}
                      >
                        {item.status ? firstLetterUpper(item.status) : "-"}
                      </span>
                    </TableCell>
                    <TableCell>
                      <Action
                        id={item._id}
                        isEdit={false}
                        isDelete={item.status === "DRAFT"}
                        setDeleteId={() => {
                          const isDelete = isDeleteAccess(
                            "EDITOR_PICK_EMAILER"
                          );
                          if (isDelete) {
                            setIdToDelete(item._id);
                            setOpen(true);
                          } else {
                            errorAlert(NOT_AUTHORIZED_TEXT);
                          }
                        }}
                        handleViewClick={() => {
                          setEditorPickToEdit(item._id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
      <ModalComp
        open={isOpen}
        title="Confirmation"
        setOpen={() => setOpen(false)}
        content={DELETE_EDITOR_PICK_TEXT}
        onClickYes={() => {
          onDelete(idToDelete);
        }}
      />
      {totalCount > 1 && (
        <Pagination
          count={totalCount}
          variant="outlined"
          shape="rounded"
          defaultPage={curPage}
          onChange={(type, page) => {
            setCurrPage(page);
          }}
        />
      )}
      {list && list.length === 0 && (
        <div className="no-content">
          <h6>No Content Added Yet</h6>
          <p>
            You haven’t added anything yet. Click on Add new button to add new
            content for editior’s pick management.
          </p>
        </div>
      )}
    </>
  );
};

export default EditorPickList;
