import React from "react";
import DataTable from "../components/DataTable";

const NetRevenue = ({ data, getFreeData }) => {
  let tableData = [];

  if (data && Object.keys(data).length > 0) {
    tableData.push({
      ...data,
      title: "TOTAL REVENUE",
    });
  }

  return (
    <div className="main-div h-auto mt18" >
      <div className="heading">
        <div className="title">
          <h6 className="fw600">Net Revenue</h6>
        </div>
      </div>
      <DataTable tableData={tableData} getFreeData={getFreeData} />
    </div>
  );
};

export default NetRevenue;
