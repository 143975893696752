import React, { useEffect, useState } from "react";
import Loader from "src/components/common/loaders";
import FreeMembership_SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/FreeMembership-Send-Personalized-Email-Modal";
const InactiveUsers = ({ loading,count }) => {
  const [isEdit, setIsEdit] = useState(false)
  const [emailLoading, setEmailLoading] = useState(false)
  const setIsOpen = (data) => {
    setIsEdit(data)
  }
  useEffect(() => {
    loading(emailLoading)
  }, [emailLoading])

  const isLoading = (data) => {
    setEmailLoading(data)
  }

  return (
    <>
      <div className="heading mb0">
        <div className="title">
          <h6 className="fw600">
            Inactive Users : {(count) || "0"}
          </h6>
        </div>
        <div className="ml-auto btn-grp">
          <button
            className="btn"
            onClick={() => {
              setIsOpen(true)
            }}
          >
            Send Personalized Email
          </button>
        </div>
      </div>
      <FreeMembership_SEND_PERSONALIZED_EMAIL_MODAL
        emailLoading={isLoading}
        open={isEdit}
        setOpen={setIsOpen}
        emailType="INACTIVE-USER"
      />
    </>
  );
};

export default InactiveUsers;
