import React, { memo, useMemo } from "react";
import { firstLetterUpper } from "src/helpers";

const BasicDetails = ({ info }) => {
  const personalInfoData = useMemo(() => {
    return {
      status: info?.status,
      email: info?.email || "-",
      classification: "Personal Membership",
      customerId: info?.customerId || "-",
      firstName: info?.firstName || "-",
      lastName: info?.lastName || "-",
      contactNumber:
        info?.contactNumber && info?.countryCode
          ? `${info?.countryCode} - ${info?.contactNumber}`
          : "-",

      free_trial: {
        status: info?.isFreeTrial
          ? firstLetterUpper(info?.freeTrialStatus)
          : "Expired",

        start_date: info?.freeTrialStartDate || "",
        end_date: info?.freeTrialEndDate || "",
      },
      referral_source: info?.userSalesAgentDetails || {},
    };
  }, [info]);

  return (
    <div className="membership-div">
      {/* <h6 className="fs16 mb24">
        <span className="text-text">Details found for: </span>
        {personalInfoData?.email}
      </h6> */}
      <div className="d-flex">
        <h6 className="fs18 mb12">
          {`Referral Partner Information`}
        </h6>
        <div className="ml-auto">
        </div>
      </div>

      <div className="">
        <ul className="user-info">
          <li>
            <small>Email ID</small>
            <p>{personalInfoData?.email}</p>
          </li>
          <li>
            <small>First Name</small>
            <p>{personalInfoData?.firstName}</p>
          </li>

          <li>
            <small>Last Name</small>
            <p>{personalInfoData?.lastName}</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default memo(BasicDetails);
