import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addPreffixforSelect } from "src/helpers";
import CustomFilterDateRangePicker from "./filterDatepicker";
import { getTimeStamp } from "src/helpers";
import Select, { components } from "react-select";
import axios from "src/utils/axios";
import appConfig from "src/appConfig";
import moment from "moment-timezone";
const Filters = ({ onSearch, onReset,filterValues, filterType,loading}) => {
  let createStartDate = getTimeStamp(new Date())

  createStartDate = moment(createStartDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
  // createStartDate.startOf('day');
  // createStartDate = createStartDate.format('YYYY-MM-DDTHH:mm:ssZ');
  createStartDate = createStartDate.toISOString()

  const { dataBaseTypes, referralPartnerList } = useSelector((state) => {
    return state?.freeMembership;
  });
  const databaseTypeOptions = dataBaseTypes.map((type) => ({
    label: type.databaseName,
    value: type._id,
  }));
  databaseTypeOptions.push({ label: "All",
    value: "ALL"})
 

  const referralPartnerOptions = referralPartnerList.map((partner) => ({
    label: `${partner.firstName} ${partner.lastName}`,
    value: partner._id,
  }));
  const [databaseType, setDatabaseType] = useState(null);
  const [refrralPartner, setRefrralPartner] = useState(null)
  const [rangeStart, setRangeStart] = useState(null);
  const [rangeEnd, setRangeEnd] = useState(null);
  const [isButtonEnable, setIsButtonEnable] = useState(true);
  const [isDateReset,setIsDateReset] = useState(false)

  useEffect(() => {
    if (databaseType && refrralPartner && rangeStart && rangeEnd) {
      setIsButtonEnable(false)
    } else {
      setIsButtonEnable(true)
    }
  }, [databaseType, refrralPartner, rangeStart, rangeEnd])

  const handleReset = () => {
    setDatabaseType(null)
    setRefrralPartner("")
    setRangeEnd(null)
    setRangeStart(null)
    setIsDateReset(true)
    onReset()
  }
  const handleResetFunc =()=>{
    setIsDateReset(false)
  }
  const getDateFromTimestamp = (timestamp) =>
    timestamp ? new Date(timestamp) : null;

  const handleStartDateChange = (date) => {
    let timestamp = getTimeStamp(date);
    timestamp = moment(timestamp, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
    // timestamp.startOf('day');
    // timestamp = timestamp.format('YYYY-MM-DDTHH:mm:ssZ');
    timestamp = timestamp.toISOString()
    setRangeStart(timestamp);
  };
  const handleSearch = async () => {
    try {
      loading(true)
      let searchData = {
        saleAgentId: refrralPartner.value,
        databaseTypeId: databaseType.value,
        startDate: rangeStart,
        endDate: rangeEnd
      }
      Object.keys(searchData).forEach((key) => {
        if (searchData[key] === "ALL") {
          delete searchData[key];
        }
      });
      let filterUrl = "admin/free-membership/direct/filter-user-data"

      if(filterType === "OPTIN"){
        filterUrl = "admin/free-membership/optin/filter-user-data"
      }
      const res = await axios.post(
        `${appConfig.REACT_APP_API_BASE_URL}${filterUrl}`,
        searchData
      );
      if (res && res?.data?.statusCode === 200) {
        let filterObj = {
          saleAgentId:refrralPartner.value,
          databaseTypeId:databaseType.value,
          startDate:rangeStart,
          endDate:rangeEnd

        }
        loading(false)
        onSearch(res?.data)
        filterValues(filterObj)
      }

    }
    catch (err) {
      console.log(err)
      loading(false)
    }
  }

  const handleEndDateChange = (date) => {
    const timestamp = getTimeStamp(date);
    setRangeEnd(timestamp); // Update local state for date picker UI
  };
  const [error, setError] = useState(false);
  return (
    <div className="search-filter row">
      <div className="col-xl-2 col-md-3">
        <div className="form-in icon">
          <span className="icon-left">
            <i className="search-icon"></i>
          </span>

          <Select
            options={databaseTypeOptions}
            value={databaseType}
            defaultValue={databaseTypeOptions.find(
              (option) => option.value === databaseType?.value
            )}
            placeholder="Database type"
            onChange={(selectedOptions) => setDatabaseType(selectedOptions)}
          />
        </div>
      </div>
      <div className="col-xl-2 col-md-3">
        <div className="form-in icon">
          <span className="icon-left">
            <i className="search-icon"></i>
          </span>

          <Select
            options={referralPartnerOptions}
            value={refrralPartner}
            placeholder="Referral Partner"
            defaultValue={referralPartnerOptions.find(
              (option) => option.value === refrralPartner?.value
            )}
            onChange={(selectedOptions) => setRefrralPartner(selectedOptions)}
          />
        </div>
      </div>
      <div className="col-xl-4 col-md-5">
        <div className="form-in">
          {/* <span className="icon-left">
            <i className="search-icon"></i>
          </span> */}

          <CustomFilterDateRangePicker
            onStartDateChange={handleStartDateChange}
            onEndDateChange={handleEndDateChange}
            initialStartDate={getDateFromTimestamp(rangeStart)}
            initialEndDate={getDateFromTimestamp(rangeEnd)}
            reset={isDateReset}
            isFunc={handleResetFunc}
          />
        </div>
      </div>
      <div className="col-xl-2  flex-0">
        <button
          className="btn fs16"
          onClick={handleSearch}
          disabled={isButtonEnable}
        >
          Search
        </button>
      </div>
      <div className="col-xl-2  flex-0 text-xl-right">
        <button
          className="btn fs16"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default Filters;
