import React from "react";
import DataTable from "../Components/DataTable";
import Select, { components } from "react-select";
import { StatusOptionsForSales } from "src/constant/global";
import { addPreffixforSelect } from "src/helpers";

const BySource = ({ data, setByStatusWithSource }) => {
  return (
    <div className="main-div h-auto mt24 no-pad-select">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">By Source</h6>
        </div>
        <div className="select-option ml-auto">
          <Select
            placeholder="NR"
            options={StatusOptionsForSales}
            defaultValue={StatusOptionsForSales[0]}
            onChange={(data) => {
              setByStatusWithSource(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "NR: ",
              components,
            })}
          />
        </div>
      </div>
      <DataTable data={data} title="Source" />
    </div>
  );
};

export default BySource;
