import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import moment from "moment-timezone";

import Select, { components } from "react-select";
import {
  MembershipOptionsSales,
  SourceOptionsSales2,
  StatusOptionsForSales,
} from "src/constant/global";
import customAxios from "src/utils/axios";
import { errorAlert } from "src/utils/alerts";
import { addPreffixforSelect } from "src/helpers";

const heading = [
  {
    id: 1,
    title: "Coupon Name",
  },
  {
    id: 4,
    title: "Benefit",
  },
  {
    id: 2,
    title: "From Start",
  },
  {
    id: 3,
    title: "Count",
  },
];

const ByCoupon = ({ setLoading, startDate = null, endDate = null }) => {
  const [couponData, setCouponData] = useState();
  const [membershipTYpe, setMembershipTYpe] = useState("");
  const [bySource, setBySource] = useState("");
  const [byStatus, setStatus] = useState("");

  useEffect(() => {
    getByCoupon();
  }, [membershipTYpe, bySource, byStatus]);

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getByCoupon();
    }
  }, [startDate, endDate]);

  const getByCoupon = () => {
    setLoading(true);
    let url = `admin/sales-management-global/paid-memberships-by-coupon?`;
    if (startDate !== null && endDate !== null) {
      url =
        url +
        `startDate=${moment(startDate).endOf("day").toDate()}&endDate=${moment(
          endDate
        )
          .endOf("day")
          .toDate()}&`;
    }

    if (membershipTYpe !== "") {
      url = url + `membershipTYpe=${membershipTYpe}&`;
    }

    if (bySource !== "") {
      url = url + `bySource=${bySource}&`;
    }

    if (byStatus !== "") {
      url = url + `byStatus=${byStatus}&`;
    }

    customAxios
      .get(url)
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          setLoading(false);
          setCouponData(response?.data?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <div className="main-div h-auto mt24 no-pad-select">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">By Coupon</h6>
        </div>
        <div className="select-option ml-auto">
          <Select
            options={MembershipOptionsSales}
            defaultValue={MembershipOptionsSales[0]}
            placeholder="MT"
            onChange={(data) => {
              setMembershipTYpe(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "MT: ",
              components,
            })}
          />

          <Select
            placeholder="Source"
            options={SourceOptionsSales2}
            defaultValue={SourceOptionsSales2[0]}
            onChange={(data) => {
              setBySource(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "Source: ",
              components,
            })}
          />
          <Select
            placeholder="NR"
            options={StatusOptionsForSales}
            defaultValue={StatusOptionsForSales[0]}
            onChange={(data) => {
              setStatus(data.value);
            }}
            components={addPreffixforSelect({
              prefix: "NR: ",
              components,
            })}
          />
        </div>
      </div>
      <div className="light-table full table-sec center-right sales-table">
        <Table>
          <TableRow>
            {heading &&
              heading.length > 0 &&
              heading.map((h, index) => {
                return (
                  <TableCell align="left" key={`key_${index + 1}`}>
                    {h?.title}
                  </TableCell>
                );
              })}
          </TableRow>
          <TableBody>
            {couponData &&
              couponData.length > 0 &&
              couponData.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{item.name || "-"}</TableCell>
                    <TableCell>
                      {item.discount
                        ? `${item?.discount}%`
                        : `${item?.freeMonths} Months Free`}
                    </TableCell>
                    <TableCell>{item.fromStart || "0"}</TableCell>
                    <TableCell>{item.count || "0"}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        {couponData && couponData?.length === 0 && (
          <div className="no-content">
            <h6>No Coupon Data Yet</h6>
          </div>
        )}
      </div>
    </div>
  );
};

export default ByCoupon;
