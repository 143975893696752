import React from "react";
import { Switch } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell, Input } from "@material-ui/core";

const CustomCell = ({
  row = {},
  name,
  editTopicInput,
  setEditTopicInput,
  topicErrors,
  setTopicErrors,
}) => {
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));

  const handleTopicBlurInput = (id) => {
    let err = {
      ...topicErrors,
    };
    if (
      editTopicInput &&
      editTopicInput[id] &&
      Object.keys(editTopicInput[id]).length > 0 &&
      (editTopicInput[id].name === "" || editTopicInput[id].name.length > 25)
    ) {
      err = { ...err, [id]: id };
      setTopicErrors(err);
    }
  };

  const handleTopicFocusInput = (id) => {
    let err = {
      ...topicErrors,
    };
    if (topicErrors && Object.keys(topicErrors).length > 0 && topicErrors[id]) {
      delete err[id];
      setTopicErrors(err);
    }

    setTimeout(() => {
      document.getElementById(`topic-${id}`).focus();
    }, 100);
  };

  const handleTopicDataChange = (value, id, type) => {
    let newObj = { ...editTopicInput[id] };
    if (newObj && Object.keys(newObj).length > 0) {
      newObj.name = value;
    }
    const newData = { ...editTopicInput, [id]: newObj };
    setEditTopicInput(newData);
    setTimeout(() => {
      document.getElementById(`topic-${id}`).focus();
    }, 100);
  };

  const handleTopicStatusDataChange = (value, id, type) => {
    let newObj = { ...editTopicInput[id] };
    if (newObj && Object.keys(newObj).length > 0) {
      if (type === "status") {
        newObj.status = value;
      }
    }
    const newData = { ...editTopicInput, [id]: newObj };
    setEditTopicInput(newData);
  };

  const classes = useStyles();
  const topicNameData =
    row.cardCount && row.cardCount > 0
      ? `${row.name} ${" "}(${row.cardCount})`
      : row.name;

  return (
    <TableCell align="left" className={classes.tableCell} key={row._id}>
      {name === "name" ? (
        <div className="editable">
          {editTopicInput &&
          Object.keys(editTopicInput).length > 0 &&
          editTopicInput[row._id] ? (
            <>
              <Input
                id={`topic-${row._id}`}
                defaultValue={editTopicInput[row._id].name}
                name={name}
                onBlur={() => handleTopicBlurInput(row._id)}
                onFocus={() => handleTopicFocusInput(row._id)}
                onChange={(e) =>
                  handleTopicDataChange(e.target.value, row._id, "topicName")
                }
                className={classes.input}
              />
              <br />
              {topicErrors &&
                Object.keys(topicErrors).length > 0 &&
                topicErrors[row._id] && (
                  <span className="d-block error-msg mt10">
                    {editTopicInput[row._id].name === ""
                      ? VALIDATION_MESSAGES.topicInput
                      : VALIDATION_MESSAGES.maxLengthTopic}
                  </span>
                )}
            </>
          ) : (
            topicNameData
          )}
        </div>
      ) : name === "status" ? (
        <>
          {editTopicInput && editTopicInput[row._id] ? (
            <Switch
              key={Math.random()}
              defaultChecked={editTopicInput[row._id].status}
              onChange={(e) =>
                handleTopicStatusDataChange(e.target.checked, row._id, "status")
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          ) : (
            <Switch
              key={Math.random()}
              checked={row.status}
              inputProps={{ "aria-label": "controlled" }}
            />
          )}
        </>
      ) : null}
    </TableCell>
  );
};

export default CustomCell;
