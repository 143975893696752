import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import {
  GeographyOptions,
  MembershipOptions,
  NOT_AUTHORIZED_TEXT,
  PaymentPlanOptions,
  StatusOptions,
} from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal_Reports-Analytics";
import customAxios from "src/utils/axios";
import ReportPaidTable from "../components/ReportPaidTable";
import { addPreffixforSelect, getTimeStamp, isEditAccess } from "src/helpers";

const heading = ["Live", "Expired", "Lapsed", "Cancelled", "Lapsed Cancelled"];

const All_Paid_Memberships = ({
  setLoading,
  selectedDate,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [paidMemberships, setPaidMemberships] = useState();
  const [membershipType, setMembershipType] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState();

  const [planType, setPlanType] = useState();
  const [paymentType, setPaymentType] = useState();
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);

  useEffect(() => {
    getPidMemberships();
  }, [selectedDate]);

  useEffect(() => {
    if (membershipType || planType || paymentType || byGeography) {
      getPidMemberships();
    }
  }, [membershipType, planType, paymentType, byGeography]);

  const getPidMemberships = (
    isSendEmailReport = false,
    personalisedData = {},
    reportStatus
  ) => {
    setLoading(true);

    const url = `admin/reports-analytics/all-paid-memberships`;
    let dataToSent = {
      byGeography,
      isSendEmailReport,
    };

    if (selectedDate !== null) {
      dataToSent = {
        ...dataToSent,
        selectedDate: getTimeStamp(selectedDate).toISOString(),
      };
    }

    if (paymentType !== "ALL") {
      dataToSent = {
        ...dataToSent,
        paymentType,
      };
    }

    if (planType !== "ALL") {
      dataToSent = {
        ...dataToSent,
        planType,
      };
    }

    if (membershipType && membershipType !== "ALL") {
      dataToSent = {
        ...dataToSent,
        membershipType,
      };
    }
    if (personalisedData?.isPersonalized) {
      const { subject, content } = personalisedData;
      dataToSent = {
        ...dataToSent,
        sendPersonalisedEmailTo: status,
        mailSubject: subject,
        mailContent: content,
      };
    }
    if (reportStatus) {
      dataToSent = {
        ...dataToSent,
        reportFor: reportStatus,
      };
    }

    customAxios
      .post(url, { ...dataToSent })
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport && !personalisedData?.isPersonalized) {
            setPaidMemberships(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getPidMemberships(true, {});
  };

  const reportStatus = (status) => {
    setStatus(status);
    setIsOpen(true);
  };

  const sendReportPersonalized = (subject, content) => {
    if (subject && content) {
      const personalisedData = { isPersonalized: true, subject, content };
      getPidMemberships(false, personalisedData);
    }
  };

  const reportForAdmin = (status) => {
    getPidMemberships(true, {}, status);
  };

  return (
    <>
      <SEND_PERSONALIZED_EMAIL_MODAL
        open={isOpen}
        setOpen={setIsOpen}
        sendReportPersonalized={sendReportPersonalized}
      />
      <div className="in-div h-auto">
        <div className="heading">
          <div className="title">
            <h6 className="fs16">All Paid Memberships</h6>
          </div>
          <div className="ml-auto">
            <button
              className="btn"
              onClick={() => {
                const isEdit = isEditAccess("REPORTS_ANALYTICS");
                if (isEdit) {
                  sendReportOnMail();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              {/* <i className="msg-icon"></i> */} Send Report To Admin
            </button>
          </div>
        </div>
        <div className="select-option lg">
          <Select
            options={GeographyOptions}
            placeholder="Geo"
            components={addPreffixforSelect({
              prefix: "Geo: ",
              components,
            })}
            defaultValue={GeographyOptions[0]}
            onChange={(data) => {
              setGeography(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                allPaidMembershipsByGeography: data.value,
              });
            }}
          />
          <Select
            options={MembershipOptions}
            placeholder="MT"
            components={addPreffixforSelect({
              prefix: "MT: ",
              components,
            })}
            defaultValue={MembershipOptions[0]}
            onChange={(data) => {
              setMembershipType(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                allPaidMembershipsMembershipType: data.value,
              });
            }}
          />
          <Select
            options={PaymentPlanOptions}
            placeholder="PP"
            components={addPreffixforSelect({
              prefix: "PP: ",
              components,
            })}
            defaultValue={PaymentPlanOptions[0]}
            onChange={(data) => {
              setPlanType(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                allPaidMembershipsPlanType: data.value,
              });
            }}
          />
          <Select
            options={StatusOptions}
            placeholder="NR"
            components={addPreffixforSelect({
              prefix: "NR: ",
              components,
            })}
            defaultValue={StatusOptions[0]}
            onChange={(data) => {
              setPaymentType(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                allPaidMembershipsPaymentType: data.value,
              });
            }}
          />
        </div>
        <div>
          <ReportPaidTable
            heading={heading}
            data={paidMemberships}
            allPaid={true}
            reportStatus={reportStatus}
            reportForAdmin={reportForAdmin}
          />
        </div>
      </div>
    </>
  );
};

export default All_Paid_Memberships;
