import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContentText,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CustomDateRangePicker from "./DatepickerRange";
import appConfig from "src/appConfig";
// Custom components
import DraggableComp from "../../../components/common/draggble";
import CancelButton from "../../../components/common/modal-cancel-btn";
import { getTimeStamp } from "src/helpers";
import Loader from "src/components/common/loaders";
import axios from "src/utils/axios";
import { Days } from "src/constant/global"
import RichTextEditor, {
  getTextAlignClassName,
  getTextAlignStyles,
} from "react-rte";

import check from "src/assets/images/green-check.svg";
import moment from "moment-timezone";
const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_ALIGNMENT_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS",
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Strikethrough", style: "STRIKETHROUGH" },
    { label: "Monospace", style: "CODE" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_ALIGNMENT_BUTTONS: [
    { label: "Align Left", style: "ALIGN_LEFT" },
    { label: "Align Center", style: "ALIGN_CENTER" },
    { label: "Align Right", style: "ALIGN_RIGHT" },
    { label: "Align Justify", style: "ALIGN_JUSTIFY" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
    { label: "Blockquote", style: "blockquote" },
  ],
};

const MultiStepFormModal = ({ open, onClose, modalType, modalHeader }) => {
  let createStartDate = getTimeStamp(new Date())

  createStartDate = moment(createStartDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
  // createStartDate.startOf('day');
  // createStartDate = createStartDate.format('YYYY-MM-DDTHH:mm:ssZ');
  createStartDate = createStartDate.toISOString()

  const [currentStep, setCurrentStep] = useState(1);

  const [formData, setFormData] = useState({
    referralPartner: "",
    databaseType: "",
    addMonth: "",
    uploadedFile: null,
    emailType: false,
    emailText: "",
    startDate: createStartDate,
    freeMembershipType: "",
    personalizedSubject: null,
    personalizedText: "",
    isPersonalizedEmail: false,
  });

  const dispatch = useDispatch();

  const { dataBaseTypes, referralPartnerList } = useSelector((state) => {
    return state?.freeMembership;
  });

  const databaseTypeOptions = dataBaseTypes.map((item) => ({
    label: item.databaseName,
    value: item._id,
  }));

  const referralPartnerOptions = referralPartnerList.map((partner) => ({
    label: `${partner.firstName} ${partner.lastName}`,
    value: partner.partnerId,
  }));

  // console.log("databaseTypes", dataBaseTypes);
  // console.log("referralPartnerList", referralPartnerList);

  const [fileError, setFileError] = useState("");
  const [rangeStart, setRangeStart] = useState(getTimeStamp(new Date()));
  const [rangeEnd, setRangeEnd] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isButtonDisable, setIsButtonDisable] = useState(true);
  const [fileData, setFileData] = useState();
  const [addMonths, setAddMonths] = useState();
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [sendFollowEmailFreeMembership, setSendFollowEmailFreeMembership] =
    useState(false);
  const [isCheckBox, setIsCheckBox] = useState(true)
  const [value, setValue] = useState(
    RichTextEditor.createEmptyValue("Bonjour, Lemonde!")
  );

  useEffect(() => {
    let countDays = 0
    if (addMonths === 3) {
      countDays = Days.threeMonths + 15
    } else if (addMonths === 6) {
      countDays = Days.sixMonths + 15
    } else if (addMonths === 12) {
      countDays = Days.year + 15
    }

    let createEndDate = moment()
    createEndDate = createEndDate.add(countDays, "days")
    createEndDate = createEndDate.toISOString()
    setRangeEnd(createEndDate)

  }, [addMonths])

  const [emailOptions, setEmailOptions] = useState([
    {
      label: "Automated Email",
      value: false,
    },
    {
      label: " Personalized Email",
      value: true,
    },
  ]);
  const [addMonthOption, setAddMonthOption] = useState([
    {
      label: "3",
      value: 3,
    },
    {
      label: "6",
      value: 6,
    },
    {
      label: "12",
      value: 12,
    }
  ]);
  if (!value) {
    return null;
  }
  // Step validation schemas
  const step1ValidationSchema = Yup.object().shape({
    referralPartner: Yup.string().required("Please select a referral partner."),
  });
  const step2ValidationSchema = Yup.object().shape({
    databaseType: Yup.string().required("Please select a database type."),
    addMonth: Yup.string().required("Please select months.")
  });
  const step3ValidationSchema = Yup.object().shape({
    personalizedSubject: Yup.string().when("isPersonalizedEmail", {
      is: true,
      then: Yup.string().required("Personalized subject is required"),
    }),
    // Additional validations as needed
  });

  const handleChangeIsCheckBox = (event) => {
    setIsCheckBox(event.target.checked);
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setFileError("Please select a file to upload.");
      return;
    }
    setFileError("");
    setLoading(true);
    uploadFileToServer(file);
  };

  const uploadFileToServer = async (file) => {
    try {
      const fileFormData = new FormData();
      fileFormData.append("file", file);
      const res = await axios.post(
        `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/validate-bulk-upload`,
        fileFormData
      );
      if (res && res?.data?.statusCode === 200) {
        setFileError("");
        setIsButtonDisable(false);
        setLoading(false);
        setFileData(file);

        setFormData((prevFormData) => ({
          ...prevFormData,
          uploadedFile: file, // Set the actual file object
          fileValid: true,
          recordCount: res?.data?.data.total_records,
        }));
        // Handle success response
      } else {
        setLoading(false);
        setFileError(res?.data?.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setLoading(false);
      setFileError(error.response?.data?.message);
    }
  };
  const removeFile = () => {
    setFileData(null);
    setFormData({
      ...formData,
      uploadedFile: null,
      fileValid: false,
      recordCount: 0,
    });
    document.getElementById("fileInput").value = "";
  };

  // Form submission handler
  const handleSubmit = async (values, actions) => {
    if (currentStep < 5 && fileError) {
      setCurrentStep((prevStep) => prevStep + 1);
      return; // Prevent going to the next step
    }

    if (currentStep === 5 && !rangeEnd) {
      return false; // Disabled because end date is not selected
    }

    if (currentStep <= 5) {
      // Update formData with the current values
      setFormData((prevFormData) => ({ ...prevFormData, ...values }));

      // If the current step is not the final step, simply move to the next step
      if (currentStep < 5) {
        setCurrentStep((prevStep) => prevStep + 1);
        return; // Exit the function to avoid executing submission logic
      }

      try {
        const fileFormData = new FormData();
        fileFormData.append("file", fileData);

        fileFormData.append("partnerId", formData.referralPartner);
        fileFormData.append("databaseTypeId", formData.databaseType);
        fileFormData.append("freeMembershipMonths", formData.addMonth);
        // Append other formData values

        fileFormData.append("isPersonalizedEmail", values.emailType);
        // fileFormData.append("endDate", rangeEnd);
        fileFormData.append("startDate", formData.startDate);
        fileFormData.append("freeMembershipType", modalType);
        fileFormData.append(
          "sendFollowEmailFreeMembership",
          isCheckBox
        );

        if (values.personalizedSubject != undefined || values.personalizedSubject != null) {
          fileFormData.append("personalizedSubject", values.personalizedSubject);
        }
        if (value._cache.html != undefined) {
          fileFormData.append("personalizedText", value._cache.html);

        }
        setLoading(true)

        const res = await axios.post(
          `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/bulk-upload`,
          fileFormData
        );

        if (res && res?.data?.statusCode === 200) {
          setLoading(false)
          setUploadSuccess(true);
          setTimeout(() => {
            setUploadSuccess(false);
            onClose();
          }, 4000);
        } else {
          setLoading(false)
          setSubmitError(res?.data?.message)
          setTimeout(() => {
            setSubmitError("")
          }, 4000);
          console.error("Submission failed: ", res?.data?.message);
        }
      } catch (error) {
        setLoading(false)
        setSubmitError(error.response.data.message)
        setTimeout(() => {
          setSubmitError("")
        }, 5000);
        console.error("Error during form submission: ", error);
      }
    }
  };

  const getDateFromTimestamp = (timestamp) => {
    return timestamp ? new Date(timestamp) : null;
  }
  const handleStartDateChange = (date) => {

    let timestamp = getTimeStamp(date);
    timestamp = moment(timestamp, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
    timestamp.startOf('day');
    timestamp = timestamp.format('YYYY-MM-DDTHH:mm:ssZ');
    setRangeStart(timestamp);
    setFormData((prevFormData) => ({
      ...prevFormData,
      startDate: timestamp, // Update formData state for form submission
    }));
  };

  const handleEndDateChange = (date) => {
    let timestamp = getTimeStamp(date);
    timestamp = timestamp.toISOString()
    setRangeEnd(timestamp); // Update local state for date picker UI
    setFormData((prevFormData) => ({
      ...prevFormData,
      endDate: timestamp, // Update formData state for form submission
    }));
  };

  const getValidationSchema = (step) => {
    switch (step) {
      case 1:
        return step1ValidationSchema;
      case 2:
        return step2ValidationSchema;
      case 3:
        return step3ValidationSchema; // Define this schema if needed
      default:
        return {}; // Return an empty schema or a default one
    }
  };

  const isSubmitButtonEnabled = () => {
    if (currentStep === 4 && isButtonDisable) {
      return false; // Disabled due to file upload issues
    }
    if (currentStep === 5 && !rangeEnd) {
      return false; // Disabled because end date is not selected
    }
    return true; // Enabled otherwise
  };

  return (
    <Dialog
      open={open}
      // onClose={onClose}
      PaperComponent={DraggableComp}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
      disableEscapeKeyDown
    >
      {loading && <Loader />}
      <div className="modal-text basic-modal free-modal">
        <div className="close-btn">
          <CancelButton onClose={onClose} />
        </div>
        <div className="head">
          <h5 className="fw700 fs20 mb24 text-center">
            {modalHeader}
          </h5>
        </div>

        <div className="body">
          <DialogContentText className="text-center fs18 fw400 text-black">
            {!uploadSuccess ? (
              <Formik
                initialValues={formData}
                validationSchema={
                  currentStep === 1
                    ? step1ValidationSchema
                    : step2ValidationSchema
                }
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    {currentStep === 1 && (
                      <div className="form-in">
                        <label>Referral Partner</label>
                        <Select
                          name="referralPartner"
                          options={referralPartnerOptions}
                          defaultValue={referralPartnerOptions.find(
                            (option) => option.value === values.referralPartner
                          )}
                          onChange={(option) =>
                            setFieldValue("referralPartner", option.value)
                          }
                        />
                        <ErrorMessage
                          name="referralPartner"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                    )}

                    {currentStep === 2 && (
                      <div className="form-in">
                        <label>Database Type</label>
                        <Select
                          name="databaseType"
                          options={databaseTypeOptions}
                          defaultValue={databaseTypeOptions.find(
                            (option) => option.value === values.databaseType
                          )}
                          onChange={(option) => {
                            setFieldValue("databaseType", option.value);
                            const val = dataBaseTypes.find((item) => {
                              return item._id == option.value
                            })

                            if (val.type === 4 || val.type === 5 || val.type === 6) {
                              setSendFollowEmailFreeMembership(true);
                              setIsCheckBox(true)
                            } else {
                              setSendFollowEmailFreeMembership(false);
                            }
                          }}
                        />
                        <ErrorMessage
                          name="databaseType"
                          component="div"
                          className="input-feedback error-msg"
                        />
                        <div></div>
                        <label className="mt10" htmlFor="addMonth">Add Month</label>
                        <Select
                          name="addMonth"
                          options={addMonthOption}
                          defaultValue={addMonthOption.find(
                            (option) => option.value === values.addMonth
                          )}
                          onChange={(option) => {
                            setFieldValue("addMonth", option.value);
                            setAddMonths(option.value)
                          }}
                        />
                        <ErrorMessage
                          name="addMonth"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                    )}
                    {currentStep === 3 && (
                      <div className="form-in">
                        <label>Email Type</label>
                        <Select
                          name="emailType"
                          options={emailOptions}
                          defaultValue={emailOptions.find(
                            (option) => option.value === values.emailType
                          )}
                          onChange={(option) => {
                            setFieldValue("emailType", option.value);
                          }}
                        />
                        {values.emailType ? (
                          <div>
                            {/* <div className="heading">
                                  <h6 className="fs18 mt5">Send Personalized Email</h6>
                                </div> */}
                            <div style={{ marginTop: "1rem" }}>
                              <div className="form-in">
                                <label>Subject</label>
                                <div className="f-in">
                                  <input
                                    type="text"
                                    value={values.personalizedSubject}
                                    placeholder="Enter Email Subject"
                                    name="personalizedSubject"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "personalizedSubject",
                                        e.target.value
                                      );
                                    }}
                                    maxLength={100}
                                  />
                                </div>
                              </div>

                              <div className="form-in">
                                <label>Description</label>
                                <RichTextEditor
                                  maxTextLength={10}
                                  htmlAttributes={{ name: "emailText" }}
                                  editorClassName="demo-editor"
                                  blockStyleFn={getTextAlignClassName}
                                  toolbarConfig={toolbarConfig}
                                  value={value}
                                  onChange={(data) => {
                                    const dataHtml = data.toString("html");
                                    setValue(data);
                                    setFieldValue(
                                      "emailText",
                                      dataHtml
                                    );
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <ErrorMessage
                          name="emailType"
                          component="div"
                          className="input-feedback error-msg"
                        />
                      </div>
                    )}

                    {currentStep === 4 && (
                      <div className="form-in">
                        <input
                          type="file"
                          id="fileInput"
                          accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          onChange={handleFileChange}
                        />
                        {fileError && (
                          <div className="error-msg">{fileError}</div>
                        )}
                        {formData.uploadedFile && (
                          <div className="d-flex mt12 mb12">
                            {/* <p>File Name: {formData.uploadedFile.name}</p> */}
                            {formData.fileValid && (
                              <p>Total Records: {formData.recordCount}</p>
                            )}

                            <button
                              className="blank-btn ml-auto fs14"
                              onClick={removeFile}
                            >
                              Remove File
                            </button>
                          </div>
                        )}
                      </div>
                    )}

                    {currentStep === 5 && (
                      <div className="form-in">
                        <div className="form-in date-pick d-flex">
                          <CustomDateRangePicker
                            onStartDateChange={handleStartDateChange}
                            onEndDateChange={handleEndDateChange}
                            initialStartDate={getDateFromTimestamp(rangeStart)}
                            initialEndDate={getDateFromTimestamp(rangeEnd)}
                            dayExtendedType={modalType}
                          />
                        </div>
                        {sendFollowEmailFreeMembership ? (
                          <FormControlLabel
                            control={
                              <Checkbox color="primary" checked={isCheckBox}
                                onChange={handleChangeIsCheckBox} />
                            }
                            label="Send free membership follow up emails"
                          />
                        ) : (
                          ""
                        )}
                        {submitError != "" && <p style={{ color: "#fb3449" }}>{submitError}</p>}
                      </div>
                    )}

                    <div className="btn-grp center">
                      {currentStep > 1 && (
                        <button
                          type="button"
                          className="btn"
                          onClick={() => {
                            setCurrentStep(currentStep - 1);
                            if (currentStep <= 4) {
                              setIsButtonDisable(true);
                            }
                          }}
                        >
                          Back
                        </button>
                      )}
                      <button
                        className="btn"
                        type="submit"
                        disabled={!isSubmitButtonEnabled() || (currentStep === 3 && values.emailType && (!values.personalizedSubject != "" || (Object.keys(value._cache).length === 0 || value._cache.html == '<p><br></p>')))}
                      >
                        {currentStep === 5 ? "Submit" : "Next"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                {/* //{" "}
                <div className="modal-text basic-modal free-modal"> */}{" "}

                <img src={check} className=" img-check" />
                <p>File uploaded successfully.</p>
                {/* //{" "}
                </div> */}
              </>
            )}
          </DialogContentText>
        </div>
      </div>
    </Dialog>
  );
};

export default MultiStepFormModal;
