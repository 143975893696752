import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addPreffixforSelect } from "src/helpers";
import Select, { components } from "react-select";
const Filters = ({ onEdit, onSearch, onreset }) => {

  const { dataBaseTypes, referralPartnerList } = useSelector((state) => {
    return state?.freeMembership;
  });
  const databaseTypeOptions = dataBaseTypes.map((type) => ({
    label: type.databaseName,
    value: type._id,
  }));

  databaseTypeOptions.push({
    label: "All",
    value: "ALL"
  })

  const referralPartnerOptions = referralPartnerList.map((partner) => ({
    label: `${partner.firstName} ${partner.lastName}`,
    value: partner._id,
  }));
  const [databaseType, setDatabaseType] = useState(null);
  const [refrralPartner, setRefrralPartner] = useState(null)

  useEffect(() => {
    if (databaseType && refrralPartner) {
      onSearch(refrralPartner.value, databaseType.value)
      onEdit(databaseType, refrralPartner)
    }
  }, [databaseType, refrralPartner])

  const handleReset = () => {
    setDatabaseType(null)
    setRefrralPartner(null)
    onreset()
  }

  return (
    <div className="search-filter">
      <div className="cols">
        <div className="form-in icon">
          <span className="icon-left">
            <i className="search-icon"></i>
          </span>

          <Select
            options={databaseTypeOptions}
            value={databaseType}
            // defaultValue={databaseType}
            placeholder="Database type"
            defaultValue={databaseTypeOptions.find(
              (option) => option.value === databaseType?.value
            )}
            onChange={(selectedOptions) => setDatabaseType(selectedOptions)}
          />
        </div>
      </div>
      <div className="cols">
        <div className="form-in icon">
          <span className="icon-left">
            <i className="search-icon"></i>
          </span>

          <Select
            options={referralPartnerOptions}
            value={refrralPartner}
            placeholder="Referral Partner"
            defaultValue={referralPartnerOptions.find(
              (option) => option.value === refrralPartner?.value
            )}
            onChange={(selectedOptions) => setRefrralPartner(selectedOptions)}
          />
        </div>
      </div>
      <div className="col">
        <button
          className="btn fs16"
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    </div>
  );
};

export default Filters;
