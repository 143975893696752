import React from "react";
import DataTable from "../Components/DataTable";
import Select, { components } from "react-select";
import { ByTypeOptionsSales, StatusOptionsForSales } from "src/constant/global";
import { addPreffixforSelect } from "src/helpers";

const ByType = ({ data, setType, setByStatusWithType }) => {
  return (
    <div className="main-div h-auto no-pad-select">
      <div className="heading">
        <div className="title">
          <h6 className="fs16">Sales by Membership Type</h6>
        </div>
        <div className="select-option ml-auto">
          <Select
            options={ByTypeOptionsSales}
            placeholder="Status"
            onChange={(data) => {
              setType(data.value);
            }}
            defaultValue={ByTypeOptionsSales[0]}
            components={addPreffixforSelect({
              prefix: "Status: ",
              components,
            })}
          />
          <Select
            placeholder="NR"
            options={StatusOptionsForSales}
            onChange={(data) => {
              setByStatusWithType(data.value);
            }}
            defaultValue={StatusOptionsForSales[0]}
            components={addPreffixforSelect({
              prefix: "NR: ",
              components,
            })}
          />
        </div>
      </div>
      <DataTable data={data} title="Type" />
    </div>
  );
};

export default ByType;
