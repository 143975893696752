import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentCard from "../ContentCard";
import { addContentCard } from "src/redux/services/contentBank.service";
import Preview from "../Preview";
import Loader from "src/components/common/loaders";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";

const AddEditContent = ({ setAddNew, contentData, setView }) => {
  const [preview, setPreview] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [contentsToPublish, setContentToPublish] = useState("");
  const [allContentValues, setAllContentValues] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  const dispatch = useDispatch();

  const { apiLoader } = useSelector((state) => {
    return {
      apiLoader: state.content.loading,
    };
  });

  const handleSave = (data) => {
    if (contentData) {
      const editApiData = {
        ...data,
        cardId: contentData._id,
        groups:
          data.groups.map((el) => {
            return { groupId: el.groupId };
          }) || [],
        topics:
          data.topics.map((el) => {
            return {
              topicId: el.topicId,
            };
          }) || [],
      };

      delete editApiData._id;
      delete editApiData.status;
      delete editApiData.publishDate;
      delete editApiData.categoryName;

      customAxios
        .patch("admin/contentBank/edit-card", { ...editApiData })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
            // successAlert(response?.data?.message);
            setView(false);
          }
        })
        .catch((error) => {
          const message =
            error.response && error.response.data
              ? error.response.data.message
              : "Something Went Wrong";
          errorAlert(message);
        });
    } else {
      dispatch(
        addContentCard({
          data: { ...data },
          callback: (res) => {
            setContentToPublish(res.data._id);
            setAddNew(false);
          },
        })
      );
    }
  };

  const handleAllValues = (values, index) => {
    const dataValues = {
      ...values,
    };
    if (
      Object.values(dataValues).every((el) => {
        return el !== "";
      })
    ) {
      setIsPreview(true);
      setAllContentValues(values);
    } else {
      setIsPreview(false);
      setPreview("");
    }
  };

  if (apiLoader) {
    return <Loader />;
  }

  return (
    <>
      <div className="main-div">
        <ContentCard
          setAddNew={setAddNew}
          onSave={handleSave}
          handleAllValues={handleAllValues}
          initialValues={contentData}
          setIsPreview={setIsPreview}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setView={setView}
        />
      </div>
      <section className="main-div content-btns mt24">
        <div className="btns-lower">
          <div>
            <button
              disabled={!isPreview}
              className={`btn-white long ${
                preview === "desktop" ? "active" : ""
              }`}
              onClick={() => {
                setPreview("desktop");
              }}
            >
              Preview Content Piece ON DESKTOP
            </button>
          </div>
          <div>
            <button
              disabled={!isPreview}
              className={`btn-white long ${
                preview === "mobile" ? "active" : ""
              }`}
              onClick={() => {
                setPreview("mobile");
              }}
            >
              Preview Content Piece ON MOBILE
            </button>
          </div>
        </div>
      </section>

      {preview && isPreview && (
        <Preview
          setPreview={setPreview}
          preview={preview}
          setIsPreview={setIsPreview}
          contentsToPublish={contentsToPublish}
          setContentToPublish={setContentToPublish}
          allContentValues={allContentValues}
          onSave={handleSave}
          initialValues={contentData}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setAddNew={setAddNew}
          setView={setView}
        />
      )}
    </>
  );
};

export default AddEditContent;
