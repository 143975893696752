import React, { useState } from "react";
import { Switch } from "@mui/material";
import EnableDisableRatePopup from "./enableDisableRatePopUp";
import { firstLetterUpper, isEditAccess } from "src/helpers";
import { errorAlert } from "src/utils/alerts";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";

const RateInfo = (props) => {
  const { membershipData, handleEditMembership, handleMembershipStatus } =
    props;
  const [open, setIsOpen] = useState(false);
  const [isMembershipStatus, setIsMembershipStatus] = useState(false);

  const planData =
    membershipData &&
    membershipData?.planType &&
    membershipData?.planType?.length > 0 &&
    membershipData?.planType;

  let baseValue = null;

  for (let el of planData) {
    if (el.type === "MONTHLY" && el.status === "ACTIVE") {
      baseValue = el;
      break;
    }
  }

  if (!baseValue) {
    let el2 = planData.find((el) => el.type === "QUARTERLY");
    baseValue = el2;
  }

  const sortedPlans = [...planData]?.sort(function (a, b) {
    return parseFloat(b.duration) - parseFloat(a.duration);
  });

  return (
    <>
      {open && (
        <EnableDisableRatePopup
          isOpen={open}
          setOpen={setIsOpen}
          handleMembershipStatus={handleMembershipStatus}
          isMembershipStatus={isMembershipStatus}
        />
      )}
      <div className="row">
        {sortedPlans?.map((item) => {
          let planType;

          if (item?.type === "ANNUAL") {
            planType = "One Time";
          } else if (item?.type === "SEMI_ANNUAL") {
            planType = "Every 6 Months";
          } else if (item?.type === "QUARTERLY") {
            planType = "Every 3 Months";
          } else if (item?.type === "MONTHLY") {
            planType = "Every Month";
          }
          return (
            <div className="col-lg-3 col-md-4" key={item?._id}>
              <div className="rate-box">
                <div className="head">
                  <h6>{item?.type ? firstLetterUpper(item?.type) : ""}</h6>

                  <div className="ml-auto mr12">
                    {item?.type === "MONTHLY" && (
                      <Switch
                        checked={item?.status === "ACTIVE" ? true : false}
                        inputProps={{ "aria-label": "controlled" }}
                        onChange={() => {
                          handleEditMembership({
                            membershipId: item?._id,
                            savingPercentage: item?.savingPercentage,
                            name: item?.name,
                            status: item?.status,
                            // baseVal,
                          });
                          setIsOpen(true);
                          setIsMembershipStatus(
                            item?.status === "ACTIVE" ? true : false
                          );
                        }}
                      />
                    )}
                  </div>
                  <div>
                    <button
                      className="blank-btn"
                      disabled={
                        item?.type === "MONTHLY" && item?.status !== "ACTIVE"
                      }
                      onClick={() => {
                        const isEdit = isEditAccess(
                          "MEMBERSHIP_RATE_MANAGEMENT"
                        );
                        if (isEdit) {
                          handleEditMembership({
                            membershipId: item?._id,
                            price: item?.price,
                            savingPercentage: item?.savingPercentage,
                            name: item?.name,
                            status: item?.status,
                            type: item?.type,
                            baseValue,
                            inrPrice: item?.inrPrice,
                            inrSavingPercentage: item?.inrSavingPercentage,
                          });
                        } else {
                          errorAlert(NOT_AUTHORIZED_TEXT);
                        }
                      }}
                    >
                      <i className="edit-icon"></i>
                    </button>
                  </div>
                </div>

                <div>
                  {" "}
                  <ul className="user-info">
                    <li>
                      <small>Payment Plan</small>
                      <p>{planType}</p>
                    </li>
                    <li></li>
                    <li>
                      <small>Rate USD</small>
                      <p>{`USD ${item?.price}`}</p>
                    </li>

                    <li className="text-right">
                      <small> Savings USD</small>
                      <p>{`${item?.savingPercentage}%`}</p>
                    </li>
                  </ul>
                  <div>
                    <ul className="user-info pt8">
                      <li>
                        <small>Rate INR</small>
                        <p>{`INR ${item?.inrPrice}`}</p>
                      </li>
                      <li className="text-right">
                        <small> Savings INR</small>
                        <p>{`${item?.inrSavingPercentage}%`}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RateInfo;
