import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Carousel from "src/components/common/Carousel";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";
import BasicDetails from "./BasicDetails";
import MembershipInfo from "./MembershipInfo";

const PersonalMembership = ({
  usersData,
  setCurrentSlide,
  currentSlide,
  getUserList,
  membershipType,
  pageNo,
  handleCanceMembership,
  setPage,
}) => {
  const [editMemberships, setEditMemberships] = useState([]);
  const [sendPersonalizedEmail, setSendPersonalizedEmail] = useState([]);
  const { loading } = useSelector((state) => {
    return state.users;
  });

  useEffect(() => {
    if (usersData?.length > 0) {
      const data = usersData.map((el) => {
        return {
          isOpen: false,
          isOpenSaved: false,
        };
      });
      const emailPopupData = usersData.map((el) => {
        return {
          open: false,
        };
      });
      setEditMemberships(data);
      setSendPersonalizedEmail(emailPopupData);
    }
  }, [usersData]);

  const handleSendPersonalizedEmail = () => {
    const popupData = [...sendPersonalizedEmail];
    let modalToOpen = popupData[currentSlide];
    modalToOpen = {
      open: !modalToOpen.open,
    };
    popupData[currentSlide] = modalToOpen;
    setSendPersonalizedEmail(popupData);
  };

  return (
    <div>
      {usersData?.length > 0 ? (
        usersData?.length === 1 ? (
          usersData?.map((userData, index) => {
            return (
              <React.Fragment key={index}>
                <h6 className="fs16 mb24">
                  <span className="text-text">Details found for: </span>
                  {userData && userData?.email}
                </h6>
                <div className="membership-row">
                  {/* <BasicDetails info={userData} /> */}
                  <MembershipInfo
                    info={userData}
                    membershipType={membershipType}
                    getUserList={getUserList}
                    editMemberships={editMemberships[index]}
                    setEditMemberships={({ value, key }) => {
                      const data = [...editMemberships];
                      let newObject = data[index];
                      newObject = {
                        ...newObject,
                        [key]: value,
                      };
                      data[index] = newObject;
                      setEditMemberships(data);
                    }}
                  />
                </div>
                {!userData.isFreeTrial &&
                  userData?.transactions &&
                  userData?.transactions.length > 0 && (
                    <PaymentHistory
                      paymentData={userData?.transactions || []}
                      userData={userData || {}}
                    />
                  )}
                <div className="btns-lower">
                  <button
                    className="btn-white fs16"
                    disabled={usersData[currentSlide]?.isFreeTrial}
                    onClick={() => {
                      const data = [...editMemberships];
                      let newObject = data[currentSlide];
                      newObject = {
                        ...newObject,
                        isOpen: true,
                      };
                      data[currentSlide] = newObject;
                      setEditMemberships(data);
                    }}
                  >
                    EDIT
                  </button>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <Carousel
            setCurrentSlide={setCurrentSlide}
            fetchData={getUserList}
            pageNo={pageNo}
            setPage={setPage}
          >
            {usersData &&
              usersData.length > 0 &&
              usersData.map((userData, index) => {
                return (
                  <React.Fragment key={index}>
                    <h6 className="fs16 mb24">
                      <span className="text-text">Details found for: </span>
                      {userData && userData?.email}
                    </h6>
                    <div className="">
                      {/* <BasicDetails info={userData} count={index + 1} /> */}
                      <MembershipInfo
                        info={userData}
                        getUserList={getUserList}
                        editMemberships={editMemberships[index]}
                        setEditMemberships={({ value, key }) => {
                          const data = [...editMemberships];
                          let newObject = data[index];
                          newObject = {
                            ...newObject,
                            [key]: value,
                          };

                          data[index] = newObject;
                          setEditMemberships(data);
                        }}
                      />
                    </div>
                    <div className="btns-lower">
                      <button
                        className="btn-white fs16"
                        disabled={usersData[currentSlide]?.isFreeTrial}
                        onClick={() => {
                          const data = [...editMemberships];
                          let newObject = data[currentSlide];
                          newObject = {
                            ...newObject,
                            isOpen: true,
                          };
                          data[currentSlide] = newObject;
                          setEditMemberships(data);
                        }}
                      >
                        EDIT
                      </button>
                    </div>
                  </React.Fragment>
                );
              })}
          </Carousel>
        )
      ) : null}
      {usersData &&
        usersData.length > 0 &&
        usersData[currentSlide] &&
        sendPersonalizedEmail &&
        sendPersonalizedEmail.length > 0 &&
        sendPersonalizedEmail[currentSlide] && (
          <SEND_PERSONALIZED_EMAIL_MODAL
            open={sendPersonalizedEmail[currentSlide].open}
            setOpen={handleSendPersonalizedEmail}
            email={usersData[currentSlide].email}
          />
        )}
    </div>
  );
};

export default PersonalMembership;
