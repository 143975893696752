import moment from "moment";
import axios from "src/utils/axios";
// import Compressor from "compressorjs";
// import UPNG from "upng-js";
// import jpeg from "jpeg-js";
import imageCompression from "browser-image-compression";

import * as API from "axios";

export const isNullEmptyOrUndefined = (value) =>
  value === null || value === undefined || value === "";

export const setSessionStorage = (key, value) => {
  if (key && value) {
    sessionStorage.setItem(key, value);
  }
};

export const setLocalStorage = (key, value) => {
  if (key && value) {
    localStorage.setItem(key, value);
  }
};

export const setStorageToken = (key, value) => {
  if (key && value) {
    localStorage.setItem(key, value);
    sessionStorage.setItem(key, value);
  }
};

export const getSessionStorage = (key) => sessionStorage.getItem(key);

export const getLocalStorage = (key) => localStorage.getItem(key);

export const getSessionToken = (key) => {
  let authToken = sessionStorage.getItem(key);
  if (!authToken) {
    authToken = localStorage.getItem(key);
    if (authToken) {
      return authToken;
      // sessionStorage.setItem(key, authToken);
      // localStorage.removeItem(key);
    }
  }
  return authToken;
};

export const delay = (ms, Callback) => setTimeout(() => Callback(null), ms);

export const passwordRegex =
  /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const validNameRegex = /^[a-zA-Z ]*$/;

export const validMobileNumber = /^\d{5,}$/;
export const isValidEmail = (email = "") => {
  const emailRegex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return !!emailRegex.test(email);
};

export const isValidName = (name = "") => {
  return !!validNameRegex.test(name);
};

export const isValidMobile = (number = "") => {
  return !!validMobileNumber.test(number);
};

export const ValidPhoneNumber = /^\d+$/;

export const firstLetterUpper = (string) => {
  let newString = "";
  if (string) {
    const data = string.split("_");
    data &&
      data.length > 0 &&
      data.forEach((el, index) => {
        newString =
          newString +
          `${index !== 0 ? " " : ""}` +
          el.charAt(0).toUpperCase() +
          el.toLowerCase().slice(1);
      });
  }

  return newString;
};

// CHECK FOR FILE TYPE
export const isValidFileUploaded = (file) => {
  const validExtensions = ["png", "jpeg", "jpg"];
  const fileExtension = file.type.split("/")[1];
  return validExtensions.includes(fileExtension);
};

export const errorMessage = (error) => {
  let message = "";
  if (error?.response && error?.response?.data) {
    message = error.response.data.message
      ? error.response.data.message
      : "Something Went Wrong. Please Try Again Later";
  }
  return message;
};

export const generateQueryString = (queryData) => {
  let query = "";
  Object.keys(queryData).forEach((item) => {
    if (queryData[item]) {
      query = `${query}${item}=${queryData[item]}&`;
    }
  });
  return query;
};

export const dateFormat = (date = "") => {
  let newDate = "";
  if (date) {
    newDate = moment(date).utc().format("D MMM, YYYY - HH:mm");
  }
  return newDate;
};

export const dateAndYearFormat = (date = "") => {
  let newDate = "";
  if (date) {
    newDate = moment(date).utc().format("D MMM, YYYY");
  }
  return newDate;
};

export const isValidUrl = (urlString) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const isValidImage = async ({ file, height, width }) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = (e) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (e) => {
        const file_height = e.target.height;
        const file_width = e.target.width;
        const file_size = file?.size / 1000;
        if (
          file_height !== height ||
          file_width !== width ||
          file_size > 3000
        ) {
          resolve(false);
        } else {
          resolve(true);
        }
      };
    };
    reader.readAsDataURL(file);
  });
};

export function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]);
    reader.readAsDataURL(blob);
  });
}

export function blobToFile(theBlob, fileName) {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
}

// export const getUploadResponse = async (file) => {
//   try {
//     const originalFileSizeMB = file.size / (1024 * 1024); // size in MB
//     const halfOriginalSize = originalFileSizeMB * 0.8; // 50% of original size
//     // originalFileSizeMB * 0.6; // 60% of original size

//     const options = {
//       maxSizeMB: halfOriginalSize,
//       maxWidthOrHeight: 1920,
//       useWebWorker: true,
//       maxIteration: 10,
//       exifOrientation: 1,
//       fileType: file.type,
//     };

//     const compressedFile = await imageCompression(file, options);
//     const newFileObj = new FormData();
//     newFileObj.append("file", compressedFile, compressedFile.name);

//     const res = await axios.post("common/media-upload", newFileObj);
//     if (res && res?.data?.statusCode === 200 && res?.data?.data) {
//       return res?.data?.data?.image;
//     } else {
//       throw new Error("Failed to upload file");
//     }
//   } catch (e) {
//     console.log("error", e);
//     throw e;
//   }
// };

export const getUploadResponse = async (file) => {
  try {
    const originalFileSizeMB = file.size / (1024 * 1024); // size in MB
    const maxSizeMB = 0.6; // Maximum allowed size in MB (600 KB)

    if (originalFileSizeMB <= maxSizeMB) {
      // Skip compression if the image size is less than or equal to 600 KB
      const newFileObj = new FormData();
      newFileObj.append("file", file, file.name);

      const res = await axios.post("common/media-upload", newFileObj);
      if (res && res?.data?.statusCode === 200 && res?.data?.data) {
        return res?.data?.data?.image;
      } else {
        throw new Error("Failed to upload file");
      }
    }

    const compressionSize = originalFileSizeMB * 0.7; // 70% of original size

    const options = {
      maxSizeMB: compressionSize,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      maxIteration: 10,
      exifOrientation: 1,
      fileType: file.type,
    };

    const compressedFile = await imageCompression(file, options);
    const newFileObj = new FormData();
    newFileObj.append("file", compressedFile, compressedFile.name);

    const res = await axios.post("common/media-upload", newFileObj);
    if (res && res?.data?.statusCode === 200 && res?.data?.data) {
      return res?.data?.data?.image;
    } else {
      throw new Error("Failed to upload file");
    }
  } catch (e) {
    console.log("error", e);
    throw e;
  }
};

export const getModule = (moduleName) => {
  const module = getLocalStorage("module");
  if (module) {
    const data = JSON.parse(module);
    const moduleData = data.find((el) => {
      return el.name === moduleName;
    });
    return moduleData;
  }
};

export const isAddAccess = (moduleName) => {
  const userType = getLocalStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.add : false;
};

export const isEditAccess = (moduleName) => {
  const userType = getLocalStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.edit : false;
};

export const isDeleteAccess = (moduleName) => {
  const userType = getLocalStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.delete : false;
};

export const isViewAccess = (moduleName) => {
  const userType = getLocalStorage("userType");
  if (userType === "ADMIN") {
    return true;
  }
  const module = getModule(moduleName);
  return module ? module.view : false;
};

export const sendPersonalizedEmail = (email) => {
  window.open(` https://mail.google.com/mail/?view=cm&fs=1&to=${email} `);
};

export const handleShortLink = (edition, link) => {
  return new Promise((resolve, reject) => {
    API.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyC4yovY26TAONTOASQ7SNMF7ULPdqSOYdA`,
      {
        dynamicLinkInfo: {
          domainUriPrefix: "https://share.thesieve.media/share",
          link: link,
          socialMetaTagInfo: {
            socialTitle: edition?.title || "",
            socialDescription: "",
            socialImageLink: edition?.mobileImage || "",
          },
        },
        suffix: {
          option: "SHORT",
        },
      }
    )
      .then((res) => {
        if (res && res.data) {
          resolve(res.data);
        } else {
          reject("Something went wrong while creating a Link");
        }
      })
      .catch(() => {
        reject("Something went wrong while creating a Link");
      });
  });
};

export const splitNameAndCapitalize = (str) => {
  const splitString = str.split(/(?=[A-Z])/);

  const capitalizedString = splitString
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedString;
};

export const roundOff = (num) => {
  return num?.toFixed(2);
};

export const addPreffixforSelect = ({ prefix, components }) => {
  return {
    SingleValue: ({ children, ...props }) => {
      return (
        <components.SingleValue {...props}>
          {prefix + children}
        </components.SingleValue>
      );
    },
  };
};

export const getTimeStamp = (date) => {
  const now = new Date();
  return new Date(
    date.getMonth() +
      1 +
      "/" +
      date.getDate() +
      "/" +
      date.getFullYear() +
      " " +
      now.getHours() +
      ":" +
      (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
      ":" +
      (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds())
  );
};
