import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import CancelButton from "src/components/common/modal-cancel-btn";
import Select from "react-select";
import {
  isValidUrl,
  getUploadResponse,
  isValidImage,
  isValidFileUploaded,
} from "src/helpers";
import * as Yup from "yup";

const ctaOptions = [
  {
    label: "LISTEN",
    value: "LISTEN",
  },
  {
    label: "WATCH",
    value: "WATCH",
  },
  {
    label: "READ",
    value: "READ",
  },
];

// VALIDATION SCHEMA
const validationSchema = Yup.object().shape({
  attribute: Yup.string().trim().required("Please Enter Attribute."),
  redirectUrl: Yup.string().trim().required("Please Enter Redirect Url."),
  ctaText: Yup.string().trim().required("Please Select CTA Text."),
  // mobileImage: Yup.string().trim().required("Please Upload An Image."),
});

const EditorPickContent = ({
  onRemove,
  index,
  handleAllValues,
  count,
  initialValues,
  isDisabled,
  status,
  content,
}) => {
  const [imageLoader, setImageLoader] = useState(false);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setImageLoader(true);
    const isValid = await isValidImage({ file, height: 625, width: 500 });
    if (isValid) {
      const response = await getUploadResponse(file);
      setImageLoader(false);
      if (response) {
        return response;
      }
    } else {
      setImageLoader(false);
      return false;
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues ? initialValues : content}
        validateOnChange
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={() => {}}
      >
        {(props) => {
          const { setValues, values, setFieldValue, errors, setFieldError } =
            props;

          useEffect(() => {
            if (content) {
              setValues(content);
            }
          }, [count]);

          useEffect(() => {
            if (values) {
              handleAllValues(values, index);
            }
          }, [values]);
          return (
            <>
              <div className="heading ">
                <div className="title">
                  <h6>Content Piece #{index + 1}</h6>
                </div>
                {count > 1 && status === "DRAFT" && (
                  <div className="btn-grp ml-auto">
                    <button
                      disabled={isDisabled}
                      className="blank-btn"
                      type="button"
                      onClick={() => {
                        onRemove(index);
                      }}
                    >
                      <i className="trash-icon"></i>
                    </button>
                  </div>
                )}
              </div>

              <div className="emailer-div">
                <div
                  className={`img-upload ${
                    values?.mobileImage ? "uploaded" : ""
                  }`}
                >
                  {imageLoader && (
                    <div className="spinner">
                      <span></span>
                    </div>
                  )}
                  {values?.mobileImage && (
                    <span className="delete-img">
                      <CancelButton
                        disabled={isDisabled}
                        onClose={() => {
                          setFieldValue("mobileImage", "");
                        }}
                      />
                    </span>
                  )}

                  {values.mobileImage ? (
                    <img src={values.mobileImage} />
                  ) : (
                    <div className="f-in">
                      <input
                        type="file"
                        name="mobileImage"
                        accept=".jpg, .jpeg, .png"
                        onChange={async (e) => {
                          const isValidExtension = isValidFileUploaded(
                            e.target.files[0]
                          );
                          if (isValidExtension) {
                            const data = await handleFileChange(e);
                            if (data) {
                              setFieldValue("mobileImage", data);
                            } else {
                              setFieldError(
                                "mobileImage",
                                "Width and Height must be 500px * 625px And Size Should Be Less Than 3 MB."
                              );
                            }
                          } else {
                            setFieldError(
                              "mobileImage",
                              "File Format Not Supported"
                            );
                          }
                        }}
                      />
                    </div>
                  )}

                  <div className="text">
                    <span className="icon icon-upload">
                      <i className="upload-icon"></i>
                    </span>
                    <p>Upload from gallery</p>
                    <p>(500x625)</p>
                    <p className="accept-img">
                      (accepts .png .jpeg .jpg images only)
                    </p>
                    <div
                      name="mobileImage"
                      className="input-feedback error-msg fs14 pt8"
                    >
                      {errors?.mobileImage}
                    </div>
                  </div>
                </div>
                {/* <ErrorMessage
                  name="mobileImage"
                  component="div"
                  className="input-feedback error-msg"
                /> */}

                <div className="text">
                  <div className="form-in">
                    <label>Redirection URL*</label>
                    <div className="f-in">
                      <Field
                        placeholder="Enter redirect url"
                        type="text"
                        name="redirectUrl"
                        disabled={isDisabled}
                      />

                      <a
                        className="icon-right text"
                        onClick={() => {
                          if (values?.redirectUrl) {
                            window.open(values?.redirectUrl, "_blank");
                          }
                          if (
                            values?.redirectUrl &&
                            !isValidUrl(values?.redirectUrl)
                          ) {
                            setFieldError("redirectUrl", "Enter Valid Url");
                          }
                        }}
                      >
                        <p className="text-blue">TEST</p>
                      </a>
                    </div>
                    <ErrorMessage
                      name="redirectUrl"
                      component="div"
                      className="input-feedback error-msg"
                    />
                  </div>

                  <div className="form-in">
                    <label>Attribution Text (30 Char)* </label>
                    <div className="f-in">
                      <Field
                        placeholder="Enter attribute text"
                        maxLength={30}
                        type="text"
                        name="attribute"
                        disabled={isDisabled}
                      />
                      <ErrorMessage
                        name="attribute"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>

                  <div className="form-in">
                    <label>Choose CTA* </label>
                    <div className="f-in">
                      <Select
                        isDisabled={isDisabled}
                        onChange={(data) => {
                          setFieldValue("ctaText", data.value);
                        }}
                        options={ctaOptions}
                        value={
                          ctaOptions.find((option) => {
                            return option.value === values.ctaText;
                          }) || null
                        }
                      />
                      <ErrorMessage
                        name="ctaText"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default EditorPickContent;
