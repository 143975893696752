import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableFooter } from "@mui/material";
import DateRangePicker from "src/components/DateRangePicker";
import { MAX_PAGE_LIMIT } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import Pagination from "@mui/material/Pagination";
import Loader from "src/components/common/loaders";
import customAxios from "src/utils/axios";
import { roundOff, firstLetterUpper } from "../../../helpers";
import ExcelExport from "src/components/common/ExcelExport";

const heading = [
  { name: "Sr. No.", id: 1 },
  { name: "Member Name", id: 2 },
  { name: "Transaction ID", id: 3 },
  { name: "System Amount", id: 4 },
  { name: "Stripe Amount", id: 5 },
  { name: "Difference", id: 6 },
];

const TransactionDetails = ({ paymentGateway, selectedPaymentMethod }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(1);
  const [isFetchTransactions, setFetchTransactions] = useState(false);

  const [transactionData, setTransactionData] = useState();
  const [loading, setLoading] = useState(false);
  const [currSymbol, setCurrSymbol] = useState("$");

  const selectStartDate = (d) => {
    if (d > endDate) {
      setEndDate(null);
    }
    setStartDate(d);
  };

  const selectEndDate = (d) => {
    setEndDate(d);
  };

  const clearDates = () => {
    setEndDate(null);
    setStartDate(null);
    setFetchTransactions(false);
    setTransactionData();
  };

  React.useEffect(() => {
    if (isFetchTransactions && curPage) {
      fetchTransactions();
    }
    if (startDate && endDate) {
      setFetchTransactions(true);
    }
  }, [curPage, startDate, endDate, paymentGateway, selectedPaymentMethod]);

  const fetchTransactions = () => {
    setLoading(true);
    let gateway = "";
    if (paymentGateway === "NON_INDIA") {
      gateway = paymentGateway
    } else {
      gateway = selectedPaymentMethod
    }

    let url = `admin/billing-payments/transaction-details?limit=${MAX_PAGE_LIMIT}&pageNo=${curPage}&paymentGatewayType=${gateway}&`;
    if (startDate !== null && endDate !== null) {
      url =
        url + `&startDate=${new Date(startDate)}&endDate=${new Date(endDate)}`;
    }
    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          let rawData = res?.data?.data;
          const rawArray = res?.data?.data?.data;
          const newArray =
            (rawArray &&
              rawArray.length > 0 &&
              rawArray.map((el) => {
                return {
                  ...el,
                  stripeCharges: 0,
                  difference: el.amount,
                };
              })) ||
            [];
          rawData = {
            ...rawData,
            data: newArray,
            stripeChargesTotal: 0,
            differenceTotal: rawData.totalAmount,
          };
          setTransactionData(rawData);
          const totalPagesCount =
            res?.data?.data?.total && res?.data?.data?.total > 0
              ? Math.ceil(res?.data?.data?.total / MAX_PAGE_LIMIT)
              : 1;

          const exampleOffset = rawData?.data[0]?.paymentTimezoneOffset;
          if (exampleOffset === "+05:30") {
            setCurrSymbol("₹"); // INR for India
          } else {
            setCurrSymbol("$"); // Default to USD
          }

          setTotalCount(totalPagesCount);
          // if (isEmail) {
          //   successAlert(res?.data?.message);
          // }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const handleChange = (e, index) => {
    const value = Number(e.target.value);

    const rawData = [...transactionData.data];
    let stripeChargesTotal = transactionData.stripeChargesTotal;
    let obj = rawData[index];

    stripeChargesTotal = (
      stripeChargesTotal -
      obj.stripeCharges +
      value
    ).toFixed(2);

    obj = {
      ...obj,
      stripeCharges: value,
      difference: obj.amount - value,
    };
    rawData[index] = obj;

    setTransactionData({
      ...transactionData,
      stripeChargesTotal,
      data: rawData,
    });
  };

  return (
    <>
      <div className=" col-md-12 ">
        <div className="heading d-flexx main-div h-auto  mb0">
          <div className=" date-filter">
            <DateRangePicker
              selectStartDate={selectStartDate}
              selectEndDate={selectEndDate}
              startDate={startDate}
              endDate={endDate}
              disableEndDate={!startDate}
            />
            <button className="btn-white ml12" onClick={() => clearDates()}>
              Clear
            </button>
          </div>{" "}
          <div className="btn-grp ml-auto">
            <button
              className="btn"
              disabled={!isFetchTransactions}
              onClick={() => {
                fetchTransactions(false);
              }}
            >
              VIEW TRANSACTION DETAILS
            </button>

            <ExcelExport
              setLoading={setLoading}
              btntext="EMAIL REPORT"
              excelData={transactionData?.data}
              disabled={!transactionData}
            />
          </div>
        </div>
      </div>
      {loading && <Loader />}
      {transactionData?.data && transactionData?.data?.length > 0 && (
        <>
          <div className="col-md-12  ">
            <div className="main-div w-100 ">
              <div className="heading ">
                <div className="title">
                  <h6 className="fs16 fw600">Transaction Details</h6>
                </div>
              </div>
              <div className="full light-table ">
                <Table>
                  <TableHead>
                    <TableRow>
                      {heading &&
                        heading.length > 0 &&
                        heading.map((h) => {
                          return (
                            <>
                              <TableCell align="left" key={h.id}>
                                {h.id !== 5 ? h.name : "Phonepe Amount"}
                              </TableCell>
                            </>
                          );
                        })}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {transactionData?.data &&
                      transactionData?.data?.length > 0 &&
                      transactionData?.data?.map((item, index) => {
                        const currencySymbol =
                          item?.paymentTimezoneOffset === "+05:30" ? "₹" : "$";
                        return (
                          <TableRow key={item?._id}>
                            <TableCell>
                              {(curPage - 1) * MAX_PAGE_LIMIT + index + 1}
                            </TableCell>
                            <TableCell>
                              {firstLetterUpper(item?.name)}
                            </TableCell>
                            <TableCell>{item?.transactionId}</TableCell>
                            <TableCell>
                              {currencySymbol}
                              {item?.amount}
                            </TableCell>
                            <TableCell>
                              <input
                                type="number"
                                className="sm-input"
                                min={0}
                                value={
                                  item?.stripeCharges &&
                                  item?.stripeCharges.toString()
                                }
                                onChange={(e) => {
                                  handleChange(e, index);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              {currencySymbol}
                              {item?.difference && roundOff(item?.difference)}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableCell>Total</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell>
                      {currSymbol}
                      {roundOff(transactionData?.totalAmount)}
                    </TableCell>
                    <TableCell>
                      {currSymbol}
                      {transactionData?.stripeChargesTotal}
                    </TableCell>
                    <TableCell>
                      {currSymbol}
                      {roundOff(
                        transactionData?.totalAmount -
                        transactionData?.stripeChargesTotal
                      )}
                    </TableCell>
                  </TableFooter>
                </Table>
              </div>
              {totalCount > 1 && (
                <Pagination
                  count={totalCount}
                  variant="outlined"
                  shape="rounded"
                  defaultPage={curPage}
                  onChange={(type, page) => {
                    setCurPage(page);
                  }}
                />
              )}
              {transactionData?.data && transactionData?.data?.length === 0 && (
                <div className="no-content">
                  <h6>No Transaction Data Yet</h6>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TransactionDetails;
