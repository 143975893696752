import React, { memo, useMemo, useState } from "react";
import ModalComp from "src/components/common/modal";
import { EXPIRY_DATE_CONFIRMATION_TEXT } from "src/constant/modals";
import { firstLetterUpper } from "src/helpers";

const RecipientData = ({ info, setEditExpiry, editExpiry, count }) => {
  const [isEdit, setEdit] = useState(false);

  const personalInfoData = useMemo(() => {
    return {
      email: info?.email || "-",
      classification: "Gift Membership Received",
      customerId: info?.customerId || "-",
      firstName: info?.firstName || "-",
      lastName: info?.lastName || "-",
      contactNumber:
        info?.contactNumber && info?.countryCode
          ? `${info?.countryCode} - ${info?.contactNumber}`
          : "-",
      membershipType: info?.membershipType
        ? firstLetterUpper(info?.membershipType)
        : "-",
      membershipStatus: info?.membershipStatus
        ? firstLetterUpper(info?.membershipStatus)
        : "-",
      expiryDate: info?.planEndedAt || "",
      id: info?.id,
    };
  }, [info]);

  const onToggle = (isConfirm) => {
    if (isConfirm) {
      setEdit(true);
    }
    setEditExpiry({ value: !editExpiry.isOpen, key: "isOpen" });
  };

  return (
    <>
      <div className="">
        <h6 className="fs16 mb24">
          <span className="text-text">Details found for: </span>
          {personalInfoData.email}
        </h6>
        <h6 className="fs18 mb12">{`Gift Recipient Information ${
          count ? "#" + count : ""
        }`}</h6>
        <ul className="user-info pb16">
          <li>
            <small>Classification</small>
            <p>{personalInfoData.classification}</p>
          </li>

          <li>
            <small>Email ID</small>
            <p>{personalInfoData.email}</p>
          </li>

          <li>
            <small>Customer ID</small>
            <p>{personalInfoData.customerId}</p>
          </li>

          <li>
            <small>First Name</small>
            <p>{personalInfoData.firstName}</p>
          </li>

          <li>
            <small>Last Name</small>
            <p>{personalInfoData.lastName}</p>
          </li>

          <li>
            <small>Mobile Number</small>
            <p>{personalInfoData.contactNumber}</p>
          </li>
          {/* <li>
            <small>Membership Type</small>
            <p>{personalInfoData.membershipType}</p>
          </li>
          <li>
            <small>Membership Status</small>
            <p>{personalInfoData.membershipStatus}</p>
          </li> */}
        </ul>

        <div className="status-info ">
          <div>
            <div className="text">
              <div>
                <small>Membership Type</small>
                <p>{personalInfoData.membershipType}</p>
              </div>
            </div>
          </div>

          <div className="text">
            <div>
              <small>Membership Status</small>
              <p>{personalInfoData.membershipStatus}</p>
            </div>
          </div>
        </div>
        <ModalComp
          open={editExpiry?.isOpen}
          setOpen={onToggle}
          content={EXPIRY_DATE_CONFIRMATION_TEXT}
          onClickYes={() => onToggle(true)}
        />
      </div>
    </>
  );
};

export default memo(RecipientData);
