import React, { useState } from "react";
import { DebounceInput } from "react-debounce-input";
import { isValidEmail } from "src/helpers";
import ModalComp from "src/components/common/modal";
import { errorAlert, successAlert } from "src/utils/alerts";
import { EXPORT_DATA_CONFIRMATION_TEXT } from "src/constant/modals";
import axios from "src/utils/axios";
import appConfig from "src/appConfig";
const Fields = [
  {
    id: 1,
    name: "email",
    placeholder: "Search by Email",
  },
  {
    id: 2,
    name: "name",
    placeholder: "Search by First and Last Name",
  },
  // {
  //   id: 3,
  //   name: "customerId",
  //   placeholder: "Search by Customer ID",
  // },
  // {
  //   id: 4,
  //   name: "transactionId",
  //   placeholder: "Search by Transaction ID",
  // },
];
const Filters = ({ onChange, onSearch,loading }) => {
  const [error, setError] = useState(false);
  const [isModalOpen,setIsModalOpen]= useState(false)
  const onToggle = async (isConfirm) => {
    try {
      if (isConfirm) {
        setIsModalOpen(false)
        loading(true)
        const res = await axios.post(
          `${appConfig.REACT_APP_API_BASE_URL}admin/free-membership/referral-partners/export-data`, {}
        );
        if (res && res?.data?.statusCode === 200) {
          loading(false)
          const message =
            res && res.data
              ? res.data.message
              : "successfull";
          successAlert(message);
        }
      }
      setIsModalOpen(false)
    }
    catch (error) {
      loading(false)
      const message =
        error.response && error.response.data
          ? error.response.data.message
          : "Something Went Wrong";
      errorAlert(message);
      // return rejectWithValue(message);
    }
  };

  return (
    <div className="search-filter">
      {Fields.map((item, id) => {
        return (
          <div className="cols" key={id}>
            <div className="form-in icon">
              <span className="icon-left">
                <i className="search-icon"></i>
              </span>
              <DebounceInput
                placeholder={item.placeholder}
                debounceTimeout={500}
                onBlur={(e) => {
                  if (e.target.name === "email" && e.target.value) {
                    const isValid = isValidEmail(e.target.value.trim());
                    setError(!isValid);
                  }
                }}
                onChange={(e) => {
                  if (e.target.name === "email" && e.target.value === "") {
                    setError(false);
                  }
                  onChange(e);
                }}
                onKeyPress={(e) => {
                  if (!error && e.key === "Enter") {
                    onSearch();
                  }
                }}
                name={item.name}
              />
              {item.name === "email" && error && (
                <p className="error-msg"> Enter Valid Email </p>
              )}
            </div>
          </div>
        );
      })}
      <div className="cols">
        <button
          className="btn fs16"
          onClick={() => {
            if (!error) {
              onSearch();
            }
          }}
        >
          Search
        </button>
      </div>
      <div className="col-xl-2  flex-0 text-xl-right">
        <button
          className="btn-white fs16"
          onClick={() => {
            setIsModalOpen(true)
            // const data = [...editMemberships];
            // let newObject = data[currentSlide];
            // newObject = {
            //   ...newObject,
            //   isOpen: true,
            // };
            // data[currentSlide] = newObject;
            // setEditMemberships(data);
          }}
        >
          Export
        </button>
      </div>
      <ModalComp
          open={isModalOpen}
          setOpen={onToggle}
          content={EXPORT_DATA_CONFIRMATION_TEXT}
          onClickYes={() => onToggle(true)}
        />
    </div>
  );
};

export default Filters;
