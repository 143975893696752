import React, { useEffect, useState } from "react";
import Select, { components } from "react-select";
import { GeographyOptions, NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal_Reports-Analytics";
import ReportFreeTable from "../components/ReportFreeTable";
import { addPreffixforSelect, isEditAccess } from "src/helpers";
import moment from "moment";

const heading = ["Registered", "Converted", "Expired"];

const All_Free_Trial_SignUps = ({
  setLoading,
  startDate = null,
  endDate = null,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const [freeTrialSignUps, setFreeTrialSignUps] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [byGeography, setGeography] = useState(GeographyOptions[0].value);

  useEffect(() => {
    getFreeTrialSignUps();
  }, [byGeography, endDate, startDate]);

  function getTimeStamp(date) {
    const now = new Date();
    return new Date(
      date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        now.getHours() +
        ":" +
        (now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes()) +
        ":" +
        (now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds())
    );
  }

  const getFreeTrialSignUps = (
    isSendEmailReport = false,
    personalisedData = {}
  ) => {
    setLoading(true);
    let url = `admin/reports-analytics/all-free-trial-signups`;

    let dataToSenT = {
      byGeography,
      isSendEmailReport,
    };

    if (personalisedData?.isPersonalized) {
      const { isPersonalized, subject, content } = personalisedData;

      dataToSenT = {
        ...dataToSenT,
        sendPersonalisedEmail: isPersonalized,
        mailSubject: subject,
        mailContent: content,
      };
    }

    if (startDate !== null && endDate !== null) {
      dataToSenT = {
        ...dataToSenT,
        startDate: getTimeStamp(startDate).toISOString(),
        endDate: getTimeStamp(endDate).toISOString(),
      };
    }
    customAxios
      .post(url, { ...dataToSenT })
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport && !personalisedData?.isPersonalized) {
            setFreeTrialSignUps(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log("errorerror", error);
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getFreeTrialSignUps(true, false);
  };

  const sendReportPersonalized = (subject, content) => {
    setIsOpen(true);
    if (subject && content) {
      const personalisedData = { isPersonalized: true, subject, content };
      getFreeTrialSignUps(false, personalisedData);
    }
  };

  return (
    <>
      <SEND_PERSONALIZED_EMAIL_MODAL
        open={isOpen}
        setOpen={setIsOpen}
        sendReportPersonalized={sendReportPersonalized}
      />
      <div className="in-div h-auto">
        <div className="heading">
          <div className="title">
            <h6 className="fs16">All Free Trial Sign-Ups</h6>
          </div>
        </div>
        <div className="select-option lg">
          <Select
            options={GeographyOptions}
            placeholder="Geo"
            components={addPreffixforSelect({
              prefix: "Geo: ",
              components,
            })}
            defaultValue={GeographyOptions[0]}
            onChange={(data) => {
              setGeography(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                allFreeTrialByGeography: data.value,
              });
            }}
          />
        </div>
        <div>
          <ReportFreeTable heading={heading} data={freeTrialSignUps} />

          <div className="text-right mb18">
            <a
              class="link ml18"
              onClick={() => {
                const isEdit = isEditAccess("REPORTS_ANALYTICS");
                if (isEdit) {
                  sendReportPersonalized();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              <u>Send Personalised Email</u>
            </a>
          </div>

          <div className="heading flex-center mb0">
            <button
              className="btn"
              onClick={() => {
                const isEdit = isEditAccess("REPORTS_ANALYTICS");
                if (isEdit) {
                  sendReportOnMail();
                } else {
                  errorAlert(NOT_AUTHORIZED_TEXT);
                }
              }}
            >
              {/* <i className="msg-icon"></i> */} Send Report To Admin
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default All_Free_Trial_SignUps;
