import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { firstLetterUpper } from "src/helpers";

// Validation Schema
const validationSchema = Yup.object().shape({
  price: Yup.number()
    .min(1, "Amount Should Be Greater Than  Or Equal To $1.")
    .max(999, "Amount Should Be Less Than $999.")
    .required("Please Enter An Amount."),
  inrPrice: Yup.number()
    .min(1, "Amount Should Be Greater Than Or Equal To ₹1.")
    .max(9999, "Amount Should Be Less Than ₹9999.")
    .required("Please Enter An Amount."),
});

const EditMembership = ({
  setIsEdit,
  editMembershipValues,
  handleEditMembershipSumbit,
}) => {
  const [discountPercentage, setDiscountPercentage] = useState(
    editMembershipValues?.savingPercentage
  );

  // const [inrPrice, setInrPrice] = useState(editMembershipValues?.inrPrice);
  const [inrSavingPercentage, setInrSavingPercentage] = useState(
    editMembershipValues?.inrSavingPercentage
  );

  // const [initialUsd, setInitialUsd] = useState(editMembershipValues.usd);
  // const [initialInr, setInitialInr] = useState(editMembershipValues.inr);

  const [usdError, setUsdError] = useState();
  const [inrError, setInrError] = useState();

  const calculateBaseVal = () => {
    let baseVal = {
      usd: {
        price: null,
        type: null,
      },
      inr: {
        price: null,
        type: null,
      },
    };
    if (
      editMembershipValues.baseValue.type === "MONTHLY" &&
      editMembershipValues.baseValue.status === "ACTIVE"
    ) {
      baseVal.usd.price = editMembershipValues.baseValue.price;
      baseVal.inr.price = editMembershipValues.baseValue.inrPrice;
      baseVal.usd.type = "MONTHLY";
      baseVal.inr.type = "MONTHLY";
    } else if (editMembershipValues.baseValue.type === "QUARTERLY") {
      baseVal.usd.price = editMembershipValues.baseValue.price;
      baseVal.inr.price = editMembershipValues.baseValue.inrPrice;
      baseVal.usd.type = "QUARTERLY";
      baseVal.inr.type = "QUARTERLY";
    }
    return baseVal;
  };

  // const handleBaseValError = (baseVales, validCal, currencyType) => {
  //   if (currencyType === "USD") {
  //     setError(
  //       `Entered USD Amount Cannot Be Greater Than ${
  //         baseVales.price * validCal
  //       }.`
  //     );
  //     setDiscountPercentage(0);
  //   } else if (currencyType === "INR") {
  //     setError(
  //       `Entered INR Amount Cannot Be Greater Than ${
  //         baseVales.price * validCal
  //       }.`
  //     );
  //     setInrSavingPercentage(0);
  //   }
  // };

  const calculateOffPercentage = (price, baseVales) => {
    let planPercentage;
    let avgVal;
    let validCal;

    switch (baseVales.type) {
      case "MONTHLY":
        switch (editMembershipValues.type) {
          case "ANNUAL":
            avgVal = baseVales.price * 12;
            validCal = 12;
            break;
          case "SEMI_ANNUAL":
            avgVal = baseVales.price * 6;
            validCal = 6;
            break;
          case "QUARTERLY":
            avgVal = baseVales.price * 3;
            validCal = 3;
            break;
        }
        break;
      case "QUARTERLY":
        switch (editMembershipValues.type) {
          case "ANNUAL":
            avgVal = baseVales.price * 4;
            validCal = 4;
            break;
          case "SEMI_ANNUAL":
            avgVal = baseVales.price * 2;
            validCal = 2;
            break;
        }
        break;
    }
    planPercentage = ((avgVal - price) * 100) / avgVal;
    return { planPercentage: Math.round(planPercentage), validCal: validCal };
  };

  function handleChange(e, currencyType) {
    const value = e.target.value;
    if (/^(\d*\.{0,1}\d{0,2}$)/.test(value)) {
      let baseVales = calculateBaseVal();
      let selectedBaseValue =
        currencyType === "USD" ? baseVales.usd : baseVales.inr;
      let savingPercentage = calculateOffPercentage(value, selectedBaseValue);

      if (savingPercentage.planPercentage < 0) {
        if (currencyType === "USD") {
          setUsdError(
            `Entered Amount Cannot Be Greater Than ${
              selectedBaseValue.price * savingPercentage.validCal
            }.`
          );
          setDiscountPercentage(0);
        } else {
          setInrError(
            `Entered Amount Cannot Be Greater Than ${
              selectedBaseValue.price * savingPercentage.validCal
            }.`
          );
          setInrSavingPercentage(0);
        }
      } else {
        if (currencyType === "USD") {
          setUsdError("");
          setDiscountPercentage(savingPercentage.planPercentage);
        } else {
          setInrError("");
          setInrSavingPercentage(savingPercentage.planPercentage);
        }
      }
    } else {
      if (currencyType === "USD") {
        setUsdError("Amount Can't Be More Than 2 Decimal Places.");
      } else {
        setInrError("Amount Can't Be More Than 2 Decimal Places.");
      }
    }
  }

  // const isSaveDisabled = !isUsdChanged && !isInrChanged;

  return (
    <div className="">
      <div className="heading">
        <div className="title">
          <h6>{`Edit ${
            editMembershipValues?.type
              ? firstLetterUpper(editMembershipValues?.type)
              : ""
          } Membership Plan`}</h6>
        </div>
        <div className="btn-grp ml-auto">
          <button className="btn" onClick={() => setIsEdit(false)}>
            Back
          </button>
        </div>
      </div>

      <Formik
        initialValues={{
          price: editMembershipValues?.price,
          savingPercentage: discountPercentage,
          inrPrice: editMembershipValues?.inrPrice,
          inrSavingPercentage: inrSavingPercentage,
        }}
        validationSchema={validationSchema}
        validateOnChange
        validateOnBlur
        onSubmit={handleEditMembershipSumbit}
      >
        {({ values, setFieldValue }) => {
          useEffect(() => {
            if (discountPercentage) {
              setFieldValue("savingPercentage", discountPercentage);
            } else {
              setFieldValue("savingPercentage", 0);
            }

            if (inrSavingPercentage) {
              setFieldValue("inrSavingPercentage", inrSavingPercentage);
            } else {
              setFieldValue("inrSavingPercentage", 0);
            }
          }, [
            discountPercentage || values?.price,
            inrSavingPercentage || values?.inrPrice,
          ]);

          return (
            <Form>
              <h6 className="mb10 rate-title">
                <span>IN USD</span>
              </h6>
              <div className="row mb24">
                <div className="col-md-5">
                  <div className="form-in icon">
                    <label>Amount</label>
                    <div className="f-in">
                      <span className="icon-left pt2">
                        <i className="dollar-icon"></i>
                      </span>
                      <Field
                        type="number"
                        placeholder="Enter Amount"
                        name="price"
                        onKeyUp={(e) => handleChange(e, "USD")}
                      />
                    </div>
                    {usdError && (
                      <p className="input-feedback error-msg">{usdError}</p>
                    )}
                    {!usdError && (
                      <ErrorMessage
                        name="price"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-in ">
                    <label>Total Savings</label>
                    <div className="f-in">
                      <span className="icon-left"></span>
                      <Field
                        type="number"
                        placeholder="Enter savings percentage"
                        name="savingPercentage"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <h6 className="mb10 rate-title ">
                <span>IN INR</span>
              </h6>
              <div className="row">
                <div className="col-md-5">
                  <div className="form-in icon">
                    <label>Amount</label>
                    <div className="f-in">
                      <span className="icon-left pt2">
                        <i className="rupee-icon"></i>
                      </span>
                      <Field
                        type="number"
                        placeholder="Enter Amount"
                        name="inrPrice"
                        onKeyUp={(e) => handleChange(e, "INR")}
                      />
                    </div>
                    {inrError && (
                      <p className="input-feedback error-msg">{inrError}</p>
                    )}
                    {!inrError && (
                      <ErrorMessage
                        name="inrPrice"
                        component="div"
                        className="input-feedback error-msg"
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="form-in ">
                    <label>Total Savings</label>
                    <div className="f-in">
                      <span className="icon-left"></span>
                      <Field
                        type="number"
                        placeholder="Enter savings percentage"
                        name="inrSavingPercentage"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="foot pt24">
                <button
                  className="btn"
                  text="Add"
                  type="submit"
                  disabled={!!(usdError || inrError)}
                >
                  SAVE CHANGES
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditMembership;
