import io from "socket.io-client";
import React from "react";

/*socket initilization**/

const socketUrl = process.env.REACT_APP_SOCKET_BASE_URL;

export const socket = io(socketUrl, {
  transports: ["websocket"],
});

export const SocketContext = React.createContext();
