import React from "react";
import XLSX from "sheetjs-style";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
import { blobToBase64, isEditAccess } from "src/helpers";
import { errorAlert, successAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
const ExcelExport = ({ excelData, disabled, btntext, setLoading }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";

  const exportToExcel = async () => {
    const rawData =
      (excelData &&
        excelData.length > 0 &&
        excelData.map((el) => {
          const obj = { ...el };
          delete obj._id;
          return obj;
        })) ||
      [];
    const ws = XLSX.utils.json_to_sheet(rawData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    const base64File = await blobToBase64(data);
    sendEmailReport(base64File);
  };

  const sendEmailReport = (file) => {
    setLoading(true);
    // const newFileObj = new FormData();
    // newFileObj.append("file", file);
    customAxios
      .post(`billing-payments/send-report`, { file })
      .then((res) => {
        if (res?.data?.statusCode === 200) {
          setLoading(false);
          // successAlert(res?.data?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <button
      className="btn-white"
      onClick={() => {
        if (isEditAccess("BILLING_PAYMENT")) {
          exportToExcel();
        } else {
          errorAlert(NOT_AUTHORIZED_TEXT);
        }
      }}
      disabled={disabled || (excelData && excelData.length === 0)}
    >
      {btntext}
    </button>
  );
};

export default ExcelExport;
