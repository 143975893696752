import React, { forwardRef, useState } from "react";

import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import copy from "copy-to-clipboard";

const Transition = forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{
        appear: 500,
        enter: 800,
      }}
    />
  );
});

const SharePopup = ({
  share,
  closeShare,
  shareData = {},
  redirectToPlatform,
}) => {
  const [isCopied, setCopied] = useState(false);

  return (
    <Dialog
      open={share}
      //   TransitionComponent={Transition}
      fullWidth
      onClose={() => {
        closeShare();
      }}
    >
      <div className="modal share-modal">
        <div className="modal-content">
          <button
            className="close-modal"
            onClick={() => {
              closeShare();
            }}
          ></button>
          <div className="head">
            <h5>Share Via</h5>
            <hr />
          </div>

          <div className="body">
            <div className="social-share">
              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    redirectToPlatform("facebook");
                  }}
                >
                  <i className="fb-icon"></i>
                </span>
                <p>Facebook</p>
              </div>

              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    redirectToPlatform("telegram");
                  }}
                >
                  <i className="telegram-icon"></i>
                </span>
                <p>Telegram</p>
              </div>

              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    redirectToPlatform("twitter");
                  }}
                >
                  <i className="twitter-icon"></i>
                </span>
                <p>Twitter</p>
              </div>

              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    redirectToPlatform("linkedin");
                  }}
                >
                  <i className="linkedin-icon"></i>
                </span>
                <p>Linkedin</p>
              </div>

              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    redirectToPlatform("whatsapp");
                  }}
                >
                  <i className="whatsapp-icon"></i>
                </span>

                <p>WhatsApp</p>
              </div>

              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    redirectToPlatform("facebook_messenger");
                  }}
                >
                  <i className="msgr-icon"></i>
                </span>
                <p>Messenger</p>
              </div>

              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    redirectToPlatform("google_mail");
                  }}
                >
                  <i className="email-icon"></i>
                </span>
                <p>Email</p>
              </div>

              <div>
                <span
                  className="sharing-icon"
                  onClick={() => {
                    if (shareData && Object.keys(shareData)) {
                      copy(shareData?.shortLink || "");
                      // closeShare(true);
                      setCopied(true);
                      setTimeout(() => {
                        closeShare(false);
                        setCopied(false);
                      }, 4000);
                    }
                  }}
                >
                  <i className="link-icon"></i>
                </span>
                <p>Copy Link</p>

                <span className={`link-tip ${isCopied ? "active" : ""}`}>
                  Link Copied
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default SharePopup;
