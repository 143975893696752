import { TextField } from "@mui/material";
import moment from "moment-timezone";
import React, { useEffect, useMemo, useState } from "react";
import { firstLetterUpper, isEditAccess } from "src/helpers";
import { errorAlert } from "src/utils/alerts";
import customAxios from "src/utils/axios";
import Select from "react-select";
import { NOT_AUTHORIZED_TEXT } from "src/constant/global";
import SEND_PERSONALIZED_EMAIL_MODAL from "src/components/common/Send-Personalized-Email-Modal";

export const statusOptions = [
  {
    label: "PENDING",
    value: "PENDING",
  },
  {
    label: "PROCESSED",
    value: "PROCESSED",
  },
];

const CancellationDetail = ({
  request,
  getPendingCancellationRequest,
  getInReviewList,
}) => {
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState(false);
  const data = useMemo(() => {
    const isInr = request?.paymentTimezoneOffset === "+05:30";
    return {
      amount: `${isInr ? "₹" : "$"}${request?.transactionDetails?.amount || "-"
        }`,
      paymentValidTill: request?.subscriptionDetails?.paymentValidTill,
      planType: request?.subscriptionDetails?.planType || "-",
      planName: request?.subscriptionDetails?.planName || "-",
      lastPaymentDate: request?.transactionDetails?.createdAt,
      transactionId: request?.transactionDetails?.transactionId || "-",
      refundAmount: request?.transactionDetails?.refundAmount,
      refundStatus: request?.refundStatus,
      paymentGatewayType: request?.transactionDetails?.paymentGatewayType,
      email: request?.userDetails?.email,
      isInr,
    };
  }, [request]);

  useEffect(() => {
    if (request && request?.refundStatus) {
      setStatus(request?.refundStatus);
    }
  }, [request]);

  const handleCancelMembershipInsystem = () => {
    const requestId = request._id;
    customAxios
      .put(`admin/cancel-refund/cancel-membership-in-system`, {
        requestId,
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          // successAlert(response?.data?.message);
          getPendingCancellationRequest();
          getInReviewList();
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const handleUpdate = () => {
    const dataToSend = {
      requestId: request?._id,
      refundStatus: status,
    };
    customAxios
      .put("admin/cancel-refund/update-status", {
        ...dataToSend,
      })
      .then((response) => {
        if (response?.data?.statusCode === 200) {
          getPendingCancellationRequest();
          getInReviewList();
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  return (
    <>
      <div className="membership-div cancel-details">
        <h6 className="m12">CANCELLATION DETAILS</h6>
        <div className="">
          <ul className="user-info">
            <li>
              <small>Date of Last Payment</small>
              <p>
                {data?.lastPaymentDate
                  ? moment(data?.lastPaymentDate).format("DD MMM, YYYY")
                  : "-"}
              </p>
            </li>
            <li>
              <small>Transaction ID</small>
              <p>{data?.transactionId}</p>
            </li>
            <li>
              <small>Amount</small>
              <p>{data?.amount}</p>
            </li>
            <li>
              <small>Payment For</small>
              <p>{data?.planName ? firstLetterUpper(data?.planName) : "-"}</p>
            </li>
            <li>
              <small>Payment Plan</small>
              <p>{data?.planType ? firstLetterUpper(data?.planType) : "-"}</p>
            </li>
            <li>
              <small>Payment Valid Till</small>
              <p>
                {data?.paymentValidTill
                  ? moment(data?.paymentValidTill).utc().format("DD MMM, YYYY")
                  : "-"}
              </p>
            </li>
          </ul>
        </div>
        {data && data?.refundStatus === "PENDING" && (
          <>
            <div className="row">
              <div className="col-md-6">
                <h6 className="m16">Refund Amount</h6>
                <div className="form-in icon">
                  <div className="f-in">
                    <span className="icon-left pt2">
                      {data?.isInr ? (
                        <i className="rupee-icon"></i>
                      ) : (
                        <i className="dollar-icon"></i>
                      )}
                    </span>
                    <TextField
                      type="text"
                      value={`${data?.refundAmount}`}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <h6 className="m16">{data?.paymentGatewayType === "PHONEPE" ? " Refund On Phonepe Status" : " Refund On Stripe Status"} </h6>
                <div className="form-in">
                  {/* <label>Refund Status</label> */}
                  <div className="f-in">
                    <Select
                      defaultValue={statusOptions.find((option) => {
                        return option.value === status;
                      })}
                      value={statusOptions.find((option) => {
                        return option.value === status;
                      })}
                      onChange={(data) => {
                        setStatus(data.value);
                      }}
                      options={statusOptions}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="btns-lower pb0 pt40">
              <button
                className="btn"
                onClick={() => {
                  if (isEditAccess("CANCELLATION_REFUND")) {
                    if (data?.paymentGatewayType === "PHONEPE") {
                      window.open("https://business.phonepe.com/transactions/live");
                    } else {
                      window.open("https://stripe.com");
                    }

                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
              >{data?.paymentGatewayType === "PHONEPE" ? " REFUND ON PHONEPE" : " REFUND ON STRIPE"}

              </button>

              <button
                className="btn-white"
                onClick={() => {
                  const isEdit = isEditAccess("CANCELLATION_REFUND");
                  if (isEdit) {
                    handleUpdate();
                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
                disabled={status === "PENDING"}
              >
                SAVE & SEND EMAIL
              </button>

              <button
                className="btn-white"
                onClick={() => {
                  const isEdit = isEditAccess("CANCELLATION_REFUND");
                  if (isEdit) {
                    setOpen(true);
                  } else {
                    errorAlert(NOT_AUTHORIZED_TEXT);
                  }
                }}
              >
                SEND PERSONALIZED EMAIL
              </button>
            </div>
          </>
        )}
        {data && data?.refundStatus !== "PENDING" && (
          <div className="btns-lower pb0 pt40">
            <button
              className="btn mr-auto ml-auto"
              onClick={handleCancelMembershipInsystem}
            >
              CANCEL MEMBERSHIP IN SYSTEM
            </button>
          </div>
        )}
      </div>
      <SEND_PERSONALIZED_EMAIL_MODAL
        open={open}
        setOpen={setOpen}
        email={data?.email}
      />
    </>
  );
};

export default CancellationDetail;
