import React, { useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { NOT_AUTHORIZED_TEXT, SourceOptions } from "src/constant/global";
import Select, { components } from "react-select";
import customAxios from "src/utils/axios";
import { errorAlert, successAlert } from "src/utils/alerts";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import {
  addPreffixforSelect,
  firstLetterUpper,
  isEditAccess,
} from "src/helpers";
import { TableHead } from "@mui/material";

const Revenue_By_Month = ({
  setLoading,
  setDataForSendReport,
  dataForSendReport,
}) => {
  const paymentGatewayOptions = [
    { value: "NON_INDIA", label: "USD" },
    { value: "INDIA", label: "INR" },
  ];

  const paymentGatewayMethods = [
    { value: "INDIA", label: "Stripe" },
    { value: "PHONEPE_INDIA", label: "Phonepe" },
  ];

  const [revenue, setRevenue] = useState();
  const [selectedSource, setSource] = useState(SourceOptions[0].value);
  const [selectedPaymentGateway, setPaymentGateway] = useState(
    paymentGatewayOptions[0].value
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    paymentGatewayMethods[0].value
  );

  const [monthYear, setMonthYear] = useState(new Date());

  useEffect(() => {
    getRevenueData();
  }, [monthYear, selectedSource, selectedPaymentGateway, selectedPaymentMethod]);

  const getRevenueData = (isSendEmailReport = false) => {
    setLoading(true);
    const month = moment(monthYear).month();
    const year = moment(monthYear).year();
    let gateway = "";
    if (selectedPaymentGateway === "NON_INDIA") {
      gateway = selectedPaymentGateway
    } else {
      gateway = selectedPaymentMethod
    }


    let url = `admin/reports-analytics/revenue-by-month?month=${month}&year=${year}&isSendEmailReport=${isSendEmailReport}&paymentGatewayType=${gateway}&`;

    if (selectedSource !== "ALL") {
      url = url + `bySource=${selectedSource}&`;
    }

    customAxios
      .get(url)
      .then((res) => {
        if (res?.data?.statusCode) {
          setLoading(false);
          if (!isSendEmailReport) {
            if (selectedPaymentMethod === "PHONEPE_INDIA") {
              delete res?.data?.data["stripeCharges"];
            } else {
              delete res?.data?.data["phonepeCharges"];
            }
            setRevenue(res?.data?.data);
          } else {
            // successAlert(res?.data?.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        const message =
          error.response && error.response.data
            ? error.response.data.message
            : "Something Went Wrong";
        errorAlert(message);
      });
  };

  const sendReportOnMail = () => {
    getRevenueData(true);
  };

  const tableData =
    revenue &&
    Object.keys(revenue).length > 0 &&
    Object.keys(revenue).map((el) => {

      const field = revenue[el];
      const key = el
        .replace("phonepeCharges", "phonepe_Charges")
        .replace("grossAmount", "gross_Amount")
        .replace("netRevenue", "net_Revenue")
        .replace("partnerCommission", "partner_Commission")
        .replace("stripeCharges", "stripe_Charges")

      if (key === "couponDiscount") {
        return {
          title: "Coupon Discount",
          count: field,
        };
      } else {
        return {
          title: firstLetterUpper(key),
          count: field,
        };
      }
    });

  return (
    <div className="main-div h-auto">
      <div className="heading">
        <div className="title">
          <h6 className="fs18 mb0">Revenue By Month</h6>
        </div>
        <div className="ml-auto">
          <button
            className="btn"
            onClick={() => {
              const isEdit = isEditAccess("REPORTS_ANALYTICS");
              if (isEdit) {
                sendReportOnMail();
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            {/* <i className="msg-icon"></i> */} Send Report To Admin
          </button>
        </div>
      </div>
      <div className="select-option">
        <DatePicker
          selected={monthYear}
          onChange={(date) => {
            setMonthYear(date);
            const month = moment(date).month();
            const year = moment(date).year();
            setDataForSendReport({
              ...dataForSendReport,
              revenueByMonth: month,
              revenueByYear: year,
            });
          }}
          dateFormat="MMM yyyy"
          showMonthYearPicker
        />
        <Select
          options={SourceOptions}
          placeholder="Source"
          components={addPreffixforSelect({
            prefix: "Source: ",
            components,
          })}
          defaultValue={SourceOptions[0]}
          onChange={(data) => {
            setSource(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              revenuebySource: data.value,
            });
          }}
        />
        <Select
          options={paymentGatewayOptions}
          placeholder="Filter By"
          components={addPreffixforSelect({
            prefix: "Payments: ",
            components,
          })}
          defaultValue={paymentGatewayOptions[0]}
          onChange={(data) => {
            if (data.value == "NON_INDIA") {
              setSelectedPaymentMethod(paymentGatewayMethods[0].value);
            }
            setPaymentGateway(data.value);
            setDataForSendReport({
              ...dataForSendReport,
              paymentGatewayType: data.value,
            });
          }}
        />
          {
          selectedPaymentGateway && selectedPaymentGateway == "INDIA" ? <Select
            options={paymentGatewayMethods}
            placeholder="Filter By"
            components={addPreffixforSelect({
              prefix: "Type: ",
              components,
            })}
            defaultValue={paymentGatewayMethods[0]}
            onChange={(data) => {
              setSelectedPaymentMethod(data.value);
              setDataForSendReport({
                ...dataForSendReport,
                paymentGatewayType: data.value,
              });
            }}
          /> : ""
        }
      </div>
      <div className="light-table full table-sec center-right">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Title</TableCell>
              <TableCell align="left">
                {monthYear && moment(monthYear).format("MMM YYYY")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData.length > 0 &&
              tableData.map((item, index) => {
                return (
                  <TableRow key={`key_${index + 1}`}>
                    <TableCell>{item?.title}</TableCell>
                    <TableCell>{item?.count}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Revenue_By_Month;
