import React, { useEffect, useState } from "react";
import axios from "src/utils/axios";
import { useDispatch, useSelector } from "react-redux";
// import { Switch } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  // Input,
} from "@material-ui/core";

//Modules
import AddCategoryModal from "./AddForm";

//Action
import { getCategory } from "src/redux/services/category.service";
import {
  API_MESSAGES,
  NOT_AUTHORIZED_TEXT,
  // VALIDATION_MESSAGES,
} from "src/constant/global";
import { errorMessage, isAddAccess, isEditAccess } from "src/helpers";
import { errorAlert, successAlert } from "src/utils/alerts";
import CustomCell from "./CustomCell";

const CategoryManagement = () => {
  const dispatch = useDispatch();

  const [addNewCategory, setNewCategory] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [editableId, setEditableId] = useState({});
  const [errors, setErrors] = useState({});

  const { categories = [] } = useSelector((state) => {
    return {
      categories: state?.category?.category,
    };
  });

  {
    /*
   Styles for Material Ui table
*/
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
      overflowX: "auto",
    },
    table: {
      minWidth: 650,
    },
    selectTableCell: {
      width: 60,
    },
    tableCell: {
      width: 130,
      height: 40,
    },
    input: {
      width: 130,
      height: 40,
    },
  }));

  const cb = (message) => {
    errorAlert(message || API_MESSAGES.getError);
  };

  useEffect(() => {
    dispatch(getCategory(cb));
  }, []);

  useEffect(() => {
    if (categories && categories.length > 0) {
      setCategoryData(categories);
    } else {
      setCategoryData([]);
    }
  }, [categories]);

  {
    /*
     on change the Input in the Table Cell
    */
  }
  // const handleInputChange = (value, id, type) => {
  //   let data = editableId && editableId[id] ? editableId[id] : {};
  //   if (type === "categoryName") {
  //     data = { ...data, name: value };
  //   }
  //   setEditableId({ ...editableId, [id]: data });
  //   setTimeout(() => {
  //     document.getElementById(`category-${id}`).focus();
  //   }, 100);
  // };

  // const handleKeyDown = (event) => {
  //   const input = event.target;
  //   const currentValue = input.value;
  //   const currentCursorPosition = input.selectionStart;

  //   if (event.key === "Backspace" && currentCursorPosition > 0) {
  //     // If the Backspace key is pressed and the cursor is not at the beginning of the input field
  //     // Delete the character at the current cursor position and set the cursor position to the same position
  //     const newValue =
  //       currentValue.substring(0, currentCursorPosition - 1) +
  //       currentValue.substring(currentCursorPosition, currentValue.length);
  //     input.value = newValue;
  //     input.selectionStart = currentCursorPosition - 1;
  //     input.selectionEnd = currentCursorPosition - 1;
  //     event.preventDefault();
  //   }
  // };

  // const handleBlurInput = (id) => {
  //   let err = {
  //     ...errors,
  //   };
  //   if (
  //     editableId &&
  //     editableId[id] &&
  //     Object.keys(editableId[id]).length > 0 &&
  //     (editableId[id].name === "" || editableId[id].name.length > 16)
  //   ) {
  //     err = { ...err, [id]: id };
  //     setErrors(err);
  //   }
  // };
  // const handleFocusInput = (id) => {
  //   let err = {
  //     ...errors,
  //   };
  //   if (errors && Object.keys(errors).length > 0 && errors[id]) {
  //     delete err[id];
  //     setErrors(err);
  //   }
  //   setTimeout(() => {
  //     document.getElementById(`category-${id}`).focus();
  //   }, 100);
  // };

  // const handleStatusChange = (value, id, type) => {
  //   let data = editableId && editableId[id] ? editableId[id] : {};
  //   if (type === "status") {
  //     data = { ...data, status: value };
  //   }
  //   setEditableId({ ...editableId, [id]: data });
  // };

  {
    /*
  Custom Table  Cell Value  for View and Edit Table
*/
  }
  // const CustomTableCell = ({ category, name }) => {
  //   const classes = useStyles();
  //   const cardCount = category && category?.cardCount ? category.cardCount : 0;

  //   const categoryNameData =
  //     cardCount > 0 ? `${category.name} ${" "}(${cardCount})` : category.name;

  //   if (name === "categoryName") {
  //     return (
  //       <TableCell
  //         align="left"
  //         className={classes.tableCell}
  //         key={category._id}
  //       >
  //         {editableId &&
  //         Object.keys(editableId).length > 0 &&
  //         editableId[category._id] ? (
  //           <div className="editable">
  //             <Input
  //               onKeyDown={handleKeyDown}
  //               key={category._id}
  //               id={`category-${category._id}`}
  //               defaultValue={editableId[category._id].name}
  //               name={name}
  //               onBlur={() => handleBlurInput(category._id)}
  //               onFocus={() => handleFocusInput(category._id)}
  //               onChange={(e) =>
  //                 handleInputChange(
  //                   e.target.value,
  //                   category._id,
  //                   "categoryName"
  //                 )
  //               }
  //               className={
  //                 errors &&
  //                 Object.keys(errors).length > 0 &&
  //                 errors[category._id]
  //                   ? `${classes.input} error`
  //                   : `${classes.input}`
  //               }
  //             />
  //             <br />
  //             {errors &&
  //               Object.keys(errors).length > 0 &&
  //               errors[category._id] && (
  //                 <span className="error-msg">
  //                   {editableId[category._id].name === ""
  //                     ? VALIDATION_MESSAGES.categoryInput
  //                     : VALIDATION_MESSAGES.maxLengthCategory}
  //                 </span>
  //               )}
  //           </div>
  //         ) : (
  //           categoryNameData
  //         )}
  //       </TableCell>
  //     );
  //   } else if (name === "status") {
  //     return (
  //       <TableCell align="left" className={classes.tableCell}>
  //         {editableId &&
  //         Object.keys(editableId).length > 0 &&
  //         editableId[category._id] ? (
  //           <Switch
  //             defaultChecked={editableId[category._id].status}
  //             onChange={(e) =>
  //               handleStatusChange(e.target.checked, category._id, "status")
  //             }
  //             inputProps={{ "aria-label": "controlled" }}
  //           />
  //         ) : (
  //           <Switch
  //             checked={category.status}
  //             inputProps={{ "aria-label": "controlled" }}
  //           />
  //         )}
  //       </TableCell>
  //     );
  //   }
  // };

  useEffect(() => {}, []);
  const classes = useStyles();

  const refetchData = (isSuccess, message) => {
    if (isSuccess) {
      // successAlert(message || "");
      dispatch(getCategory(cb));
    } else {
      errorAlert(message || "");
    }
  };

  const handleEditClick = (id, category) => {
    let data = { ...editableId };
    data = { ...data, [id]: category };
    setEditableId(data);
  };

  const handleSaveClick = (id) => {
    let data = { ...editableId };
    if (
      id &&
      editableId &&
      Object.keys(editableId).length > 0 &&
      editableId[id] &&
      Object.keys(editableId[id]).length > 0
    ) {
      axios
        .put("admin/editCategory", {
          name: editableId[id].name || "",
          status: editableId[id].status,
          categoryId: id,
        })
        .then((res) => {
          if (
            editableId &&
            Object.keys(editableId).length > 0 &&
            editableId[id]
          ) {
            delete data[id];
            setEditableId(data);
          }
          if (res && res.data && res.data.data) {
            dispatch(getCategory(cb));
          }
          // successAlert(API_MESSAGES.editCategorySuccess);
        })
        .catch((err) => {
          let message = "";

          if (err?.response && err?.response?.data) {
            message = errorMessage(err);
          }
          errorAlert(message || API_MESSAGES.editCategory);
        });
    }
  };

  return (
    <div className="main-div">
      <div className="heading">
        <div className="title">
          <h6 className="">Category management</h6>
        </div>
        <div className="btn-grp ml-div">
          <button
            className="btn"
            onClick={() => {
              const isAdd = isAddAccess("CATEGORY_MANAGEMENT");
              if (isAdd) {
                setNewCategory(true);
              } else {
                errorAlert(NOT_AUTHORIZED_TEXT);
              }
            }}
          >
            + New Category
          </button>
        </div>
      </div>

      {addNewCategory && (
        <AddCategoryModal
          open={addNewCategory}
          setOpen={setNewCategory}
          refetchData={refetchData}
        />
      )}
      <div className="full category-table">
        <Table className={classes.table} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Sr. No.</TableCell>
              <TableCell align="left">Category Name</TableCell>
              <TableCell align="left">
                Set Status (
                <span className="text-text fw500"> Inactive / Active </span>)
              </TableCell>
              <TableCell align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryData &&
              categoryData.length > 0 &&
              categoryData.map((category, index) => {
                return (
                  <TableRow key={category._id}>
                    <TableCell>{index + 1}</TableCell>
                    <CustomCell
                      category={category}
                      name="categoryName"
                      editableId={editableId}
                      setEditableId={setEditableId}
                      errors={errors}
                      setErrors={setErrors}
                    />
                    <CustomCell
                      category={category}
                      name="status"
                      editableId={editableId}
                      setEditableId={setEditableId}
                      errors={errors}
                      setErrors={setErrors}
                    />
                    <TableCell>
                      <>
                        {editableId &&
                        Object.keys(editableId).length > 0 &&
                        editableId[category._id] ? (
                          <button
                            className="btn small"
                            disabled={
                              errors &&
                              Object.keys(errors).length > 0 &&
                              errors[category._id]
                                ? true
                                : false
                            }
                            onClick={() => handleSaveClick(category._id)}
                          >
                            SAVE
                          </button>
                        ) : (
                          <button
                            className="null-btn"
                            aria-label="edit"
                            onClick={() => {
                              const isEdit = isEditAccess(
                                "CATEGORY_MANAGEMENT"
                              );
                              if (isEdit) {
                                handleEditClick(category._id, category);
                              } else {
                                errorAlert(NOT_AUTHORIZED_TEXT);
                              }
                            }}
                          >
                            <i className="edit-icon"></i>
                          </button>
                        )}
                      </>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default React.memo(CategoryManagement);
