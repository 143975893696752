import moment from "moment-timezone";
import React from "react";

const PreviewOnMobileAndDesktop = ({
  preview,
  editorContent,
  publishDate,
  setPreview,
}) => {
  return (
    <div className="main-div mt24 h-auto">
      <div className="heading bb">
        <div className="">
          <h6 className="title">{`Preview Editor’s Pick on ${
            preview === "desktop" ? "Desktop" : "Mobile"
          }`}</h6>
        </div>
        <div className="btn-grp ml-auto">
          <button
            className="btn"
            type="button"
            onClick={() => {
              setPreview("");
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className={`email-preview table-sec`}>
        <table
          className={`emailer-table  ${preview === "desktop" ? "" : "mobile"}`}
        >
          <tr>
            <td>
              <table width="100%">
                <tr className="top-td">
                  <td>
                    <p>
                      {publishDate &&
                        moment(publishDate).format("MMM DD, YYYY")}
                    </p>
                  </td>
                  <td>
                    <p>
                      Customer Id.: <b>--------</b>{" "}
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="logo-td">
              <img
                width="179px"
                src={require("./../../../assets/images/logo-text.png")}
              />
            </td>
          </tr>
          <tr>
            <td>
              <div className="email-cards">
                <hr />
                <div className="emailer-cards">
                  {editorContent &&
                    editorContent.length > 0 &&
                    editorContent.map((data) => (
                      <div>
                        <img src={data?.mobileImage} />
                        <p>Courtesy</p>
                        <small>{data?.attribute}</small>
                        <a>
                          {data?.ctaText} <i className="caret-sm-icon"></i>
                        </a>
                      </div>
                    ))}
                </div>
                <hr />
                <div className="emailer-btn">
                  <button>VIEW LATEST EDITION</button>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="footer-td">
              <h6>
                Copyright©{new Date().getFullYear()} The Sieve Media LLC. All
                rights reserved.
              </h6>

              <p>
                You are receiving this email because you are part of the Sieve’s
                membership initiative. To unsubscribe, <a>Click here</a>
              </p>

              <a>Add us to your address book</a>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default PreviewOnMobileAndDesktop;
